import { Component, OnInit } from "@angular/core";

export interface Configuraciones {
  nombre?: string;
  descripcion?: string;
  url?: string;
  icon?: string;
}

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  constructor() {}

  listadoDeConfiguraciones: Configuraciones[] = [
    {
      nombre: "Empresa",
      descripcion: "Datos de la empresa, de contacto y facturación",
      url: "/configurar/empresa",
    },
    {
      nombre: "Puntos de venta",
      descripcion: "Gestión de los puntos de ventas",
      url: "/configurar/punto-venta",
    },
    {
      nombre: "Listas de precios",
      descripcion: "Gestión de la lista de precios",
      url: "/configurar/lista-precio",
    },
    {
      nombre: "Talonarios",
      descripcion: "Gestión de los talonarios",
      url: "/configurar/talonarios",
    },
    {
      nombre: "Marcas",
      descripcion: "Gestión de las marcas",
      url: "/configurar/marcas",
    },
    {
      nombre: "Colaboradores",
      descripcion: "Gestión de los colaboradores",
      url: "/configurar/colaboradores",
    },
    {
      nombre: "Rubros",
      descripcion: "Gestión de los rubros",
      url: "/configurar/rubros",
    },
    {
      nombre: "Tienda",
      descripcion: "Gestión de la tienda online",
      url: "/configurar/tienda",
    },
  ];

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector(".navbar-brand");
    if (sidebar !== undefined) {
      sidebar.innerHTML = "Configuraciones";
    }
  }
}
