export const environment = {
  production: true,
  testing: false,
  localhost: false,
  firebase: {
    apiKey: 'AIzaSyDlWlntd9P8kQ5TuUL1gQfm-2cH-MdNf4A',
    authDomain: 'kocursoft-erp.firebaseapp.com',
    projectId: 'kocursoft-erp',
    storageBucket: 'kocursoft-erp.appspot.com',
    messagingSenderId: '285331451218',
    appId: '1:285331451218:web:d54ac3b723eeffac935e8b',
    measurementId: 'G-Y3GGQ06CPE'
  },
  apiUrl: 'https://api-erp-kocursoft-274979470440.southamerica-west1.run.app/v1/',
  urlAuth: 'https://signin.kocursoft.com'
};
