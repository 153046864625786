export class Usuario {
    nombre: string;
    email: string;
    apellido: string;
    dni: string;
    fechaNacimiento: string;
    nacimiento: string;
    userName: string;
    avatarUrl: string;
    habilitado: any;
    rol: any;
    razonsocial: any;
    telefono: any;
    direccion: any;
    provincia: any;
    displayName: any;
    localidad: any;
    pais: any;
    cpostal: any;
    avatar: any;
    cuit: any;
    password: string;
    _id: string;
}
