import { Component, OnInit } from "@angular/core";
import { ParametrosService } from "app/services/parametros.service";

@Component({
  selector: "app-talonarios",
  templateUrl: "./talonarios.component.html",
  styleUrls: ["./talonarios.component.css"],
})
export class TalonariosComponent implements OnInit {
  talonarios = [];

  constructor(private parametrosService: ParametrosService) {}

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector(".navbar-brand");
    if (sidebar !== undefined) {
      sidebar.innerHTML = "Talonarios";
    }
    this.getTalonarios();
  }

  getTalonarios() {
    this.parametrosService.getTalonarios().subscribe(
      (data) => {
        this.talonarios = data;
        console.log({ data });
      },
      (err) => console.log(err)
    );
  }
}
