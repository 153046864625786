import { ClientesService } from 'app/services/clientes.service';
import { FormatWidth } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cliente } from 'app/models/cliente.models';
import { Tipodocumento } from 'app/models/tipodocumento.models';
import { TipoResponsble } from 'app/models/tiporesponsable.models';
import { DocumentosService } from 'app/services/documentos.service';
import { TipoResponsableService } from 'app/services/tiporesponsable.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ParametrosService } from 'app/services/parametros.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-detalle-cliente',
  templateUrl: './detalle-cliente.component.html',
  styleUrls: ['./detalle-cliente.component.css']
})
export class DetalleClienteComponent implements OnInit {

  responsables: TipoResponsble[] = [];
  documentos: Tipodocumento[] = [];
  form: UntypedFormGroup;
  cliente: Cliente;
  textoBtn: string;
  idCliente: string;

  constructor(
    private tipoResponsableService: TipoResponsableService,
    private tipoDocumentoService: DocumentosService,
    private clienteService: ClientesService,
    private parametroServicio: ParametrosService,
    private fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private routerActive: ActivatedRoute,
    private router: Router
  ) {
    this.idCliente = this.routerActive.snapshot.params.id;
    this.form = this.fb.group({
      numero: new UntypedFormControl(''),
      razonsocial: new UntypedFormControl(''),
      bonificacion: new UntypedFormControl(0),
      tipo: new UntypedFormControl(''),
      tipoduc: new UntypedFormControl(''),
      nrodoc: new UntypedFormControl(''),
      telefono: new UntypedFormControl(''),
      celular: new UntypedFormControl(''),
      direccion: new UntypedFormControl(''),
      ciudad: new UntypedFormControl(''),
      provincia: new UntypedFormControl('Misiones'),
      pais: new UntypedFormControl('Argentina'),
      cpostal: new UntypedFormControl(''),
      email: new UntypedFormControl(''),
      tiporesp: new UntypedFormControl(''),
      compemitir: new UntypedFormControl(''),
      comentario: new UntypedFormControl('')
    });
    if (!this.idCliente) {
      this.textoBtn = 'Crear';
      this.cliente = new Cliente();
      this.cliente.tipo = 'Cliente';
      this.getNuevoNumeroCliente();
    } else {
      this.textoBtn = 'Actualizar';
      this.clienteService.getClienteById(this.idCliente).subscribe(
        (data) => {
          this.cliente = data;
          this.form = this.fb.group({
            numero: new UntypedFormControl({ value: this.cliente.numero, disabled: true }),
            razonsocial: new UntypedFormControl(this.cliente.razonsocial),
            tipo: new UntypedFormControl(this.cliente.tipo),
            bonificacion: new UntypedFormControl(this.cliente.bonificacion),
            tipoduc: new UntypedFormControl(this.cliente.tipodocumento),
            nrodoc: new UntypedFormControl(this.cliente.nrodocumento),
            telefono: new UntypedFormControl(this.cliente.telefono),
            celular: new UntypedFormControl(this.cliente.celular),
            direccion: new UntypedFormControl(this.cliente.direccion),
            ciudad: new UntypedFormControl(this.cliente.localidad),
            provincia: new UntypedFormControl(this.cliente.provincia),
            pais: new UntypedFormControl(this.cliente.pais),
            cpostal: new UntypedFormControl(this.cliente.cgopostal),
            email: new UntypedFormControl(this.cliente.email),
            tiporesp: new UntypedFormControl(this.cliente.tiporesponsable),
            comprobanteemitir: new UntypedFormControl(this.cliente.comprobanteemitir),
            comentario: new UntypedFormControl(this.cliente.comentario)
          });
        }
      )

    }

  }

  ngOnInit(): void {
    this.initForm();
    this.getParametros();
  }

  getNuevoNumeroCliente() {
    this.clienteService.getNuevoNumeroCliente().subscribe(
      res => {
        this.form.controls.numero.setValue(res.numero);
        this.form.controls.numero.disable();
      },
      err => {
        console.log(err)
      }
    )
  }

  initForm() {
    this.form = this.fb.group({
      numero: new UntypedFormControl(''),
      razonsocial: ['', Validators.required],
      bonificacion: new UntypedFormControl(0),
      tipo: new UntypedFormControl('cliente'),
      tipoduc: new UntypedFormControl(''),
      nrodoc: ['', Validators.required],
      telefono: new UntypedFormControl(''),
      celular: new UntypedFormControl(''),
      direccion: new UntypedFormControl(''),
      ciudad: new UntypedFormControl(''),
      provincia: new UntypedFormControl('Misiones'),
      pais: new UntypedFormControl('Argentina'),
      cpostal: new UntypedFormControl(''),
      email: new UntypedFormControl(''),
      tiporesp: new UntypedFormControl(''),
      comprobanteemitir: new UntypedFormControl('factura a'),
      comentario: new UntypedFormControl('')
    });
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getParametros() {
    this.parametroServicio.getParametrosClientes().subscribe((data) => {
      this.responsables = data.tiporesponsables;
      this.documentos = data.tipodocumentos;
      let responsable = this.responsables.find(x => x.codigo == '1')
      let documento = this.documentos.find(x => x.codigo == '80')
      this.form.controls.tiporesp.setValue(responsable._id, {
        onlySelf: true
      })
      this.form.controls.tipoduc.setValue(documento._id, {
        onlySelf: true
      })
    })
  }

  guardarCliente() {
    this.cliente.numero = this.cliente.numero;
    this.cliente.celular = this.form.value.celular;
    this.cliente.cgopostal = this.form.value.cpostal;
    this.cliente.direccion = this.form.value.direccion;
    this.cliente.email = this.form.value.email;
    this.cliente.localidad = this.form.value.ciudad;
    this.cliente.nrodocumento = this.form.value.nrodoc;
    this.cliente.pais = this.form.value.pais;
    this.cliente.provincia = this.form.value.provincia;
    this.cliente.razonsocial = this.form.value.razonsocial;
    this.cliente.telefono = this.form.value.telefono;
    this.cliente.tipodocumento = this.form.value.tipoduc;
    this.cliente.tipo = this.form.value.tipo;
    this.cliente.tiporesponsable = this.form.value.tiporesp;
    this.cliente.comprobanteemitir = this.form.value.comprobanteemitir;
    this.cliente.comentario = this.form.value.comentario;
    this.cliente.bonificacion = this.form.value.bonificacion;
    if (this.cliente._id) {
      this.clienteService.actualizarCliente(this.cliente._id, this.cliente).subscribe(
        (data) => {
          this.openSnackBar(`${this.cliente.tipo} actualizado correctamente`, 'Información');
          this.router.navigate(['/clientes']);
        },
        err => {
          console.log(err)
          this.openSnackBar(`Error al actualizar ${this.cliente.tipo}`, 'Error');
        }
      )
    } else {
      this.clienteService.guardarCliente(this.cliente).subscribe(
        (data) => {
          this.openSnackBar(`${this.cliente.tipo} guardado correctamente`, 'Información');
          this.router.navigate(['/clientes']);
        },
        err => {
          console.log(err)
          this.openSnackBar(`Error al guardar ${this.cliente.tipo}`, 'Error');
        }
      )
    }

  }

  editarNumero() {
    if (this.form.controls.numero.disabled) {
      this.form.controls.numero.enable();
    } else {
      this.form.controls.numero.disable();
    }
  }

}
