import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor( 
    private dataService: DataService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.dataService.getToken().then(
      (token) => {
        if (token) {
          if (token !== null) {
            // validamos si el token no se encuentra vencido
            const payload = token.split('.')[1];
            const payloadDecoded = atob(payload);
            const payloadJSON = JSON.parse(payloadDecoded);
            const expiracion = payloadJSON.exp;
            const expiracionDate = new Date(expiracion * 1000);
            const ahora = new Date();
            if (expiracionDate.getTime() < ahora.getTime()) {
              // token vencido
              this.router.navigate(['/login']);
              return false;
            } else {
             return true;
            } 
          }
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      },
      (err) => {
        return false;
      }
    );
  }
  
}
