import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Cliente } from 'app/models/cliente.models';
import { ClientesService } from 'app/services/clientes.service';
import { ComprobantesService } from 'app/services/comprobantes.service';
import { VentasService } from 'app/services/ventas.service';

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.css']
})
export class ResumenComponent implements OnInit {

  idCliente: string;
  razonSocial: string;
  totalComprobantes: number;
  totalFacturado: number;
  deuda: number;
  saldo: number;
  comprobantes = [];
  cliente: Cliente;
  isCliente: boolean;
  isProveedor: boolean;

  constructor( 
    private comprobanteService: ComprobantesService,
    private clienteService: ClientesService,
    public dialog: MatDialog,
    private routerActive: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private router: Router,) {
      this.idCliente = this.routerActive.snapshot.params.id;
      this.totalComprobantes = 0;
      this.totalFacturado = 0;
      this.deuda = 0;
      this.saldo = 0;
     }

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Clientes y Proveedores / Resumen';
    }
    this.getCliente();
  }

  getCliente() {
    this.clienteService.getClienteById(this.idCliente).subscribe((data) => {
      this.razonSocial = data.razonsocial;
      this.cliente = data;
      this.getComprobantesByCliente();
      if (this.cliente) {
        switch (this.cliente.tipo) {
          case 'cliente':
            this.isCliente = true;
            this.isProveedor = false;
            break;
          case 'proveedor':
            this.isCliente = false;
            this.isProveedor = true;
              break;
          case 'cliente/proveedor':
            this.isCliente = true;
            this.isProveedor = true;
              break;
          default:
            this.isCliente = false;
            this.isProveedor = false;
            break;
        }
      }
    });
   
  }

  getComprobantesByCliente() {
    this.comprobanteService.getComprobanteByCliente(this.idCliente).subscribe(
      res => {
        console.log(res)
        this.comprobantes = res.comprobantes;
        this.totalComprobantes = res.comprobantes.length;
        this.totalFacturado = res.totalFacturado;
      },
      err => {
        console.log(err)
      }
    )
    
  }

  irAPagos() {
    this.router.navigateByUrl(`comprobantes/recibo/pago/${this.idCliente}`);
  }

  irACobros() {
    this.router.navigateByUrl(`comprobantes/recibo/cobro/${this.idCliente}`);
  }

  irAProductosProveedor() {
    this.router.navigateByUrl(`comprobantes/recibo/cobro/${this.idCliente}`);
  }
}
