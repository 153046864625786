import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PtoventaService } from 'app/services/ptoventa.service';

@Component({
  selector: 'app-apertura',
  templateUrl: './apertura.component.html',
  styleUrls: ['./apertura.component.css']
})
export class AperturaComponent implements OnInit {

  form: UntypedFormGroup;
  lstPuntoVentas = [];

  constructor(
    public dialogRef: MatDialogRef<AperturaComponent>,
    private fb: UntypedFormBuilder,
    private ptoVentaService: PtoventaService,
    private snackBar: MatSnackBar,
    ) {
      this.form = this.fb.group({
        montoCtrl: ['0', Validators.required],
        puntoventa: ['', Validators.required]
      });
    }

  ngOnInit(): void {
    this.getPuntosVentas();
  }

  getPuntosVentas() {
    this.ptoVentaService.getAll().subscribe(
      (data) => {
        this.lstPuntoVentas = data;
        if (this.lstPuntoVentas.length > 0) {
          this.form.controls.puntoventa.setValue(this.lstPuntoVentas[0]._id);
        }
      },
      err => {
        console.log(err)
        this.openSnackBar("No ah sido posible recuperar el listado de puntos de ventas");
      }
    )
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "error", {
      duration: 4000,
    });
  }


  guardarCaja() {
    let pto = this.lstPuntoVentas.find(x => x._id === this.form.controls.puntoventa.value)
    this.dialogRef.close({
      monto: this.form.controls.montoCtrl.value,
      puntoventa: pto
    });
  }

  cancelar() {
    this.dialogRef.close();
  }


}
