import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { CheckauthComponent } from './pages/checkauth/checkauth.component';
import { LoginGuard } from './login.guard';

export const AppRoutes: Routes = [ 
  {
    path: '',
    redirectTo: 'checkauth',
    pathMatch: 'full',
  },
  {
    path: 'checkauth',
    component: CheckauthComponent,
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
        canActivate: [LoginGuard],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
