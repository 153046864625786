import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-sucursal',
  templateUrl: './add-sucursal.component.html',
  styleUrls: ['./add-sucursal.component.css']
})
export class AddSucursalComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddSucursalComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.form = this.fb.group({
        numero: [this.data.numero],
        nombre: ['', Validators.required],
        direccion: [''],
        email: [''],
        telefono: ['']
      });
     }

  ngOnInit(): void {
  }

  salir() {
    this.dialogRef.close();
  }

  guardarSucursal() {
    this.dialogRef.close({
      numero: this.form.controls.numero.value,
      nombre:  this.form.controls.nombre.value,
      direccion:   this.form.controls.direccion.value,
      email:  this.form.controls.email.value,
      telefono: this.form.controls.telefono.value
    });
  }

}
