export class Cliente {
    _id: string;
    numero: number ;
    razonsocial?: string;
    nrodocumento?: string;
    tipodocumento?: string;
    tiporesponsable?: string;
    email?: string;
    direccion?: string;
    telefono?: string;
    celular?: string;
    localidad?: string;
    provincia?: string;
    pais?: string;
    cgopostal?: string;
    userid?: string;
    fechaBaja?: Date;
    bonificacion?: any;
    tipo: string;
    comprobanteemitir?: string;
    comentario?: string;
}
