import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Auditoria } from 'app/models/auditoria.models';

@Injectable({
  providedIn: 'root'
})
export class AuditoriaService {
  headers: HttpHeaders;

  constructor(private http: HttpClient) { }

  getAuditorias(): Observable<Auditoria | any> {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'auditorias', { headers: this.headers });
  }

  getAuditoriaById(id): Observable<Auditoria | any> {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'auditoria/' + id, { headers: this.headers });
  }

  guardarAuditoria(cambionuevo, cambioanterior, tabla, campo, usuario, tipo): Observable<any> {
    const auditoria = new Auditoria();
    auditoria.cambioanterior = cambioanterior;
    auditoria.cambionuevo = cambionuevo;
    auditoria.tabla = tabla;
    auditoria.tipo = tipo;
    auditoria.campo = campo;
    auditoria.usuario = usuario;
    this.getOptions();
    return this.http.post(environment.apiUrl + 'auditoria', auditoria, { headers: this.headers });
  }

  private getOptions(): any {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    });
  }
}
