import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Comprobante } from 'app/models/comprobante.models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComprobantesService {

  constructor(private http: HttpClient) { }

  getCompras(): Observable<Comprobante | any> {
    return this.http.get(environment.apiUrl + 'compras');
  }

  getComprobantes(): Observable<Comprobante | any> {
    return this.http.get(environment.apiUrl + 'comprobantes');
  }

  getComprobanteById(id): Observable<Comprobante | any> {
    return this.http.get(environment.apiUrl + `comprobante/${id}`);
  }

  getComprobanteByTipoNumero(tipo): Observable<any> {
    return this.http.get(environment.apiUrl + `comprobante/siguiente/${tipo}`);
  }

  getComprobanteByCliente(idcliente): Observable<any> {
    return this.http.get(environment.apiUrl + `comprobante/cliente/${idcliente}`);
  }

  guardarComprobante(comprobante: Comprobante, actualizarStock: boolean): Observable<any> {
    return this.http.post(environment.apiUrl + 'comprobante/create', {comprobante, actualizarStock})
  }


}
