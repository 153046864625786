import { Injectable } from '@angular/core';
import { TipoResponsble } from '../models/tiporesponsable.models';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipoResponsableService {

  tipodocumentos: Observable<TipoResponsble[] | any>;

  constructor(private http: HttpClient) { }

  getTipoResponsables(): Observable<TipoResponsble | any> {
    return this.tipodocumentos = this.http.get(environment.apiUrl + 'tiporesponsables');
  }


}
