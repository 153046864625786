import { TipoComprobante } from './../../models/tipocomprobante.models';
import { Producto } from 'app/models/producto.models';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Cliente } from 'app/models/cliente.models';
import { Caja } from 'app/models/caja.models';
import { AuditoriaService } from 'app/services/auditoria.service';
import { GeneralesService } from 'app/services/generales.service';
import { ProductoService } from 'app/services/producto.service';
import { ClientesService } from 'app/services/clientes.service';
import { ParametrosService } from 'app/services/parametros.service';
import { DialogoComponent } from '../dialogo/dialogo.component';
import { Stock } from 'app/models/stock.models';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { AperturaComponent } from './apertura/apertura.component';
import { CierreComponent } from './cierre/cierre.component';
import { DataService } from 'app/services/data.service';
import { ListaClienteCajaComponent } from './lista-cliente-caja/lista-cliente-caja.component';
import { CajaService } from 'app/services/caja.service';
import { ConfirmarCajaComponent } from './confirmar-caja/confirmar-caja.component';
import { OperacionCaja } from 'app/models/opercaja.models';
import { ListaprecioService } from 'app/services/listaprecio.service';
import { TcomprobanteService } from 'app/services/tcomprobante.service';
import { ListaProductosDialogoComponent } from '../lista-productos-dialogo/lista-productos-dialogo.component';
import { Comprobante } from 'app/models/comprobante.models';
import { Cabecera } from 'app/models/cabecera.models';
import * as dayjs from 'dayjs';
import { ComprobantesService } from 'app/services/comprobantes.service';
import { Concepto } from 'app/models/concepto.models';
import { EditarCantidadesComponent } from './editar-cantidades/editar-cantidades.component';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-caja',
  templateUrl: './caja.component.html',
  styleUrls: ['./caja.component.css'],
})
export class CajaComponent implements OnInit, AfterViewInit {
  @ViewChild('inputcliente') clienteElement: ElementRef;
  @ViewChild('inputcantidad') cantidadElement: ElementRef;
  @ViewChild('inputarticulo') articuloElement: ElementRef;

  loading = false;
  articuloSel = '';
  cantidad = 0;
  lstarticulos: any[] = [];
  lstStock: any[] = [];
  selectedRow: number;
  unarticulo: Producto;
  unCliente: Cliente;
  total = 0.0;
  montoing = 0.0;
  cambio = 0.0;
  fecha;
  hora;
  verBtnCancelar;
  cajero = '';
  cliente = 'Consumidor Final';
  cajaActual: Caja;
  clienteSel: string;
  setClickedRow: Function;
  fpago: any;
  verInputArticulo: boolean;
  verBtnBuscarArticulo: boolean;
  verBtnFPago: boolean;
  verBtnConfirmar: boolean;
  verInputBuscarCliente: boolean;
  VerBtnCerrarCaja: boolean;
  VerBtnAbrirCaja: boolean;
  verBtnBuscarCliente: boolean;
  lstPagos: any;
  lstDetalles = [];
  listaFormularioImprimir: any = [];
  observaciones: string;
  form: UntypedFormGroup;
  monedas = [];
  listaprecios = [];
  tipoComprobante: TipoComprobante;
  comprobante: Comprobante;
  cabecera: Cabecera;
  concepto: Concepto;
  tipoComprobanteDB;
  punto = 0;
  nro = 0;
  uid;
  dialogRefLoading;

  constructor(
    public cajaService: CajaService,
    private dataService: DataService,
    private servicioGrales: GeneralesService,
    private articuloService: ProductoService,
    private clienteService: ClientesService,
    private auditoriaService: AuditoriaService,
    private paramService: ParametrosService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private listaprecioService: ListaprecioService,
    private tipoComprobService: TcomprobanteService,
    private comprobanteSerice: ComprobantesService,
    private parametroServicio: ParametrosService,
  ) {
    moment.locale('es');
    this.form = this.fb.group({
      clienteSel: '',
      razonsocial: '',
      tipo: '',
      articuloSel: '',
      observaciones: '',
      moneda: 'Pesos',
      lista: 'sinseleccion',
    });
    this.setClickedRow = function (index) {
      this.selectedRow = index;
    };
  }

  ngOnInit() {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Caja';
    }
    this.fecha = moment().format('L');
    this.hora = moment().format('LT');
    this.isCajaAbierta();
    this.uid = this.dataService.getUID();
    this.cajero = this.dataService.getNombre()
    this.form.controls.clienteSel.setValue(this.cliente, {
      onlySelf: true,
    });
    this.getClienteByRazonSocial('Consumidor Final');
    this.getListaPrecios();
    this.getConceptos();
    this.getMonedas();
    this.obtenerMediosDePagoByNombre('Efectivo');
    this.obtenerTipoComprobanteByCodigo('083');
  }

  ngAfterViewInit() {
    this.setFocusArticulo();
  }

  getMonedas() {
    this.paramService.getMonedas().subscribe((data) => {
      this.monedas = data;
      let moneda = this.monedas.find(x => x.codigo === 'PES')
      this.form.controls.moneda.setValue(moneda?._id, {
        onlySelf: true
      })
      this.form.controls.moneda.disable();
    });
  }

  getClienteByRazonSocial(razonsocial) {
    this.clienteService
      .getClienteByRazonSocial(razonsocial)
      .subscribe((data) => {
        this.unCliente = data;
      });
  }

  getClienteByTipo(tipo) {
    this.clienteService
      .getClientesByTipo(tipo)
      .subscribe((data) => {
        this.unCliente = data;
      });
  }

  getListaPrecios() {
    this.listaprecioService.getListaPrecios().subscribe((data) => {
      this.listaprecios = data;
      this.form.controls.lista.setValue(this.listaprecios[0]._id)
    });
  }

  getConceptos() {
    this.parametroServicio.getConceptos().subscribe((data) => {
      let conceptos = data;
      let indiceOtros = conceptos.findIndex(x => x.Desc.toLowerCase().includes('otros'));
      conceptos.splice(indiceOtros, 1);
      this.concepto = conceptos.find(x => x.Id === '1');
    })
  }

  openSnackBar(message: string, tipo) {
    this.snackBar.open(message, tipo, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

  obtenerMediosDePagoByNombre(nombre: string) {
    this.paramService.getMediosPagosByNombre(nombre).subscribe(
      (data) => {
        this.fpago = data;
      },
      (err) => console.log(err)
    );
  }

  obtenerTipoComprobanteByCodigo(codigo) {
    this.tipoComprobService.getTipoComprobanteByCodigo(codigo).subscribe(
      (data) => {
        this.tipoComprobante = data;
        this.getNumeroComprobante(this.tipoComprobante._id);

      },
      err => console.log(err)
    )
  }

  getNumeroComprobante(tipo) {
    this.comprobanteSerice.getComprobanteByTipoNumero(tipo).subscribe(
      res => {
        let punto = this.dataService.formatearPuntoVenta(res.pventa);
        let nro = this.dataService.formatearNumeroComprobante(res.numero);
        this.punto = res.pventa;
        this.nro = res.numero;
      },
      err => {
        console.log(err)
      }
    )
  }

  isCajaAbierta() {
    this.servicioGrales.getCajaAbierta().subscribe(
      (data) => {
        if (data) {
          this.cajaActual = data as Caja;
        } else {
          this.cajaActual = null;
          this.verInputBuscarCliente = true;
          this.verBtnBuscarCliente = true;
          this.openDialogAperturaCaja();
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        this.verBtnConfirmar = true;
        this.verBtnFPago = true;
        this.verBtnBuscarArticulo = true;
        this.verInputArticulo = true;
        if (this.cajaActual !== null) {
          this.VerBtnCerrarCaja = true;
          this.VerBtnAbrirCaja = false;
        }
      }
    );
  }

  comprobarListaPrecio() {
    console.log(this.form.controls.lista.value);
  }

  verListaClientes() {
    if (this.cajaActual !== undefined) {
      const dialogRef = this.dialog.open(ListaClienteCajaComponent, {
        width: '50%',
        minHeight: '200px',
        data: {
          tipo: 'cliente',
        },
        disableClose: true,
        autoFocus: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result !== undefined) {
          this.unCliente = result;
          this.cajaService.eliminarListaArticulos();
          this.setFocusArticulo();
          this.form.controls.clienteSel.setValue(this.unCliente?.razonsocial, {
            onlySelf: true,
          });
        }
      });
    } else {
      Swal.fire(
        'Atención!',
        'Debe abrir la caja para poder continuar.',
        'warning'
      );
    }
  }

  verListaArticulos() {
    if (!this.cajaActual) {
      Swal.fire(
        'Atención!',
        'Debe abrir la caja para poder continuar.',
        'warning'
      );
      return
    }
    const dialogRef = this.dialog.open(ListaProductosDialogoComponent, {
      width: '60%',
      maxHeight: '85%',
      data: {
        tipo: 'articulo',
        bonificacion: this.unCliente?.bonificacion,
      },
      disableClose: true,
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.calcularTotal();
      this.verBtnFPago = false;
      this.verBtnConfirmar = false;
    });
  }

  verDialogoOk(borrar: boolean) {
    let formExiste = false;
    if (this.listaFormularioImprimir.length > 0) {
      formExiste = true;
    }
    const dialogRef = this.dialog.open(DialogoComponent, {
      width: '300px',
      data: {
        tipo: 'ok',
        formulario: formExiste,
      },
      disableClose: true,
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (borrar) {
          if (result) {
            const dialogRef2 = this.dialog.open(DialogoComponent, {
              width: '70%',
              height: '700px',
              data: {
                tipo: 'imprimirresumen',
                total: (this.total = result['total']),
                pagos: (this.lstPagos = result['pagos']),
                detalle: this.listaFormularioImprimir,
                sujeto: this.unCliente,
              },
              disableClose: true,
              autoFocus: true,
            });
            dialogRef2.afterClosed().subscribe((result) => {
              if (result !== undefined) {
                this.calcularTotal();
                this.auditoriaService
                  .guardarAuditoria(
                    '',
                    '',
                    'caja',
                    'imprimir resumen',
                    sessionStorage.getItem('userid'),
                    'imprimir'
                  )
                  .subscribe();
              }
            });
          } else {
            this.calcularTotal();
          }
        }
      },
      (err) => console.log(err),
      () => {
        this.calcularTotal();
        this.cancelar();
      }
    );
  }

  // confirmamos la operacion
  verConfirmar() {
    if (this.VerBtnAbrirCaja) {
      Swal.fire('Abrir Caja!', 'Para continuar con la venta debe abrir la caja.', 'warning');
      return;
    } 
    this.loading = true;
    const dialogRef = this.dialog.open(ConfirmarCajaComponent, {
      width: '40%',
      height: 'auto',
      data: {
        tipo: 'confirmar',
        total: this.total,
      },
      disableClose: true,
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.cambio = result['cambio'];
        this.montoing = result['montoing'];
        this.total = result['total'];
        this.lstPagos = result['pagos'];
        this.openDialogLoading();
        this.guardarVenta();
      } else {
        this.loading = false;
      }
    });
  }

openDialogLoading() {
  this.dialogRefLoading = this.dialog.open(LoadingComponent, {
    data: {
      mensaje: 'Procesando la venta! Espere ...'
    },
    disableClose: true,
    autoFocus: true,
  });
}
  
  /**
   * Abre el dialogo para el proceso de cierre de caja
   *
   * @memberof CajaComponent
   */
  openDialogCierreCaja(): void {
    let montoCierre = 0;
    let totalEfectivo = 0;
    let totalPagoEfectivo = 0;
    let totalDebito = 0;
    let totalPagoDebito = 0;
    let totalCredito = 0;
    let totalPagoCredito = 0;
    let totalCheque = 0;
    let totalPagoCheque = 0;
    let totalTransferencia = 0;
    let totalPagoTransferencia = 0;
    let totalCompra = 0;
    let montoTotal = 0;
    let montoApertura = this.cajaActual.montoinicio;
    let saldoCajaTotal = 0;
    let totalIngreso = 0;
    if (this.cajaActual !== undefined) {
      let Operaciones;
      this.servicioGrales.getSumaMovientosByCaja(this.cajaActual._id).subscribe(
        (data) => {
          montoCierre = data['totalCobro'];
          totalCompra = data['totalPago'];
          Operaciones = data['cajaSel'];
          totalPagoEfectivo = data['efectPago'];
          totalEfectivo = data['efectCobro'];
          totalPagoDebito = data['debitoPago'];
          totalDebito = data['debitoCobro'];
          totalPagoCredito = data['creditoPago'];
          totalCredito = data['creditoCobro'];
          totalPagoCheque = data['chequePago'];
          totalCheque = data['chequeCobro'];
          totalPagoTransferencia = data['transfPago'];
          totalTransferencia = data['transfCobro'];
          totalIngreso = data['totalIngreso'];
        },
        (err) => console.log(err),
        () => {
          montoTotal = montoCierre + montoApertura + totalIngreso;
          saldoCajaTotal =
            totalEfectivo + montoApertura + totalIngreso - totalPagoEfectivo;
          const dialogRef = this.dialog.open(CierreComponent, {
            width: '50%',
            data: {
              cajaActual: this.cajaActual,
              montoApertura: montoApertura,
              fechaApertura: moment(this.cajaActual.apertura).format('L LTS'),
              montoTotal: montoTotal,
              totalIngreso: totalIngreso,
              totalCompra: totalCompra,
              efectivo: totalEfectivo,
              efectivoPago: totalPagoEfectivo,
              debito: totalDebito,
              debitoPago: totalPagoDebito,
              credito: totalCredito,
              creditoPago: totalPagoCredito,
              cheque: totalCheque,
              chequePago: totalPagoCheque,
              transferencia: totalTransferencia,
              transferenciaPago: totalPagoTransferencia,
              saldoCajaTotal: saldoCajaTotal,
              total: this.total,
            },
            disableClose: true,
            autoFocus: true,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
              if (result === 'Caja cerrada correctamente') {
                Swal.fire(
                  'Excelente!',
                  'Se realizó el cierre de caja correctamente',
                  'success'
                );
                this.VerBtnCerrarCaja = false;
                this.VerBtnAbrirCaja = true;
                this.verInputBuscarCliente = true;
                this.cajaActual = undefined;
              }
            }
          });
        }
      );
    }
  }

  openDialogAperturaCaja(): void {
    const dialogRef = this.dialog.open(AperturaComponent, {
      width: '450px',
      disableClose: true,
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // si el usuario acepto abrir la caja
      if (result !== undefined) {
        this.cajaActual = new Caja();
        this.cajaActual.abierto = true;
        this.cajaActual.apertura = new Date();
        this.cajaActual.fecha = new Date();
        this.cajaActual.cierre = null;
        this.cajaActual.montoinicio = result.monto;
        this.cajaActual.efectivoCaja = 0;
        this.cajaActual.totalCobro = 0;
        this.cajaActual.numero = result.numero;
        this.cajaActual.totalPago = 0;
        this.cajaActual.efectPago = 0;
        this.cajaActual.efectCobro = 0;
        this.cajaActual.debitoPago = 0;
        this.cajaActual.debitoCobro = 0;
        this.cajaActual.creditoPago = 0;
        this.cajaActual.creditoCobro = 0;
        this.cajaActual.chequePago = 0;
        this.cajaActual.chequeCobro = 0;
        this.cajaActual.transfPago = 0;
        this.cajaActual.puntoventa = result.puntoventa;
        this.cajaActual.transfCobro = 0;
        this.cajaActual.idUsuario = this.uid;
        this.cajaActual.cajero = this.cajero;
        this.servicioGrales.guardarCaja(this.cajaActual).subscribe(
          (data) => {
            this.cajaActual._id = data.caja?._id;
            Swal.fire(
              'Excelente!',
              'Ya se realizó la apertura de caja',
              'success'
            );
            this.VerBtnCerrarCaja = true;
            this.VerBtnAbrirCaja = false;
            this.verInputBuscarCliente = false;
            this.verBtnBuscarCliente = false;
            this.cajaActual._id = data['idcaja'];
          },
          (err) => console.log(err)
        );
      } else {
        // si no acepto entonces mostramos el boton abrir caja y deshabilitamos todas las opciones
        this.VerBtnCerrarCaja = false;
        this.VerBtnAbrirCaja = true;
        this.verInputArticulo = true;
        this.verBtnBuscarArticulo = true;
      }
    });
  }

  obtenerUnArticulo(idarticulo) {
    if (!this.cajaActual) {
      Swal.fire(
        'Atención!',
        'Debe abrir la caja para poder continuar.',
        'warning'
      );
      return
    }
    if (!idarticulo) {
      this.verListaArticulos();
    } else {
      this.buscarArticulo(idarticulo);
      this.limpiarCamposNuevoArticulo();
    }

  }

  /*
    Recibo un id de articulo y busco el articulo completo en la base de datos
  */
  buscarArticulo(idarticulo, tipotabla?: boolean) {
    let articulo: Producto;
    if (idarticulo !== '') {
      // obtengo el articulo
      this.articuloService.getArticuloPorCodigo(idarticulo).subscribe(
        (data) => {
          articulo = data as Producto;
          if (this.comprobarArticuloCargado(articulo._id)) {
            const index = this.comprobarIndiceArticuloCargado(articulo._id);
            this.cajaService.articulosList[index].cantidad += 1;
            let subt = 0;
            const cantidadXPrecio = (articulo.precioventa * this.cajaService.articulosList[index].cantidad);
            const bonif = (this.unCliente.bonificacion * cantidadXPrecio) / 100;
            subt = (cantidadXPrecio - bonif);
            this.cajaService.articulosList[index].subtotalciva = subt;
            this.cajaService.articulosList[index].subtotal = subt;
          } else {
            let subt = 0;
            const bonif = (this.unCliente.bonificacion * articulo.precioventa) / 100;
            subt = (articulo.precioventa - bonif);
            this.cajaService.addArticulo({
              _id: articulo._id,
              codigo: articulo.codigo,
              descripcion: articulo.nombre,
              tipo: articulo.tipo,
              preciounitario: articulo.precioventa,
              cantidad: 1,
              stock: Array.of(articulo.stock),
              bonificacion: this.unCliente.bonificacion,
              importebonif: 0,
              subtotal: subt,
              subtotalciva: subt,
              articulo
            });
          }
          this.calcularTotal();
        },
        (err) => {
          this.openSnackBar('No se encontró ningún producto con el código de barra ingresado', 'Información')
        }
      );
    }
  }

  verDialogoSinStock(nombre) {
    const dialogRef = this.dialog.open(DialogoComponent, {
      width: '450px',
      data: { tipo: 'sinstock', articuloNombre: nombre },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(() => { });
  }

  obetenerStockById(idstock): Stock {
    let stockdb: Stock;
    this.articuloService.getStockById(idstock).subscribe(
      (data) => (stockdb = data as Stock),
      (err) => console.error(err)
    );
    return stockdb;
  }

  buscarCliente(cliente: string) {
    if (this.cajaActual !== undefined) {
      if (cliente !== '') {
        this.clienteService.getClienteByNumero(cliente).subscribe(
          (data) => {
            this.unCliente = data;
            this.verInputArticulo = false;
            this.verBtnBuscarArticulo = false;
            this.form.controls.clienteSel.setValue(
              this.unCliente?.razonsocial,
              {
                onlySelf: true,
              }
            );
          },
          (err) => {
            console.log(err.error);
          },
          () => {
            this.setFocusArticulo();
          }
        );
      } else {
        this.clienteService.getClienteByNumero('1').subscribe(
          (data) => (this.unCliente = data),
          (err) => console.log(err),
          () => {
            if (this.unCliente !== undefined) {
              this.cliente = this.unCliente.razonsocial;
              this.clienteSel = '';
              this.verInputArticulo = false;
              this.verBtnBuscarArticulo = false;
            }
            this.setFocusArticulo();
          }
        );
      }
    } else {
      Swal.fire(
        'Atención!',
        'Debe abrir la caja para poder continuar.',
        'warning'
      );
    }
  }

  limpiarCamposNuevoArticulo() {
    this.articuloElement.nativeElement.value = '';
    this.setFocusArticulo();
  }

  cargarArticulo(articulo: any) {
    let stockdb;
    if (articulo !== undefined) {
    }
  }

  editarArticulo(articulo, indice?: number) {
    let bonificacion= this.unCliente.bonificacion;
    const dialogRef = this.dialog.open(EditarCantidadesComponent, {
      width: '450px',
      data: {
        articulo: articulo,
        unArticulo: articulo.articulo,
        indice,
        bonificacion,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.calcularTotal();
    });
  }

  comprobarArticuloCargado(id: string): boolean {
    let resul = false;
    for (let index = 0; index < this.cajaService.articulosList.length; index++) {
      const articulo = this.cajaService.articulosList[index];
      if (articulo._id === id) {
        resul = true;
        break;
      }
    }
    return resul;
  }

  comprobarIndiceArticuloCargado(id: string): number {
    let resul = -1;
    for (let index = 0; index < this.cajaService.articulosList.length; index++) {
      const articulo = this.cajaService.articulosList[index];
      if (articulo._id === id) {
        resul = index;
        break;
      }
    }
    return resul;
  }

  cambiarPrecioArticulo(articulo: Producto, preciounit): number {
    // calculamos los valores
    return this.servicioGrales.calcularCostoUnitario(
      preciounit,
      articulo.preciocompra
    );
  }

  getArticuloTablaByIndice(codigo: string): any {
    let resul: any;
    for (let index = 0; index < this.lstarticulos.length; index++) {
      const articulo = this.lstarticulos[index];
      if (articulo.idstock === codigo) {
        resul = articulo;
        break;
      }
    }
    return resul;
  }

  guardarVenta() {
    let opercaja = new OperacionCaja();
    opercaja.estado = 'correcto';
    opercaja.tipo = 'venta';
    opercaja.observaciones = this.observaciones;
    opercaja.caja = this.cajaActual._id;
    opercaja.fecha = new Date();
    opercaja.mediopago = this.lstPagos;
    opercaja.usuario = this.uid;
    opercaja.montocobrar = this.total;
    opercaja.montopago = 0;
    opercaja.montocambio = this.cambio;
    opercaja.cliente = this.unCliente?._id;
    opercaja.montorecibido = this.montoing;
    opercaja.detalle = this.cajaService.articulosList;
    this.comprobante = new Comprobante();
    this.cabecera = new Cabecera();
    // Cabecera
    let fecha = dayjs(this.fecha.value).format('DD/MM/YYYY');
    let fechaDesde = dayjs(this.fecha.value).format('DD/MM/YYYY');
    let fechaHasta = dayjs(this.fecha.value).format('DD/MM/YYYY');
    this.cabecera.puntoventa = this.punto;
    this.cabecera.numero = this.nro;
    this.cabecera.condicionventa = 'contado';
    this.cabecera.cuitemisor = '304586956692';
    this.cabecera.domicilio = 'mitre 144';
    this.cabecera.fecha = fecha;
    this.cabecera.fechadesde = fechaDesde;
    this.cabecera.fechahasta = fechaHasta;
    this.cabecera.fechavencimiento = fechaDesde;
    this.cabecera.ingresosbrutos = '304586956692';
    this.cabecera.inicioactividad = '20/02/1990';
    this.cabecera.razonsocial = 'Kocursoft';
    // Comprobante
    this.comprobante.cantreg = this.cajaService.articulosList.length;
    this.comprobante.cabecera = this.cabecera;
    this.comprobante.cae = '';
    this.comprobante.codigobarras = '';
    this.comprobante.comentario = this.form.controls.observaciones.value;
    this.comprobante.compasociado = [];
    this.comprobante.concepto = this.concepto;
    this.comprobante.moneda = this.form.controls.moneda.value;
    this.comprobante.cliente = this.unCliente;
    this.comprobante.electronico = false;
    this.comprobante.enviado = false;
    this.comprobante.estado = 'creado';
    this.comprobante.tipo = 'ventas';
    this.comprobante.fechavtocae = '';
    this.comprobante.impiva = 0;
    this.comprobante.impneto = this.total;
    this.comprobante.impopext = 0;
    this.comprobante.imptotconc = 0;
    this.comprobante.imptrib = 0;
    this.comprobante.usuario = this.uid;
    this.comprobante.imptotal = this.total;
    this.comprobante.moncotiz = 0;
    this.comprobante.comentario = this.form.controls.observaciones.value;
    this.comprobante.tipocomprobante = this.tipoComprobante?._id;
    // Detalle
    this.comprobante.detalle = this.cajaService.articulosList;
    //Guardamos operacion caja
    this.cajaService.guardarOperacionCaja(opercaja, this.comprobante).subscribe(
      (data) => {
        this.cancelar();
        Swal.fire(
          'Excelente!',
          'Operación registrada con éxito',
          'success'
        );
      },
      (err) => {
        console.log(err)
        this.loading = false;
        this.openSnackBar('Ocurrió un error al generar la venta', 'Error');
      }
    )
  }

  calcularTotal() {
    this.total = 0;
    for (
      let index = 0;
      index < this.cajaService.articulosList.length;
      index++
    ) {
      const art = this.cajaService.articulosList[index];
      const subtotal = art.subtotal;
      this.total = this.formatearPrecios(this.total + subtotal);
    }
    this.cantidad = 0;
    this.limpiarCamposNuevoArticulo();
  }

  formatearPrecios(valor: number) {
    let numeroFormateado = 0;
    if (valor > 0) {
      const precioString = valor.toString();
      const indice = precioString.indexOf('.');
      if (indice !== -1) {
        // existe punto
        const calculo = precioString.length - indice - 1;
        if (calculo >= 2) {
          numeroFormateado = Number.parseFloat(
            precioString.substring(0, indice + 3)
          );
        } else {
          numeroFormateado = Number.parseFloat(`${precioString}.00`);
        }
      } else {
        numeroFormateado = Number.parseFloat(`${precioString}.00`);
      }
    } else {
      numeroFormateado = valor;
    }
    return numeroFormateado;
  }

  eliminar(i) {
    const index = this.cajaService.articulosList.findIndex(x => x._id == i._id);
    if (index !== -1) {
      // eliminamos el item
      this.cajaService.articulosList.splice(index, 1);
      this.calcularTotal();
      this.openSnackBar('Registro eliminado', 'Información');
    }
  }

  setFocusCantidad() {
    this.cantidadElement.nativeElement.focus();
  }

  setFocusCliente() {
    this.clienteElement.nativeElement.focus();
  }

  setFocusArticulo() {
    this.articuloElement.nativeElement.focus();
  }

  cancelarVenta() {
    Swal.fire({
      title: '¿Seguro que desea cancelar la venta?',
      text: 'Se perderan los cambios cargados hasta el momento!',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, quiero cancelar!',
    }).then((result) => {
      if (result.value) {
        this.cancelar();
        Swal.fire('Cancelado!', 'Su venta ha sido cancelada.', 'success');
      }
    });
  }

  cancelar() {
    this.lstarticulos = [];
    this.total = 0;
    this.unCliente = null;
    this.verInputBuscarCliente = false;
    this.verBtnBuscarCliente = false;
    this.verInputArticulo = true;
    this.verBtnBuscarArticulo = true;
    this.verBtnConfirmar = true;
    this.verBtnFPago = true;
    this.clienteSel = undefined;
    this.cambio = 0;
    this.montoing = 0;
    this.total = 0;
    this.lstPagos = [];
    this.lstDetalles = [];
    this.observaciones = '';
    this.listaFormularioImprimir = [];
    this.cajaService.eliminarListaArticulos();
    this.limpiarCamposNuevoArticulo();
    this.loading = false;
    this.dialogRefLoading.close();
  }

}
