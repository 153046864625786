export class ListaPrecio {
  nombre: string;
  codigo: string;
  articulos: [];
  estado: string;
  descripcion: string;
  observacion: string;
  perfil : [];
  desde: string;
  hasta: string;
}
