import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Detalle } from 'app/models/detalle.models';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CajaService {

  public lstArticulos = new Subject<any>();
  public articulosList: Detalle[] = [];

  constructor(private http: HttpClient) { }

  addArticulo(articulo: Detalle) {
    this.articulosList.push(articulo);
    this.lstArticulos.next(this.articulosList);
  }

  quitarCobro(id) {
    this.articulosList.splice(this.articulosList.findIndex(x => x._id === id), 1);
    this.lstArticulos.next(this.articulosList);
  }

  editarCobro(indice, datos) {
    this.articulosList[indice] = datos;
    this.lstArticulos.next(this.articulosList);
  }

  eliminarListaArticulos() {
    this.articulosList = [];
    this.lstArticulos.next(this.articulosList);
  }

  eliminarDatosCargados(todosForm): any {
    todosForm.forEach(element => {
      this.articulosList.forEach(e => {
        if (e._id === element._id) {
          todosForm.splice(todosForm.indexOf(element), 1);
        }
      });
    });
    return todosForm;
  }

  // guardamos el detalle de la operacion
  saveDetalle(detalle: Detalle) {
    return this.http.post(environment.apiUrl + 'detalle', detalle);
  }

  // guardamos operación caja y genera el comprobante
  guardarOperacionCaja(opercaja, comprobante) {
    return this.http.post(environment.apiUrl + 'caja/movimiento', {
      opercaja, comprobante
    });
  }

}
