import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ArchivosService {
  constructor(private http: HttpClient) { }

  getArticulosExcel() {
    return this.http.get(environment.apiUrl + "articulos/excel", {
      responseType: "blob" as "json",
    });
  }

  getClientesExcel() {
    return this.http.get(environment.apiUrl + "clientes/excel", {
      responseType: "blob" as "json",
    });
  }

  importarArchivoExcel(file: File) {
    let formData = new FormData();
    formData.append("archivo", file);
    return this.http.post(environment.apiUrl + "upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  subirImagen(imagen: File) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("archivo", imagen);
      this.http.post(environment.apiUrl + "upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        )
    });
  }

  procesarArchivoExcel(nombreFile: string) {
    return this.http.post(environment.apiUrl + "articulos/importar/excel", {
      nombreFile,
    });
  }

  procesarArchivoExcelClientes(nombreFile: string) {
    console.log(nombreFile);
    return this.http.post(environment.apiUrl + "clientes/importar/excel", {
      nombreFile,
    });
  }
}
