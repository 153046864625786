import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Caja } from 'app/models/caja.models';

@Injectable({
  providedIn: 'root'
})
export class GeneralesService {

  bancos: Observable<any>;
  headers: HttpHeaders;

  constructor(private http: HttpClient) { }

  calcularPrecioVenta(preciounitario: number, utilidad: number, totalimpuestos: number): number {
    /*
      Para el calculo del precio de venta se utiliza la formula
      P = Costo / (1-% margen sobre ventas)
    */
    const preciov = (preciounitario) / (1 - (utilidad / 100));
    const aux = Number.parseFloat((preciov + totalimpuestos).toFixed(2));
    return aux;
  }

  calcularCostoUnitario(precioventa: number, utilidad: number): number {
    const aux = Number.parseFloat((precioventa * (1 - (utilidad / 100))).toFixed(2));
    return aux;
  }

  // x = 153.84   = 100 * (1 - 0.35)

  getOperacionCajas(): Observable<any> {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'operacioncajas', { headers: this.headers });
  }

  getResumen(): Observable<any> {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'resumen', { headers: this.headers });
  }

  getAperturaCajas(): Observable<any> {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'caja/ingresos', { headers: this.headers });
  }

  getOperacionCajasVentas(): Observable<any> {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'operacioncaja/ventas/all', { headers: this.headers });
  }

  getIngresosDineroEnCaja(caja): Observable<any> {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'cajas/ingresos/' + caja._id, { headers: this.headers });
  }

  obtenerValoresAcumuladosHistoricos() {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'valoresacumulados/ingresos', { headers: this.headers });
  }

  getVentasByApellido(apellido: string) {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'operacioncaja/ventas/nombre/' + apellido, { headers: this.headers });
  }

  eliminarVenta(opercaja): Observable<any> {
    this.getOptions();
    return this.http.delete(environment.apiUrl + 'operacioncaja/' + opercaja, { headers: this.headers });
  }

  eliminarIngreso(opercaja): Observable<any> {
    this.getOptions();
    return this.http.delete(environment.apiUrl + 'operacioncaja/ingreso/' + opercaja, { headers: this.headers });
  }

  getCajaAbierta(): Observable<any> {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'caja/abierta', { headers: this.headers });
  }

  getMovimientosCaja(): Observable<any> {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'cajas/movimientos', { headers: this.headers });
  }

  getSumaMovientosByCaja(idCaja): Observable<any> {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'cajas/suma/movimientos/' + idCaja, { headers: this.headers });
  }

  getArrayVentasXDia(): Observable<any> {
    this.getOptions();
    return this.http.get(environment.apiUrl + 'cajas/movimientos/ventaxdia', { headers: this.headers });
  }

  guardarCaja(caja: Caja): Observable<any> {
    this.getOptions();
    return this.http.post(environment.apiUrl + 'caja', caja, { headers: this.headers });
  }

  addIngresoDineroACaja(caja) {
    this.getOptions();
    return this.http.post(environment.apiUrl + 'operacioncaja/ingresodinero', caja, { headers: this.headers });
  }

  actualizarIngresoCaja(caja): Observable<any> {
    this.getOptions();
    return this.http.put(environment.apiUrl + 'operacioncaja/' + caja._id, caja, { headers: this.headers });
  }

  cerrarCaja(caja): Observable<any> {
    this.getOptions();
    return this.http.put(environment.apiUrl + 'caja/cerrar/' + caja._id, caja, { headers: this.headers });
  }

  private getOptions(): any {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('token')
    });
  }

}
