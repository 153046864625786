import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cliente } from 'app/models/cliente.models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  negocio: Observable<Cliente | any>;
  clientes: Observable<Cliente[] | any>;

  constructor(private http: HttpClient) { }

  getClientes(pagina: number, itemXPag: number, search?: string): Observable<Cliente | any> {
      return this.clientes = this.http.get(environment.apiUrl + `clientes?pag=${pagina}&itemXPag=${itemXPag}&search=${search}`);
  }

  getClientesByTipo(tipo: string): Observable<Cliente | any> {
    return this.clientes = this.http.get(environment.apiUrl + 'clientes/tipo/' + tipo);
  }

  getNuevoNumeroCliente(): Observable<any> {
    return this.http.get(environment.apiUrl + 'cliente/codigo/nuevo');
  }

  getCantidadClientesActivos(): Observable<Cliente | any> {
      return this.clientes = this.http.get(environment.apiUrl + 'clientes/cantidad');
  }

  getCliente(cuit: string): Observable<Cliente | any> {
      return this.clientes = this.http.get(environment.apiUrl + 'cliente/' + cuit);
  }

  getClienteById(id: string): Observable<Cliente | any> {
      return this.http.get(environment.apiUrl + 'cliente/' + id);
  }

  getExisteClienteByCuit(nro: string): Observable<Cliente | any> {
      return this.clientes = this.http.get(environment.apiUrl + 'cliente/existe/' + nro);
  }

  getClienteByNumero(nro: string): Observable<Cliente | any> {
      return this.clientes = this.http.get(environment.apiUrl + 'cliente/numero/' + nro);
  }

  getClienteByRazonSocial(razonsocial: string): Observable<Cliente | any> {
      return this.clientes = this.http.get(environment.apiUrl + 'cliente/razonsocial/' + razonsocial);
  }


  eliminarCliente(id: string): Observable<string | any> {
      return this.http.delete(environment.apiUrl + 'cliente/' + id);
  }

  actualizarCliente(id: string, cliente: Cliente): Observable<string | any> {
      return this.http.put(environment.apiUrl + 'cliente/' + id, cliente);
  }

  guardarCliente(cliente: Cliente): Observable<any> {
      return this.http.post(environment.apiUrl + 'cliente', cliente);
  }


}
