import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'; 
import { RouteInfo } from 'app/sidebar/sidebar.component'; 
@Injectable({
  providedIn: 'root'
})
export class DataService {
 
  token;
  avatar = new BehaviorSubject<string>('');
  uid = new BehaviorSubject<string>('');
  username = new BehaviorSubject<string>('');
  rol = new BehaviorSubject<string>('');
  ruta = new BehaviorSubject<string>('Kocursoft');
  app = new BehaviorSubject<boolean>(true);
  empresa = new BehaviorSubject<string>('');
  empresarazonsocial = new BehaviorSubject<string>('');
  empresalogo = new BehaviorSubject<string>('assets/img/logo.jpg');
  menu = new BehaviorSubject<any>([]);
  isCloseDialog = new BehaviorSubject<boolean>(false);

  ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Inicio', icon: 'nc-shop', class: '', activa: true },
    { path: '/clientes', title: 'Clientes y Proveedores', icon: 'nc-badge', class: '', activa: true},
    { path: '/productos', title: 'Productos Y Servicios', icon: 'nc-tile-56', class: '', activa: true },
    { path: '/ventas', title: 'Ventas', icon: 'nc-money-coins', class: '', activa: true },
    { path: '/compras', title: 'Compras', icon: 'nc-cart-simple', class: '', activa: true },
    { path: '/caja', title: 'Caja', icon: 'nc-bank', class: '', activa: true },
    { path: '/reportes', title: 'Reportes', icon: 'nc-paper', class: '', activa: true },
    { path: '/configurar', title: 'Configuración', icon: 'nc-settings-gear-65', class: '', activa: true}
  ];

  deferredPrompt;
  dispositivo = {};
  isMobile = false;
  isTablet = false;
  isDesktopDevice = true;
  UserActual;

  constructor() { }

  setPermisos(data: any) {
    this.ROUTES = Array.from(data);
    this.menu.next(this.ROUTES);
    sessionStorage.setItem('permisos', JSON.stringify(data));
  }

  getPermisos() {
    if (sessionStorage.getItem('permisos')) {
      this.ROUTES = Array.from(JSON.parse(sessionStorage.getItem('permisos')));
    }    
    this.menu.next(this.ROUTES);
  }

  cifrarToken(token) {
    sessionStorage.setItem('token', token);
  }

  actualizarToken(token) {
    this.token.next(token);
  }

  setRutaActual(ruta) {
    this.ruta.next(ruta);
  }

  setVariablesSession(data) {
    this.UserActual = data;
    this.avatar.next(data?.avatar);
    this.uid.next(data.uid);
    this.username.next(data?.username);
    this.empresarazonsocial.next(data?.empresa);
    this.empresalogo.next(data?.logo);
    this.rol.next(data?.rol);
    sessionStorage.setItem('rol', data?.rol);
    sessionStorage.setItem('username', data.username);
    sessionStorage.setItem('uid', data.uid);
    sessionStorage.setItem('empresa', data?.empresa);
    sessionStorage.setItem('empresalogo', data?.logo);
    sessionStorage.setItem('empresa', data?.empresa);
    sessionStorage.setItem('avatar', data?.avatar);
  }

  getDatos() {
    this.token = sessionStorage.getItem('token');
    this.uid.next(sessionStorage.getItem('uid'));
    this.avatar.next(sessionStorage.getItem('avatar'));
    this.username.next(sessionStorage.getItem('username'));
    this.empresa.next(sessionStorage.getItem('empresa'));
    this.empresarazonsocial.next(sessionStorage.getItem('empresa'));
    this.empresalogo.next(sessionStorage.getItem('empresalogo'));
    this.rol.next(sessionStorage.getItem('rol'));
  }

  deleteVariablesSession() {
    this.avatar.unsubscribe;
    this.uid.unsubscribe;
    this.username.unsubscribe;
    this.empresarazonsocial.unsubscribe;
    this.empresa.unsubscribe;
    this.empresalogo.unsubscribe;
    this.rol.unsubscribe;
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('uid');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('empresa');
    sessionStorage.removeItem('empresalogo');
    sessionStorage.removeItem('empresarazonsocial');
    sessionStorage.removeItem('rol');
    sessionStorage.removeItem('permisos');
    sessionStorage.removeItem('avatar');
  }


  async getToken() {
    if (this.token) {
      return this.token;
    } else {
      return sessionStorage.getItem('token');
    }  
  }

  setToken(token) {
    this.token = token;
  }


  getNombre() {
    if (this.username.value) {
      return this.username.value;
    } else {
      return sessionStorage.getItem('username');
    }
  }

  getUID() {
    if (this.uid.value) {
      return this.uid.value;
    } else {
      return sessionStorage.getItem('uid');
    }
  }

  setTokenData() {
    sessionStorage.setItem('token', this.token.value);
  }

  formatearPuntoVenta(value): string {
    let nro;
    if (value) {
      switch (value.toString().length) {
        case 1:
          nro = '0000' + value
          break;
        case 2:
          nro = '000' + value
          break;
        case 3:
          nro = '00' + value
          break;
        case 4:
          nro = '0' + value
          break;
        case 5:
          nro = value
        default:
          break;
      }
    }    
    return nro;
  }

  formatearNumeroComprobante(value): string {
    let nro;
    switch (value.toString().length) {
      case 1:
        nro = '0000000' + value
        break;
      case 2:
        nro = '000000' + value
        break;
      case 3:
        nro = '00000' + value
        break;
      case 4:
        nro = '0000' + value
        break;
      case 5:
        nro = '000' + value
        break;
      case 6:
        nro = '00' + value
        break;
      case 7:
        nro = '0' + value
        break;
      default:
        nro = value
        break;
    }
    return nro;
  
  }

}
