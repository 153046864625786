import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ListaPrecio } from 'app/models/listaprecio.models';
import { ListaprecioService } from 'app/services/listaprecio.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-lista-precio',
  templateUrl: './create-lista-precio.component.html',
  styleUrls: ['./create-lista-precio.component.css']
})
export class CreateListaPrecioComponent implements OnInit {
  listaPrecio: ListaPrecio;
  form: UntypedFormGroup;
  listaPrecios = [];
  total = 0;

  constructor(
    private fb: UntypedFormBuilder,
    private listaPrecioService: ListaprecioService,
    private router: Router
    ) {
    this.form = this.fb.group({
      numero: new UntypedFormControl(''),
      codigo: new UntypedFormControl(''),
      desde: new UntypedFormControl(''),
      hasta: new UntypedFormControl(''),
      descripcion: new UntypedFormControl(''),
      observacion: new UntypedFormControl(''),
      perfil: new UntypedFormControl('venta'),
      nombre: new UntypedFormControl('')
    });
  }

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Nueva lista de precios';
    }
    this.listaPrecio = new ListaPrecio();
    this.getListaPrecios();
  }

  getListaPrecios() {
    this.listaPrecioService.getListaPrecios().subscribe(
      (data) => {
        this.listaPrecios = data;
        this.total = this.listaPrecios.length + 1;
        this.form.controls.codigo.setValue(this.total, {
          onlySelf: true
        })
      },
      err => console.log(err)
    )
  }

  guardar() {
    this.listaPrecio.codigo = this.form.value.codigo;
    this.listaPrecio.nombre = this.form.value.nombre;
    this.listaPrecio.observacion = this.form.value.observacion;
    this.listaPrecio.descripcion = this.form.value.descripcion;
    this.listaPrecio.perfil = this.form.value.perfil;
    this.listaPrecio.articulos = [];
    this.listaPrecioService.guardarListaPrecio(this.listaPrecio).subscribe(
      (data) => {
        console.log(data);
        Swal.fire(
          'Excelente!',
          'Ya has creado la lista de precio',
          'success'
        );
        this.router.navigate(['configurar/lista-precio']);
      },
      err => console.log(err)
    )
  }

}
