import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuditoriaService } from 'app/services/auditoria.service';
import { AuthService } from 'app/services/auth.service';
import Swal from 'sweetalert2';
import { DialogoComponent } from '../../dialogo/dialogo.component';
import { CreateColaboradorComponent } from './create-colaborador/create-colaborador.component';
import { NotificacionesService } from 'app/services/notificaciones.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UsuariosComponent implements OnInit {
  usuarios: any;
  user: any;
  UserActual;
  userid;
  cargando: boolean;
  textToggle = 'Habilitado';
  userSel: any;
  notificaciones: any = [];

  constructor(public dialog: MatDialog,
    private authService: AuthService,
    private notificacionesService: NotificacionesService,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Listado de colaboradores';
    }
    this.cargando = true;
    this.user = sessionStorage.getItem('username');
    this.userid = sessionStorage.getItem('userid');
    if (this.userid !== null) {
      this.getUsuario();
    } else {
      this.getUsuarios();
    }
    this.getNotificaciones();
  }

  getNotificaciones() {
    this.notificacionesService.getNotificaciones().subscribe(
      {
        next: data => {
          console.log(data);
          this.notificaciones = data;
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });;
  }

  nuevoUsuario() {
    const dialogRef = this.dialog.open(CreateColaboradorComponent, {
      width: '50%',
      panelClass: 'dialog-no-padding',
      minHeight: '20vh',
      disableClose: true,
      data: {
        lstUsuarios: this.notificaciones
      }     
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        Swal.fire(
          'Excelente!',
          'Ya has creado un nuevo colaborador',
          'success'
        );  
      }
      this.getUsuarios();
      this.getNotificaciones();
    });
  } 


  habilitarUsuario(u, estado) {
    this.authService.setHabilitarUsuario(u._id, estado).subscribe(
      data => {
        Swal.fire(
          'Excelente!',
          'Usuario actualizado con éxito!',
          'success'
        );
        this.getUsuarios();
      },
      err => Swal.fire({
        title: 'Error!',
        text: err['message'],
        icon: 'error',
        confirmButtonText: 'Salir'
      })
    )
  }

  setUser(u) {
    this.userSel = u._id;
  }

  getUsuario() {
    this.authService.getUsuarioById(this.userid).subscribe(
      data => {
        this.UserActual = data;
        //this.auditoriaService.guardarAuditoria('', '', 'usuario', this.UserActual, sessionStorage.getItem('userid'), 'consulta').subscribe()
      },
      err => Swal.fire({
        title: 'Error!',
        text: err['message'],
        icon: 'error',
        confirmButtonText: 'Salir'
      }),
      () => this.getUsuarios()
    )
  }

  getUsuarios() {
    this.authService.getUsuarios().subscribe(
      data => {
        this.usuarios = data;
        console.log(data)
      },
      err => Swal.fire({
        title: 'Error!',
        text: err['message'],
        icon: 'error',
        confirmButtonText: 'Salir'
      }),
      () => this.cargando = false
    )
  }

  

  setRol(user) {
    let width = 'auto';
    const dialogRef = this.dialog.open(DialogoComponent, {
      width: width,
      disableClose: true,
      data: {
        tipo: 'setRol',
        permisos: user.permisos
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined) {
        this.authService.setRolUsuario(user._id, result.permisos).subscribe(
          data => {
            Swal.fire(
              'Excelente!',
              'Usuario actualizado con éxito!',
              'success'
            );
            this.getUsuarios();
            // this.auditoriaService.guardarAuditoria('', '', 'usuario', { idusuario: user._id },
            //   sessionStorage.getItem('userid'), 'actualizar').subscribe()
          },
          err => Swal.fire({
            title: 'Error!',
            text: err['message'],
            icon: 'error',
            confirmButtonText: 'Salir'
          })
        )
      }
    });

  }

  eliminarUsuario(u) {
    Swal.fire({
      title: '¿Seguro que desea eliminar el usuario seleccionado?',
      text: "No podrá revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminalo!'
    }).then((result) => {
      if (result.value) {
        this.authService.eliminarUsuario(u._id).subscribe(
          data => {
            Swal.fire(
              'Excelente!',
              'Usuario eliminado con éxito!',
              'success'
            );
            this.getUsuarios();
            //this.auditoriaService.guardarAuditoria('', '', 'usuario', { idusuario: u._id }, sessionStorage.getItem('userid'), 'baja').subscribe()
          },
          err => Swal.fire({
            title: 'Error!',
            text: err['message'],
            icon: 'error',
            confirmButtonText: 'Salir'
          })
        )
      }
    })

  }

}
