export class Auditoria {
    _id: string;
    cambioanterior: any;
    cambionuevo: any;
    tabla: string;
    tipo: string;
    campo: any;
    usuario: any;
    fechaBaja: Date;
    fecha: Date;
}