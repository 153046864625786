import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Cliente } from 'app/models/cliente.models';
import { ClientesService } from 'app/services/clientes.service';
import * as moment from 'moment';
import { DialogComprobantesComponent } from './dialog-comprobantes/dialog-comprobantes.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-recibos',
  templateUrl: './recibos.component.html',
  styleUrls: ['./recibos.component.css']
})
export class RecibosComponent implements OnInit{
  sidebar: HTMLElement;
  tipoComprobante: string;
  clienteproveedor: string;
  tipo = 'cobranza'
  talonario = '0001'
  numero = '00000001'
  form: FormGroup;
  tipoCliente = ''
  cliente: Cliente;
  fechaHoy = moment().format('YYYY-MM-DD');
  fechaMax = moment().add(5, 'days').format('YYYY-MM-DD');
  comprobantes = [];
  totalRecibo = 0;
  datasource = new MatTableDataSource<any>(); 
  displayedColumns: string[] = ['comprobante', 'cuotas', 'importe', 'saldo'];

  constructor(  
    private routerActive: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private clienteService: ClientesService,
    ){
    this.tipoComprobante = this.routerActive.snapshot.params.tipo;
    this.clienteproveedor = this.routerActive.snapshot.params.clienteproveedor;
    this.form = this.fb.group({
      razonsocial: ['', Validators.required],
      fecha: ['', Validators.required],
      moneda: ['pesos', Validators.required],
      talonario: ['', Validators.required],
      numeroTal: ['', Validators.required],
      imputarComprobante: ['no', Validators.required]
    })

    this.form.controls.talonario.valueChanges.subscribe(
      v => {
        this.talonario = v;
        if (this.sidebar !== undefined) {
          this.sidebar.innerHTML = `Recibo de ${this.tipo} N° ${this.talonario} - ${this.numero}`;
        }
      }
    )

    this.form.controls.numeroTal.valueChanges.subscribe(
      v => {
        this.numero = v;
        if (this.sidebar !== undefined) {
          this.sidebar.innerHTML = `Recibo de ${this.tipo} N° ${this.talonario} - ${this.numero}`;
        }
      }
    )
  }
  
  ngOnInit(): void {
    this.sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (this.sidebar !== undefined) {
      this.sidebar.innerHTML = `Recibo de ${this.tipo} N° ${this.talonario} - ${this.numero}`;
    }
    this.form.controls.talonario.setValue(this.talonario);
    this.form.controls.numeroTal.setValue(this.numero);
    this.getCliente();
    this.addComprobanteInterfaz();
  }

  comprobarInput(tipo,cantidad: number, product) {
    switch (tipo) {
      case 'importe':
        let total = 0
        for (let index = 0; index < this.comprobantes.length; index++) {
          const c = this.comprobantes[index];
          total += c.importe
        }
        this.totalRecibo = total + cantidad; 
        break;
    
      default:
        break;
    }
  }

  getCliente() {
    this.clienteService.getClienteById(this.clienteproveedor).subscribe(
      res => {
        this.cliente = res;
        this.tipoCliente = this.cliente?.tipo
        this.form.patchValue(this.cliente);
        this.form.controls.fecha.setValue(this.fechaHoy);
        this.form.controls.razonsocial.disable();
        this.form.controls.moneda.disable();
      },
      err => console.log(err)
    )
  }

  addComprobanteInterfaz() {
    this.comprobantes.push({
      numero: '',
      cuota: '0',
      vto: this.fechaHoy,
      saldo: 0.00,
      importe: 0.00
    })
    this.datasource.data = this.comprobantes;
  }

  verFacturas(indice) {
    const dialogRef = this.dialog.open(DialogComprobantesComponent, {
      width: '40%',
      height: 'auto',
      panelClass: 'dialog-no-padding',
      data: {
        cliente: this.cliente._id
      },
      disableClose: true,
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result !== undefined) {
        this.comprobantes[indice].numero = result.numero;
      } 
    });
  }

}
