import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PuntoVenta } from 'app/models/puntoventa.models';
import { Tienda } from 'app/models/tienda.models';
import { ArchivosService } from 'app/services/archivos.service';
import { EmpresaService } from 'app/services/empresa.service';
import { PtoventaService } from 'app/services/ptoventa.service';
import { TiendaService } from 'app/services/tienda.service';
import { UtilService } from 'app/shared/utils.service';

@Component({
  selector: 'app-tienda',
  templateUrl: './tienda.component.html',
  styleUrls: ['./tienda.component.css']
})
export class TiendaComponent {

  public myForm: FormGroup;
  puntoVentas: PuntoVenta[] = [];
  tienda: Tienda;

  constructor(
    private fb: FormBuilder,
    private empresaService: EmpresaService,
    private archivosService: ArchivosService,
    private ptoVentaService: PtoventaService,
    private _snackBar: MatSnackBar,
    private tiendaService: TiendaService,
    private utilService: UtilService
  ) {
    const sidebar = <HTMLElement>document.querySelector(".navbar-brand");
    if (sidebar !== undefined) {
      sidebar.innerHTML = "Tienda Online";
    }
    this.myForm = this.fb.group({
      cuit: '',
      disponible: [false, [Validators.required]],
      nombre: [ "",[Validators.required, this.utilService.stringMaxLength],],
      domiciliocom: "",
      puntoventa: ["vacio", Validators.required],
      dominio: "",
      status: '',
      domicilio: '',
      telefono: '',
      zonahoraria: '',
      celular: '',
      ip: '',
      email: ["",[
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          Validators.maxLength(255),
        ],
      ],
      logo: "",

    });
    this.myForm.controls["email"].setValidators(this.utilService.validarEmails);
    this.getPuntosVentas();
    this.getTienda();
  }

  getTienda() {
    this.tiendaService.getTienda().subscribe(
      (res) => {
        if (res) {
          this.tienda = res;
          this.myForm.patchValue(this.tienda);
        }
      },
      (err) => {
        console.log(err)
      }
    )
  }

  guardar() {
    const tienda = this.myForm.getRawValue();
    this.tiendaService.guardar(tienda).subscribe(
      (data) => { 
         this.openSnackBar(`Tienda actualizada con éxito`, 'Información');
      },
      (err) => {
        console.log(err);
        this.openSnackBar(`Error al guardar los datos de la tienda`, 'Información');
      }
    )
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  setDisponible(event) {

  }

  getPuntosVentas() {
    this.ptoVentaService.getAll().subscribe(
      (data) => {
        this.puntoVentas = data;
      },
      err => console.log(err)
    )
  }
}
