import { Rubro } from './../../../../models/rubro.models';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ParametrosService } from 'app/services/parametros.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-rubro',
  templateUrl: './create-rubro.component.html',
  styleUrls: ['./create-rubro.component.css']
})
export class CreateRubroComponent implements OnInit {

  rubro: Rubro;
  form: UntypedFormGroup;
  lstPuntoVentas = [];
  total = 0;

  constructor(
    private fb: UntypedFormBuilder,
    private parametrosService: ParametrosService,
    private router: Router
    ) {
    this.form = this.fb.group({
      codigo: new UntypedFormControl(''),
      nombre: new UntypedFormControl('')
    });
  }

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Nuevo rubro';
    }
    this.rubro = new Rubro();
  }


  guardar() {
    this.rubro.codigo = this.form.value.codigo;
    this.rubro.nombre = this.form.value.nombre;
    this.parametrosService.guardarRubro(this.rubro).subscribe(
      (data) => {
        Swal.fire(
          'Excelente!',
          'Ya has creado un nuevo rubro',
          'success'
        );
        this.router.navigate(['configurar/rubros']);
      },
      err => console.log(err)
    )
  }
}
