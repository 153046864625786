import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { PtoventaService } from "app/services/ptoventa.service";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { PuntoVenta } from "app/models/puntoventa.models";
import { ParametrosService } from "app/services/parametros.service";
import { Provincia } from "app/models/provincias.models";

@Component({
  selector: "app-create",
  templateUrl: "./create-pto-venta.component.html",
  styleUrls: ["./create-pto-venta.component.css"],
})
export class CreatePtoVentaComponent implements OnInit {
  puntoVenta: PuntoVenta;
  form: UntypedFormGroup;
  lstPuntoVentas = [];
  total = 0;
  provincias: Provincia[] = [];
  idPuntoVenta: string;
  textoBtnSubmit = "Guardar";

  constructor(
    private fb: UntypedFormBuilder,
    private ptoVentaService: PtoventaService,
    private parametroService: ParametrosService,
    private router: Router,
    private routerActive: ActivatedRoute
  ) {
    this.idPuntoVenta = this.routerActive.snapshot.params.id;
    this.form = this.fb.group({
      numero: ["", Validators.required],
      tipo: "general",
      descripcion: ["", Validators.required],
      nombre: "",
      electronico: false,
      calle: "",
      altura: "",
      piso: "",
      email: "",
      telefono: "",
      departamento: "",
      provincia: "",
      localidad: "",
      cpostal: "",
    });
  }

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector(".navbar-brand");
    let titulo;
    if (this.idPuntoVenta) {
      titulo = "Modificar";
      this.textoBtnSubmit = "Modificar";
      this.getPuntosVentaById();
    } else {
      titulo = "Nuevo";
      this.textoBtnSubmit = "Guardar";
      this.getPuntosVentas();
    }
    if (sidebar !== undefined) {
      sidebar.innerHTML = `${titulo} punto de venta`;
    }
    this.puntoVenta = new PuntoVenta();

    this.getProvincias();
  }

  getPuntosVentas() {
    this.ptoVentaService.getAll().subscribe(
      (data) => {
        this.lstPuntoVentas = data;
        this.total = this.lstPuntoVentas.length + 1;
        this.form.controls.numero.setValue(this.total, {
          onlySelf: true,
        });
      },
      (err) => console.log(err)
    );
  }

  getPuntosVentaById() {
    this.ptoVentaService.getById(this.idPuntoVenta).subscribe(
      (data) => {
        this.puntoVenta = data;
        this.form.patchValue(this.puntoVenta);
        this.form.controls.numero.disable();
      },
      (err) => console.log(err)
    );
  }

  getProvincias() {
    this.parametroService.getProvincias().subscribe(
      (r) => {
        this.provincias = r;
        let prov = this.provincias.find((x) => x.codigo === 19);
        this.form.controls.provincia.setValue(prov._id);
      },
      (err) => console.log(err)
    );
  }

  guardar() {
    this.puntoVenta = this.form.getRawValue();
    this.puntoVenta.empresa = this.form.value.empresa;
    if (!this.idPuntoVenta) {
      this.ptoVentaService.guardar(this.puntoVenta).subscribe(
        (data) => {
          console.log(data);
          Swal.fire(
            "Excelente!",
            "Ya has creado un nuevo punto de ventas",
            "success"
          );
          this.router.navigate(["configurar/punto-venta"]);
        },
        (err) => console.log(err)
      );
    } else {
      this.puntoVenta._id = this.idPuntoVenta;
      this.ptoVentaService.actualizar(this.puntoVenta).subscribe(
        (data) => {
          Swal.fire(
            "Excelente!",
            "El punto de ventas se actualizó correctamente",
            "success"
          );
          this.router.navigate(["configurar/punto-venta"]);
        },
        (err) => console.log(err)
      );
    }
  }
}
