import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Reportes';
    }
  }

}
