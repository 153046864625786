import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { DataService } from 'app/services/data.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-checkauth',
  templateUrl: './checkauth.component.html',
  styleUrls: ['./checkauth.component.css']
})
export class CheckauthComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dataService: DataService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    let token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      this.dataService.setToken(token);
      sessionStorage.setItem('token', token);
      setTimeout(() => {
        // buscamos los permisos del usuario
        this.authService.getPermisosUsuario().subscribe(
          {
            next: (res) => {
              this.dataService.setPermisos(res.menu);
              this.dataService.setVariablesSession(res.data);
              this.router.navigate(['/dashboard']);
            },
            error: (err) => {
              this.openSnackBar("No tiene permisos para ingresar", 'Información');
              setTimeout(
                () => {
                  // si hay error mostramos permisos incorrectos y redirigimos a auth
                  window.location.href = `${environment.urlAuth}`;
                }, 2000
              )
            }
          }
        );
      }, 3000);
    } else {
      // si hay token redirigimos a auth
      window.location.href = `${environment.urlAuth}`;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
