import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCodigo'
})
export class FormatCodigoPipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    let nro;
    if (value) {
      const regex = /^[a-zA-Z]/;
      if (!regex.test(value)) {
        switch (value.toString().length) {
          case 1:
            nro = '00000' + value
            break;
          case 2:
            nro = '0000' + value
            break;
          case 3:
            nro = '000' + value
            break;
          case 4:
            nro = '00' + value
            break;
          case 5:
            nro = '0' + value
            break;
          case 6:
            nro = value
          default:
            break;
        }
      } else {
        nro = value;
      }
    }
    return nro;
  }

}
