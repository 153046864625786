import { Moneda } from 'app/models/moneda.models';
import { Cabecera } from 'app/models/cabecera.models';
import { Detalle } from 'app/models/detalle.models';
import { Cliente } from 'app/models/cliente.models';
import { Concepto } from 'app/models/concepto.models';
import { TipoComprobante } from './tipocomprobante.models';
export class Comprobante {
  _id: string;
  cae: string;
  fechavtocae: string;
  electronico: boolean;
  enviado: boolean;
  impneto: number;
  imptrib: number;
  impiva: number;
  imptotal: number;
  imptotconc: number;
  impopext: number;
  comentario: string;
  cantreg: number;
  moncotiz: number;
  estado: string;
  codigobarras: string;
  tipocomprobante: string;
  concepto: Concepto;
  cliente: Cliente;
  usuario: string;
  moneda: Moneda;
  cabecera: Cabecera;
  detalle: Detalle[];
  compasociado: [];
  tributos: [];
  opcionales: [];
  iva: [];
  tipo: string;
}
