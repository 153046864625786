import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from './data.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class InterceptorHeadersInterceptor implements HttpInterceptor {

  constructor(
    private router: Router
    ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = `Beared ${sessionStorage.getItem('token')}`;
    let headers = new HttpHeaders(
      {
        'authorization': token
      }
    );
    if (!request.url.includes('login') && !request.url.includes('sigUp')) {
      const reqClone = request.clone({ headers });
      return next.handle(reqClone).pipe(
        catchError(this.manejarError)
      );
    } else {
      return next.handle(request).pipe(
        catchError(this.manejarError)
      );
    }

  }
  private manejarError(err: HttpErrorResponse) {
    if (err.status === 403) {
      //this.router.navigate(['/']);
    }
    return throwError(err.error);
  }
}
