import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Rubro } from "app/models/rubro.models";
import { ParametrosService } from "app/services/parametros.service";
import { DialogGenericoComponent } from "app/shared/dialog/dialog.component";

@Component({
  selector: "app-rubros",
  templateUrl: "./rubros.component.html",
  styleUrls: ["./rubros.component.css"],
})
export class RubrosComponent implements OnInit {
  rubros = [];

  constructor(
    private parametrosService: ParametrosService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector(".navbar-brand");
    if (sidebar !== undefined) {
      sidebar.innerHTML = "Rubros";
    }
    this.getRubros();
  }

  getRubros() {
    this.parametrosService.getRubros().subscribe(
      (data) => {
        this.rubros = data;
        console.log({ data });
      },
      (err) => console.log(err)
    );
  }

  editarRubro(rubro: Rubro) {
    const { _id } = rubro;
    const dialogRef = this.dialog.open(DialogGenericoComponent, {
      disableClose: true,
      autoFocus: true,
      data: {
        nombre: rubro.nombre,
        title: "Rubro",
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (!data) return;

      rubro.nombre = data;
      let response = this.parametrosService
        .modificarRubro(rubro, _id)
        .subscribe(
          (data) => {
            this.getRubros();
          },
          (error) => {
            console.log({ error });
          }
        );
    });
  }

  borrarRubro(rubro: Rubro) {
    const { _id } = rubro;
    this.parametrosService.borrarRubro(_id).subscribe(
      (data) => {
        this.getRubros();
      },
      (error) => {
        console.log({ error });
      }
    );
  }
}
