import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Producto } from 'app/models/producto.models';
import { ReportesService } from 'app/services/reportes.service';

@Component({
  selector: 'app-reporte-stock',
  templateUrl: './reporte-stock.component.html',
  styleUrls: ['./reporte-stock.component.css']
})
export class ReporteStockComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['codigo','codigobarras', 'nombre', 'tipo','stockminimo','stock'];
  dataSource: MatTableDataSource<Producto>;

  productos: Producto[] = [];
  loading: boolean;

  constructor(private reporteService:ReportesService) { }

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'reportes / productos sin stock';
    }
    this.dataSource = new MatTableDataSource();
    this.getProductosSinStock();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getProductosSinStock() {
    this.loading = true;
    this.reporteService.getProductosSinStock().subscribe(
      res => {
        this.loading = false;
        this.productos = res;
        this.dataSource = new MatTableDataSource(this.productos);
        this.dataSource.paginator = this.paginator;
      },
      err => console.log(err)
    )
  }

}
