import { ViewEncapsulation } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ArticuloProveedor } from 'app/models/articuloproveedor.models';
import { Cliente } from 'app/models/cliente.models';
import { Marca } from 'app/models/marca.models';
import { Rubro } from 'app/models/rubro.models';
import { ClientesService } from 'app/services/clientes.service';
import { ParametrosService } from 'app/services/parametros.service';
import { ProductoService } from 'app/services/producto.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-precios-masivos',
  templateUrl: './precios-masivos.component.html',
  styleUrls: ['./precios-masivos.component.css']
})
export class PreciosMasivosComponent implements OnInit{

  form: FormGroup;
  verFiltros: boolean;
  marcas: Marca[] = [];
  rubros: Rubro[] = [];
  proveedores: Cliente[] = [];
  marcasSelected = [];
  rubrosSelected = [];
  proveedoreSelected = [];
  
  constructor(
    private _fb: FormBuilder,
    private parametroServicio: ParametrosService,
    private clienteService: ClientesService,
    private articuloService: ProductoService,
    private _snackBar: MatSnackBar,
  ) {
    this.form = this._fb.group({
      servicios: [true],
      todos: [true],
      iva: [true],
      impuestos: [true],
      proveedor:[null],
      rubro:[null],
      marca:[null],
      porcentaje: [0, [Validators.required, Validators.min(1)]]
    })

    this.form.controls['todos'].valueChanges.subscribe(
      d => {
        this.verFiltros = !d ? true: false;
      }
    )
    this.form.controls['rubro'].valueChanges.subscribe(
      rubros => {
        this.rubrosSelected = Array.from(rubros);
      }
    )
    this.form.controls['marca'].valueChanges.subscribe(
      marcas => {
        this.marcasSelected = Array.from(marcas);
      }
    )
    this.form.controls['proveedor'].valueChanges.subscribe(
      proveedor => {
        this.proveedoreSelected = Array.from(proveedor);
      }
    )
  }

  ngOnInit() {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Actualización Masiva de Precios';
    }
    this.getParametros();
    this.getProveedores();
  }

  getParametros() {
    this.parametroServicio.getParametrosArticulos().subscribe(
      (data) => {
        this.marcas = data.marcas;
        this.rubros = data.rubros; 
      },
      (err) => console.log(err)
    )
  }

  getProveedores() {
    this.clienteService.getClientesByTipo('proveedor').subscribe(
      (res) => {
        this.proveedores = res;
      },
      err => {
        this.openSnackBar(`Ocurrió un error al obtener listado proveedores`, 'Información');
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  actualizar() { 
    // si está el filtro de todos los productos seleccionado seteamos en vacios los arrays
    if (this.form.controls['todos'].value) {
       this.rubrosSelected = [];
       this.marcasSelected = [];
       this.proveedoreSelected = [];
    }
    let filtros = {
      servicio: this.form.controls['servicios'].value,
      iva: this.form.controls['iva'].value,
      impuesto: this.form.controls['impuestos'].value,
      porcentaje: this.form.controls['porcentaje'].value,
      proveedores: this.proveedoreSelected,
      rubros: this.rubrosSelected,
      marcas: this.marcasSelected
    }
    this.articuloService.actualizarPreciosMasivos(filtros).subscribe(
      res => {
        Swal.fire({
          title: "Excelente!",
          text: "Precios actualizados correctamente!",
          icon: "success"
        });
      },
      (err) => {
        Swal.fire({
          title: "Error",
          text: "Ocurrió un error al actualizar los precios!",
          icon: "warning",
          confirmButtonColor: "#3085d6",
        })
      }
    )
  }

  
}
