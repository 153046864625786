import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-medios-pago',
  templateUrl: './dialog-medios-pago.component.html',
  styleUrls: ['./dialog-medios-pago.component.css']
})
export class DialogMediosPagoComponent {

}
