import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Producto } from "app/models/producto.models";
import { Variante } from "app/models/variante.models";

@Injectable({
  providedIn: "root",
})
export class ProductoService {
  articulos: Observable<Producto[] | any>;
  articulo: Observable<Producto | any>;

  constructor(private http: HttpClient) {}

  getArticulos(
    pagina: number,
    itemXPag: number,
    search?: string
  ): Observable<Producto | any> {
    return this.http.get(
      environment.apiUrl +
        `articulos?pag=${pagina}&itemXPag=${itemXPag}&search=${search}`
    );
  }

  getNuevoCodigoArticulos(): Observable<Producto | any> {
    return this.http.get(environment.apiUrl + "articulo/codigo/nuevo");
  }

  getNuevoCodigoBarrasArticulos(): Observable<Producto | any> {
    return this.http.get(environment.apiUrl + "articulo/codigobarras/nuevo");
  }

  getCantidadArticulosActivos(): Observable<any> {
    return this.http.get(environment.apiUrl + "articulos/cantidad");
  }

  getMediosDePago(): Observable<any> {
    return (this.articulos = this.http.get(environment.apiUrl + "mediopagos"));
  }

  getMedioDePagoByNombre(nombre: string): Observable<any> {
    return (this.articulos = this.http.get(
      environment.apiUrl + "mediopago/nombre/" + nombre
    ));
  }

  getDepositos(): Observable<any> {
    return this.http.get(environment.apiUrl + "depositos");
  }

  getArticulo(id: string): Observable<Producto | any> {
    return this.http.get(environment.apiUrl + "articulo/" + id);
  }

  getProveedorByArticulo(id: string): Observable<any> {
    return this.http.get(
      environment.apiUrl + "articuloproveedor/articulo/" + id
    );
  }

  getStocks(): Observable<Producto | any> {
    return this.http.get(environment.apiUrl + "stocks");
  }

  getArticuloByNombre(nombre: string): Observable<Producto | any> {
    return this.http.get(
      environment.apiUrl + "stocks/articulo/nombre/" + nombre
    );
  }

  getArticuloByDroga(nombre: string): Observable<Producto | any> {
    return this.http.get(
      environment.apiUrl + "stocks/articulo/droga/" + nombre
    );
  }

  getArticuloByCodigoBarras(codigo: string): Observable<any> {
    return this.http.get(
      environment.apiUrl + "stock/articulo/codigobarras/" + codigo
    );
  }

  getStockById(id): Observable<any> {
    return this.http.get(environment.apiUrl + "stock/" + id);
  }

  getStockByArticulo(id): Observable<any> {
    return this.http.get(environment.apiUrl + "stock/articulo/" + id);
  }

  actualizarStock(stock: any, id): Observable<string | any> {
    return this.http.put(environment.apiUrl + "stock/" + id, stock);
  }

  actualizarPreciosByArticulo(articulo: any): Observable<string | any> {
    return this.http.put(
      environment.apiUrl + "articulo/" + articulo._id,
      articulo
    );
  }

  actualizarPreciosMasivos(filtros): Observable<any> {
    return this.http.put(
      environment.apiUrl + "articulo/masivo/precios",
      filtros
    );
  }

  actualizarCantidadStock(cantidad: any, id): Observable<string | any> {
    return this.http.put(environment.apiUrl + "stock/cantidadarticulo/" + id, {
      cantidad: cantidad,
    });
  }

  getArticuloPorCodigo(codigo: string): Observable<Producto | any> {
    return (this.articulo = this.http.get(
      environment.apiUrl + "articulo/codigo/" + codigo
    ));
  }

  getArticuloPorNombre(nombre: string): Observable<Producto | any> {
    return (this.articulo = this.http.get(
      environment.apiUrl + "articulo/nombre/" + nombre
    ));
  }

  guardarArticulo(articulo: Producto): Observable<any> {
    console.log({ articulo });
    return this.http.post(environment.apiUrl + "articulo", articulo);
  }

  modificarArticulo(articulo: Producto): Observable<any> {
    return this.http.put(
      environment.apiUrl + "articulo/" + articulo._id,
      articulo
    );
  }

  eliminarArticulo(id: string): Observable<string | any> {
    return this.http.delete(environment.apiUrl + "articulo/" + id);
  }

  eliminarStock(id: string): Observable<string | any> {
    return this.http.delete(environment.apiUrl + "stock/" + id);
  }

  // VARIANTES

  getVariantesPorArticulo(idArticulo: string): Observable<any> {
    return this.http.get(environment.apiUrl + "variantes/articulo/" + idArticulo);
  }

  guardarVariante(dataVariante: Variante) : Observable<any> {
    return this.http.post(environment.apiUrl + "variantes", dataVariante);
  }

  deleteVariante(idVariante: string) {
    return this.http.delete(environment.apiUrl + "variante/" + idVariante);
  }


  // TIPO DE VARIANTES
  getTipoVariantes(): Observable<any> {
    return this.http.get(environment.apiUrl + "tipovariantes");
  }

}
