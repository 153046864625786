import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CajaService } from 'app/services/caja.service';
import { ParametrosService } from 'app/services/parametros.service';

@Component({
  selector: 'app-editar-cantidades',
  templateUrl: './editar-cantidades.component.html',
  styleUrls: ['./editar-cantidades.component.css']
})
export class EditarCantidadesComponent implements OnInit {

  verMensaje: boolean;
  form: UntypedFormGroup;
  total: string;
  articulo;
  subtotal: string;
  porcentajeBonificacion;
  indice;
  unArticulo: any;

  constructor(
    public dialogRef: MatDialogRef<EditarCantidadesComponent>,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private cajaService: CajaService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.articulo = data.articulo;
      this.porcentajeBonificacion = data.bonificacion;
      this.indice = data.indice;
      this.unArticulo = data.unArticulo;
      this.form = this.fb.group({
        codigo: '',
        descripcion: '',
        cantidad: [0, Validators.required],
        preciounitario: [0, Validators.required],
        subtotal: '0'
      });
      this.form.patchValue(this.articulo);
      this.total =  Number.parseFloat(this.data.total).toFixed(2);
      this.form.controls.codigo.disable();
      this.form.controls.descripcion.disable();
      this.form.controls.subtotal.disable();
     }


  ngOnInit(): void {
  }

  calcularPreciosPressKey(e){
    let c = 0;
    if (e.key === 'Backspace') {
      if (this.form.controls.cantidad.value !== '') {
        c = Number.parseInt(this.form.controls.cantidad.value); 
        this.subtotal = (this.form.controls.preciounitario.value * c).toFixed(2);
        this.form.controls.subtotal.setValue(this.subtotal);
      } else {
        this.subtotal = '0.00';
        this.form.controls.subtotal.setValue(this.subtotal);
      }
    } else {
      if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        if (this.form.controls.cantidad.value !== '') {
          c = Number.parseInt(this.form.controls.cantidad.value);
          this.subtotal = (this.form.controls.preciounitario.value * c).toFixed(2);
          this.form.controls.subtotal.setValue(this.subtotal);
        } else {
          this.subtotal = '0.00';
          this.form.controls.subtotal.setValue(this.subtotal);
        }
      }
    }

  }

  guardar() {
    this.cajaService.articulosList[this.indice].cantidad = this.form.controls.cantidad.value;
    let subt = 0;
    const cantidadXPrecio =  (this.unArticulo.precioventa * this.cajaService.articulosList[this.indice].cantidad);
    const bonif = (this.porcentajeBonificacion * cantidadXPrecio) / 100;
    subt = (cantidadXPrecio - bonif);
    this.cajaService.articulosList[this.indice].subtotalciva = subt;
    this.cajaService.articulosList[this.indice].subtotal = subt;
    
    this.dialogRef.close();
  }

  cancelar() {
    this.dialogRef.close();
  }

}
