export class Cabecera {
  numero: number;
  puntoventa: number;
  fecha: string;
  fechadesde: string;
  fechahasta: string;
  fechavencimiento: string;
  inicioactividad: string;
  condicionventa: string;
  cuitemisor: string;
  domicilio: string;
  razonsocial: string;
  cuitreceptor: string;
  ingresosbrutos: string;
}
