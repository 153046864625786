import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tienda } from 'app/models/tienda.models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TiendaService {

  
  constructor(private http: HttpClient) { }

  getTienda(): Observable<Tienda | any> {
    return this.http.get(environment.apiUrl + 'tienda');
  }

  guardar(tienda: Tienda): Observable<any> {
    return this.http.post(environment.apiUrl + 'tienda', tienda);
  }

  actualizar(tienda: Tienda): Observable<any> {
    return this.http.put(`${environment.apiUrl}tienda/${tienda._id}`, tienda);
  }

  eliminar(tienda: Tienda): Observable<any> {
    return this.http.delete(`${environment.apiUrl}tienda/${tienda._id}`);
  }
}
