import { Component, OnInit } from '@angular/core';
import { ParametrosService } from 'app/services/parametros.service';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {


  constructor() {}

  ngOnInit() {
    const sidebar = <HTMLElement>document.querySelector('.sidebar');
    if (sidebar !== undefined){
      sidebar.setAttribute('data-color', 'black');
    }
   }
}
