import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from "@angular/core"; 
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { AuthService } from "app/services/auth.service";
import { DataService } from "app/services/data.service";
import { TiendaService } from "app/services/tienda.service";

@Component({
  moduleId: module.id,
  selector: "navbar-cmp",
  templateUrl: "navbar.component.html",
  styles: [".username { padding: 5px 0px 5px 15px; font-weight: 500; }"],
})
export class NavbarComponent implements OnInit {

  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  verTienda: boolean;
  urlTienda = '';

  public isCollapsed = true;
  @ViewChild("navbar-cmp", { static: false }) button;

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private autService: AuthService,
    public dataService: DataService,
    private tiendaService: TiendaService

  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  cerrarSesion() {
    this.autService.cerrarSesion();
  }

  ngOnInit() {
     
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggle")[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
    this.getTienda();
  }
  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }
    let listTitles
    for (var item = 0; item < this.dataService.ROUTES.values.length; item++) {
      if (listTitles[item].path === titlee) {
        return listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  getTienda() {
    this.tiendaService.getTienda().subscribe(
      (res) => {
        if (res) {
          this.verTienda = true;
          this.urlTienda = `https://${res.dominio}`;
        }
      },
      (err) => {
        console.log(err)
      }
    )
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName("html")[0];
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);

    html.classList.add("nav-open");
    if (window.innerWidth < 991) {
      mainPanel.style.position = "fixed";
    }
    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = "";
      }, 500);
    }
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }
  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName("nav")[0];
    console.log(navbar);
    if (!this.isCollapsed) {
      navbar.classList.remove("navbar-transparent");
      navbar.classList.add("bg-white");
    } else {
      navbar.classList.add("navbar-transparent");
      navbar.classList.remove("bg-white");
    }
  }

  irTienda() {
      window.open(this.urlTienda, "_blank")
  }

}
