import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

export interface FileHandle {
  file: File;
  url: SafeUrl;
}
@Directive({
  selector: "[fileDragDrop]",
})
export class FileDragAndDropDirective {
  @Output() private filesChangeEmiter: EventEmitter<FileHandle[]> =
    new EventEmitter();
  @HostBinding("style.background-color") private bg_color;
  @HostBinding("style.border-color") private border_color;
  @HostBinding("style.border-style") private border_style;
  @HostBinding("style.border-width") private border_width;

  constructor(private sanitizer: DomSanitizer) {}

  @HostListener("dragover", ["$event"]) public onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.bg_color = "#ccc";
    this.border_color = "green";
    this.border_width = "3px";
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.bg_color = "white";
    this.border_color = "#0000004d";
  }

  @HostListener("drop", ["$event"]) public onDrop(e) {
    e.preventDefault();
    e.stopPropagation();

    this.bg_color = "white";
    this.border_color = "#0000004d";
    this.border_style = "dashed";
    this.border_width = "2px";

    let files: FileHandle[] = [];

    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      const file = e.dataTransfer.files[i];
      const url = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file)
      );
      files.push({ file, url });
    }
    if (files.length > 0) {
      this.filesChangeEmiter.emit(files);
    }
  }
  @HostListener("change", ["$event"]) public onChangeCustom(e) {
    e.preventDefault();
    e.stopPropagation();

    let files: FileHandle[] = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const url = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file)
      );
      files.push({ file, url });
    }
    if (files.length > 0) {
      this.filesChangeEmiter.emit(files);
    }
  }
}
