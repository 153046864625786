import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SidebarComponent } from "./sidebar.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  imports: [RouterModule, CommonModule, MatTooltipModule],
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
})
export class SidebarModule {}
