export class OperacionCaja {
    _id: string;
    estado?: string;
    montorecibido: number;
    montocambio: number;
    montocobrar: number;
    nrotransaccion: number;
    usuario: string;
    cliente: string;
    tipoCliente: string;
    mediopago: any;
    caja: string;
    fecha: Date;
    tipo?: string;
    detalle: any;
    socio: string;
    observaciones: string;
    montopago: number;
    montoingresado: number;
}