import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-dialog-comprobantes',
  templateUrl: './dialog-comprobantes.component.html',
  styleUrls: ['./dialog-comprobantes.component.css']
})
export class DialogComprobantesComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['comprobante', 'cuotas', 'importe', 'saldo'];
  idCliente;

  comprobantes = [
    {
      numero: '0001-0000001',
      cuota: '1',
      saldo: 1000,
      importe: 14000
    }
  ];

  constructor( 
    public dialogRef: MatDialogRef<DialogComprobantesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
    this.idCliente = data.cliente;
    this.getComprobantesACuentaByClientes();
    this.dataSource = new MatTableDataSource(this.comprobantes);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getComprobantesACuentaByClientes() {
    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  selectComprobante(indice) {
    this.dialogRef.close(this.comprobantes[indice]);
  }

  salir() {
    this.dialogRef.close();
  }
}
