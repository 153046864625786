import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Talonario } from "app/models/talonario.models";
import { ParametrosService } from "app/services/parametros.service";

@Component({
  selector: "app-talonarios",
  templateUrl: "./create-talonario.component.html",
  styleUrls: ["./create-talonario.component.css"],
})
export class CreateTalonarioComponent implements OnInit {
  form: UntypedFormGroup;
  textoBtnSubmit = "Guardar";
  puntoVentas = [];
  idTalonario: string;
  talonario: Talonario;

  constructor(
    private parametrosService: ParametrosService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private routerActive: ActivatedRoute
  ) {
    this.idTalonario = this.routerActive.snapshot.params.id;
    this.form = this.fb.group({
      numero: ["", Validators.required],
      letra: "",
      tipocomprobante: "",
      descripcion: ["", Validators.required],
      puntoventa: "",
      tipotalonario: "manual",
      observaciones: "",
    });
  }

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector(".navbar-brand");
    let titulo;
    if (this.idTalonario) {
      titulo = "Modificar";
      this.textoBtnSubmit = "Modificar";
      this.getTalonarioById();
    } else {
      titulo = "Nuevo";
      this.textoBtnSubmit = "Guardar";
      this.getPuntoDeVenta();
    }
    if (sidebar !== undefined) {
      sidebar.innerHTML = `${titulo} talonario`;
    }
    //this.talonario = new Talonario();

    this.getPuntoDeVenta();
  }

  getPuntoDeVenta() {
    this.parametrosService.getPuntosDeVenta().subscribe(
      (data) => {
        this.puntoVentas = data;
      },
      (err) => console.log(err)
    );
  }

  getTalonarioById() {
    this.parametrosService.getTalonarioById(this.idTalonario).subscribe(
      (data) => {
        this.talonario = data;
        this.form.patchValue(data.talonario);
        this.form.controls.numero.disable();
      },
      (err) => console.log(err)
    );
  }

  guardar() {}
}
