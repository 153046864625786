import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Producto } from 'app/models/producto.models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

  constructor(private http: HttpClient) { }

  getProductosSinStock(): Observable<Producto | any> {
    return this.http.get(environment.apiUrl + 'reportes/stock/sinstock');
  }
}
