import { Venta } from './../models/venta.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VentasService {

  constructor(private http: HttpClient) { }

  getVentas(): Observable<Venta | any> {
    return this.http.get(environment.apiUrl + 'ventas');
  }

  eliminarVenta(id: string): Observable<string | any> {
    return this.http.delete(environment.apiUrl + 'comprobante/' + id);
  }

}
