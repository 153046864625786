import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ParametrosService } from "app/services/parametros.service";

import { DialogGenericoComponent } from "../../../shared/dialog/dialog.component";
import { Marca } from "app/models/marca.models";

@Component({
  selector: "app-marcas",
  templateUrl: "./marcas.component.html",
  styleUrls: ["./marcas.component.css"],
})
export class MarcasComponent implements OnInit {
  marcas = [];

  constructor(
    private parametrosService: ParametrosService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector(".navbar-brand");
    if (sidebar !== undefined) {
      sidebar.innerHTML = "Marcas";
    }
    this.getMarcas();
  }

  getMarcas() {
    this.parametrosService.getMarcas().subscribe(
      (data) => {
        this.marcas = data;
      },
      (err) => console.log(err)
    );
  }

  editarMarca(marca: Marca) {
    const { _id } = marca;
    const dialogRef = this.dialog.open(DialogGenericoComponent, {
      disableClose: true,
      autoFocus: true,
      data: {
        nombre: marca.nombre,
        title: "Marca",
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (!data) return;

      marca.nombre = data;
      let response = this.parametrosService
        .modificarMarca(marca, _id)
        .subscribe(
          (data) => {
            this.getMarcas();
          },
          (error) => {
            console.log({ error });
          }
        );
    });
  }

  borrarMarca(marca: Marca) {
    const { _id } = marca;
    this.parametrosService.borrarMarca(_id).subscribe(
      (data) => {
        this.getMarcas();
      },
      (error) => {
        console.log({ error });
      }
    );
  }
}
