import { environment } from '../../environments/environment';


import { Injectable } from '@angular/core';
import { TipoComprobante } from '../models/tipocomprobante.models';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TcomprobanteService {


  tipocomprobantes: Observable<TipoComprobante[] | any>;

  constructor(private http: HttpClient) { }

  getObtenerTiposComprobantes(): Observable<TipoComprobante | any> {
    return this.http.get(environment.apiUrl + 'tipocomprobantes');
  }


  getTipoComprobanteByCodigo(codigo): Observable<TipoComprobante | any>  {
    return this.http.get(environment.apiUrl + 'tipocomprobante/codigo/' + codigo);
  }



}
