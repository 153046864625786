import { Variante } from "./variante.models";

export class Producto {
  _id: string;
  codigo?: string;
  codigobarras?: string;
  descripcion?: string;
  nombre: string;
  tipo: string;
  unidadmedida: any;
  unidadmedidasecundaria: any;
  deposito: any;
  stock: any;
  stockminimo: number;
  stockmaximo: number;
  impuestos: any;
  preciosinivaventa: number;
  preciosinivacompra: number;
  preciocompra: number;
  precioventa: number;
  stockactual: number;
  droga?: string;
  lote: string;
  vencimiento: string;
  tipoiva: any;
  posicioniva: any;
  moneda: any;
  listaprecio: any;
  rubro: any;
  marca: any;
  sku: string;
  variante: boolean;
  variantes: Variante[];
  public: boolean;
  imagenes: Imagenes[];
}

interface Imagenes {
  //_id: string;
  nombre: string;
  tamaño: number;
  url: string;
}
