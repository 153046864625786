import { Component, OnInit } from '@angular/core';
import { DataService } from './services/data.service';
import { DeviceDetectorService } from './services/deviceDetector.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{

  constructor(
    private deviceDetector: DeviceDetectorService,
    public dataService: DataService,
    private swUpdate: SwUpdate
  ) { 
    if (this.swUpdate.isEnabled) {
      swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        if(confirm("Hay una nueva versión disponible. ¿Desea actualizar?")) {
          window.location.reload();
      }
      });
  }        
  }

  ngOnInit(): void {
    this.dataService.getPermisos(); 
    this.deviceDetector.detectarCambiosPantalla();
  }

}
