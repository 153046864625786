import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Empresa } from "app/models/empresa.models";

@Injectable({
  providedIn: "root",
})
export class EmpresaService {
  empresa: Observable<Empresa | any>;
  headers: HttpHeaders;

  constructor(private http: HttpClient) {}

  getEmpresa(cuit: string): Observable<Empresa | any> {
    return this.http.get(environment.apiUrl + "empresa/cuit/" + cuit);
  }

  guardarEmpresa(empresa: Empresa): Observable<any> {
    return this.http.post(environment.apiUrl + "empresa", empresa);
  }

  getDashboard(): Observable<any> {
    return this.http.get(environment.apiUrl + "dashboard");
  }

  getEmpresaConfig(): Observable<Empresa> {
    return this.http.get<Empresa>(`${environment.apiUrl}empresas`);
  }

  actualizarDataEmpresa(empresa: Empresa): Observable<Empresa> {
    return this.http.put<Empresa>(
      `${environment.apiUrl}empresa/${empresa._id}`,
      empresa
    );
  }
}
