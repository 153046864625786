import { Rubro } from "./../models/rubro.models";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CondicionIva } from "app/models/condicionfrenteiva.models";
import { Marca } from "app/models/marca.models";
import { Moneda } from "app/models/moneda.models";
import { Tributo } from "app/models/tributo.models";
import { environment } from "environments/environment";
import { Observable, Subscription } from "rxjs";
import { Provincia } from "app/models/provincias.models";
import { Talonario } from "app/models/talonario.models";
import { PuntoVenta } from "app/models/puntoventa.models";

@Injectable({
  providedIn: "root",
})
export class ParametrosService {
  condicionivas: Observable<CondicionIva[] | any>;
  p: Subscription;

  constructor(private http: HttpClient) {}

  getParametrosArticulos(): Observable<any> {
    return this.http.get(environment.apiUrl + "articulos/parametros");
  }

  getParametrosClientes(): Observable<any> {
    return this.http.get(environment.apiUrl + "clientes/parametros");
  }

  getParametrosComprobantes(): Observable<any> {
    return this.http.get(environment.apiUrl + "comprobantes/parametros");
  }

  getCondicionIva() {
    this.p = this.http.get(environment.apiUrl + "condicioniva").subscribe();
    return this.p;
  }

  getMonedas(): Observable<Moneda[] | any> {
    return this.http.get(environment.apiUrl + "monedas");
  }

  getMarcas(): Observable<Marca[] | any> {
    return this.http.get(environment.apiUrl + "marcas");
  }

  getRubros(): Observable<Rubro[] | any> {
    return this.http.get(environment.apiUrl + "rubros");
  }

  getTalonarios(): Observable<Talonario[] | any> {
    return this.http.get(environment.apiUrl + "talonarios");
  }

  getProvincias(): Observable<Provincia[] | any> {
    return this.http.get(environment.apiUrl + "provincias");
  }

  getPuntosDeVenta(): Observable<PuntoVenta[] | any> {
    return this.http.get(environment.apiUrl + "puntoventas");
  }

  guardarTalonario(talonario: Talonario): Observable<Marca | any> {
    return this.http.post(environment.apiUrl + "talonarios", talonario);
  }

  guardarMarca(marca: Marca): Observable<Marca | any> {
    return this.http.post(environment.apiUrl + "marcas", marca);
  }

  guardarRubro(rubro: Rubro): Observable<Marca | any> {
    return this.http.post(environment.apiUrl + "rubros", rubro);
  }

  getBancos(): Observable<Moneda[] | any> {
    return this.http.get(environment.apiUrl + "bancos");
  }

  getTributos(): Observable<Tributo | any> {
    return this.http.get(environment.apiUrl + "tributos");
  }

  getConceptos(): Observable<any> {
    return this.http.get(environment.apiUrl + "conceptos");
  }

  getTipoComprobantes(): Observable<any> {
    return this.http.get(environment.apiUrl + "tipocomprobantes");
  }

  getMediosPagos(): Observable<any> {
    return this.http.get(environment.apiUrl + "conceptos");
  }

  getMediosPagosByNombre(nombre: string): Observable<any> {
    return this.http.get(environment.apiUrl + "mediopago/nombre/" + nombre);
  }

  modificarMarca(marca: Marca, id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}marca/${id}`, marca);
  }

  borrarMarca(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}marca/${id}`);
  }

  modificarRubro(rubro: Rubro, id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}rubro/${id}`, rubro);
  }

  borrarRubro(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}rubro/${id}`);
  }

  modificarTalonario(talonario: Talonario, id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}talonario/${id}`, talonario);
  }

  borrarTalonario(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}talonario/${id}`);
  }

  getTalonarioById(id: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}talonario/${id}`);
  }
}
