import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ArchivosService } from 'app/services/archivos.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {
  @ViewChild('fileInput') inputEl: ElementRef;

  urlFIle: string = '';
  nombreArchivo: string = '';
  nombre: string = '';
  procesando = false; 
  subiendo = false;
  erroresFile: string[] = [];
  mensaje = '';
  tipo;

  constructor(
    private archivoServicio: ArchivosService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UploadFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.mensaje = this.data.mensaje;
    this.tipo = this.data.tipo;
   }

  ngOnInit(): void {
  }

  salir(): void {
    this.dialogRef.close();
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    this.nombreArchivo = file.name;
    if (file) {
      this.procesando = true;
      this.archivoServicio.importarArchivoExcel(file).subscribe(
        (res) => {
          this.urlFIle = res['data']['url'];
          this.nombre = res['data']['nombre'];
          this.procesando = false;
        },
        (err) => {
          this.openSnackBar(`Error al validar el archivo`, 'Información');
          this.procesando = false;
        }
      )
    }
  }

  procesarArchivo() {
    // procedemos a guardar el archivo y esperar que termine de procesarlo
    this.subiendo = true;
    if (this.tipo === 'articulos') {
      this.archivoServicio.procesarArchivoExcel(this.nombre).subscribe(
        (res) => {
          this.openSnackBar(`Excelente! Archivo procesado correctamente`, 'Información');
          this.subiendo = false;
          this.salir();
        },
        (err) => {
          this.subiendo = false;
          if (err.error) {
            this.openSnackBar(err.message, 'Error');
          } else {
            this.openSnackBar(`Error procesar el archivo`, 'Error');
          }
          
        }
      )
    }
    if (this.tipo === 'clientes') {
      this.archivoServicio.procesarArchivoExcelClientes(this.nombre).subscribe(
        (res) => {
          this.openSnackBar(`Excelente! Archivo procesado correctamente`, 'Información');
          this.subiendo = false;
          this.salir();
        },
        (err) => {
          this.subiendo = false;
          this.openSnackBar(`Error procesar el archivo`, 'Error');
        }
      )
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
