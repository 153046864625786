import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Caja } from 'app/models/caja.models';
import { GeneralesService } from 'app/services/generales.service';

@Component({
  selector: 'app-cierre',
  templateUrl: './cierre.component.html',
  styleUrls: ['./cierre.component.css']
})
export class CierreComponent implements OnInit {

  montoCtrl: UntypedFormControl = new UntypedFormControl('');
  totalEfectivo = 0;
  totalEfectivoPago = 0;
  totalDebito = 0;
  totalDebitoPago = 0;
  totalCredito = 0;
  totalCreditoPago = 0;
  totalCheque = 0;
  totalChequePago = 0;
  totalTransferencia = 0;
  totalTransferenciaPago = 0;
  saldoCajaTotal = 0;
  totalCompra = 0;
  sumaTotalTablaFPago = 0;
  montoTotal = 0;
  montoApertura = 0;
  totalIngreso = 0;
  fechaApertura: string;
  montoCierre = 0;
  cajaActual: Caja;

  constructor(public dialogRef: MatDialogRef<CierreComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private servicioGrales: GeneralesService,) {
    this.cajaActual = data.cajaActual;
  }

  ngOnInit(): void {
    this.montoApertura = this.data.montoApertura;
    this.montoCierre = this.data.montoCierre;
    this.totalCompra = this.data.totalCompra;
    this.montoTotal = this.data.montoTotal;
    this.fechaApertura = this.data.fechaApertura;
    this.totalEfectivo = this.data.efectivo;
    this.totalEfectivoPago = this.data.efectivoPago;
    this.totalDebito = this.data.debito;
    this.totalDebitoPago = this.data.debitoPago;
    this.totalCredito = this.data.credito;
    this.totalCreditoPago = this.data.creditoPago;
    this.totalCheque = this.data.cheque;
    this.totalIngreso = this.data.totalIngreso;
    this.totalChequePago = this.data.chequePago;
    this.totalTransferencia = this.data.transferencia;
    this.totalTransferenciaPago = this.data.transferenciaPago;
    this.saldoCajaTotal = this.data.saldoCajaTotal;
  }


  guardarCaja() {
    this.cajaActual.totalCompra = this.totalCompra;
    this.cajaActual.efectCobro = this.totalEfectivo;
    this.cajaActual.efectPago = this.totalEfectivoPago;
    this.cajaActual.debitoCobro = this.totalDebito;
    this.cajaActual.debitoPago = this.totalDebitoPago;
    this.cajaActual.creditoCobro = this.totalCredito;
    this.cajaActual.creditoPago = this.totalCreditoPago;
    this.cajaActual.chequeCobro = this.totalCheque;
    this.cajaActual.chequePago = this.totalChequePago;
    this.cajaActual.transfCobro = this.totalTransferencia;
    this.cajaActual.transfPago = this.totalTransferenciaPago;
    this.cajaActual.efectivoCaja = this.saldoCajaTotal;
    this.servicioGrales.cerrarCaja(this.cajaActual).subscribe(
      data => {
        if (data['message'] === 'Caja cerrada correctamente') {
          this.dialogRef.close('Caja cerrada correctamente');
        }
      },
      err => {
        console.log(err)
      }
    )
  }

  cancelar() {
    this.dialogRef.close();
  }

  imprimir() {
    this.dialogRef.close();
  }

}
