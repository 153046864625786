export class Caja {
  _id: string;
  idpuntoventa: string;
  numero?: string;
  apertura: Date;
  cierre: Date;
  abierto: boolean;
  montoinicio: number;
  efectivoCaja: number;
  totalCobro: number;
  totalPago: number;
  efectPago: number;
  efectCobro: number;
  debitoPago: number;
  debitoCobro: number;
  creditoPago: number;
  creditoCobro: number;
  chequePago: number;
  chequeCobro: number;
  transfPago: number;
  transfCobro: number;
  cerrado: boolean;
  puntoventa?: any;
  fecha: Date;
  totalCompra: number;
  idUsuario: string;
  cajero: string;
}
