import { Injectable } from "@angular/core";
import {
  ValidatorFn,
  UntypedFormGroup,
  ValidationErrors,
} from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor() {}

  /** Encarcado de recorrer todos los controls del form e ir seteando la propiedad TOUCHED = TRUE
   *  Para que aparezca como tocado y se active la validació de requerido, en caso de serlo.
   */
  marcarTodosComoTocados(controls) {
    for (let i in controls) {
      controls[i].markAsTouched();
      if (controls[i].controls) {
        this.marcarTodosComoTocados(controls[i].controls);
      }
    }
  }

  //TODO: hacer una función generica para controlar nombre y apellido con tildes y dieresis
  validarCaracteres(keyCode) {
    if (keyCode >= 65 && keyCode <= 90) return true;
    if (keyCode >= 97 && keyCode <= 122) return true;
    if (keyCode >= 192 && keyCode <= 196) return true;
    if (keyCode >= 192 && keyCode <= 196) return true;
    if (keyCode >= 200 && keyCode <= 207) return true;
    if (keyCode >= 209 && keyCode <= 214) return true;
    if (keyCode >= 217 && keyCode <= 221) return true;
    if (keyCode >= 224 && keyCode <= 229) return true;
    if (keyCode >= 232 && keyCode <= 239) return true;
    if (keyCode >= 241 && keyCode <= 246) return true;
    if (keyCode >= 249 && keyCode <= 255) return true;
    if (keyCode == 32) return true;

    return false;
  }
  validarEmails(control: UntypedFormGroup): ValidationErrors | null {
    let email = "";
    if (control.value != null) {
      email = control.value.toString().trim();
    }
    const reg =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const regOficial =
      /^[a-zA-Z0-9.!#$%&ñ'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$/;
    let error = false;
    if (email.substring(0, 1) !== ".") {
      if (reg.test(email) && regOficial.test(email)) {
        error = true;
      } else if (reg.test(email)) {
        error = true;
      }
    }
    return error ? null : { emailInvalido: true };
  }

  stringMaxLength(control: UntypedFormGroup): ValidationErrors | null {
    const test = control.value.trim().split(" ").join("").length;
    if (test > 50)
      return {
        maxLength: test,
      };
    return null;
  }
}
