import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-content",
  templateUrl: "./dialog.component.html",
})
export class DialogGenericoComponent {
  public inputValue: string = "";
  public title: string = "";

  constructor(
    public dialogRef: MatDialogRef<DialogGenericoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.inputValue = data.nombre;
    this.title = data.title;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() {
    this.dialogRef.close(this.inputValue);
  }

  onOkEnter(e) {
    if (e.keyCode === 13) {
      this.dialogRef.close(this.inputValue);
    }
    if (e.keyCode === 27) {
      this.dialogRef.close();
    }
  }
}
