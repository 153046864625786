import {
  Component,
  Inject,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientesService } from 'app/services/clientes.service';
import { ProductoService } from 'app/services/producto.service';
import { Stock } from 'app/models/stock.models';
import { ParametrosService } from 'app/services/parametros.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export interface UserData {
  numero: string;
  nombre: string;
  docu: string;
}

@Component({
  selector: 'app-dialogo',
  templateUrl: './dialogo.component.html',
  styleUrls: ['./dialogo.component.scss'],
})
export class DialogoComponent implements OnChanges, OnInit {
  @ViewChild('urldocumento') urlarchivo: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  clientes: any;
  monto = 0;
  progressPercent;
  verListaArticulos;
  verBtnBuscarArticulo;
  buscarCliente;
  tipoarticulo;
  verMensaje = false;
  articulos: any;
  verAperturaCaja = true;
  verClientes = false;
  verArticulos = false;
  verFPagos = false;
  verConfirmar = false;
  verEditarArticulo: boolean;
  verSpinner = false;
  nombre: string;
  codigo: string;
  cantidad = '0';
  bonificacion: number;
  articulo: any;
  subtotal: string;
  preciounit = 0;
  activarBtnCancelar = true;
  activarBtnGuardar = true;
  disponible: number;
  verErrorStock: boolean;
  fpago = 'Efectivo';
  total: string;
  montorecibido: string;
  cambio = '0.00';
  verConfirmarOK: boolean;
  verSinStock: boolean;
  vtaTableta: boolean;
  mpagos: any;
  articuloNombre: any;
  verAjusteInventario: boolean;
  inventarioActual: any;
  nuevoInventario: any;
  observacion: string;
  verCerrarCaja: boolean;
  montoApertura;
  montoCierre;
  montoTotal;
  fechaApertura;
  verTabletas: boolean;
  verXCaja: boolean;
  verActualizarPrecio: boolean;
  verXTableta: boolean;
  inicio: number;
  fin: number;
  tipoDocumento: string;
  verAdjDocumentacion: boolean;
  habilitarGuardar: boolean;
  documentos: any[] = [];
  file: File;
  image: HTMLImageElement;
  userid: any;
  idSocio;
  uploadPercent: any;
  downloadURL: any;
  fileData: File;
  previewUrl: string | ArrayBuffer;
  spinner: boolean;
  verAddFormulario: boolean;
  verUnAdjDocumentacion: boolean;
  archivoAceptado: string;
  verTablaFormulario: boolean;
  verTablaServicio: boolean;
  tipoBusqueda: string;

  lowValue = 0;
  highValue = 5;
  verPagoOtraF: boolean;
  verPagoEfectivo: boolean;
  verBanco: boolean;
  verNumero: boolean;
  verVencimiento: boolean;
  verTitular: boolean;
  verNumeroTransaccion: boolean;
  verNombreTarjeta: boolean;
  bancos: any;
  banco: string;
  nrocheque: string;
  nrotransaccion: string;
  tarjeta: string;
  vencimiento: string;
  titular: string;
  montoFPago: number;
  lstMedioPagoSelected: any = [];
  verCambio: boolean;
  socios: any;
  verTablaCliente: boolean;
  verTablaSocio: boolean;
  tipoCliente: string;
  tipoSujeto: any;
  totalEfectivo = 0;
  totalEfectivoPago = 0;
  totalDebito = 0;
  totalDebitoPago = 0;
  totalCredito = 0;
  totalCreditoPago = 0;
  totalCheque = 0;
  totalChequePago = 0;
  totalTransferencia = 0;
  totalTransferenciaPago = 0;
  saldoCajaTotal = 0;
  totalCompra = 0;
  sumaTotalTablaFPago = 0;
  verCarnet: boolean;
  socio: any;
  mobil: boolean;
  verImprVenta: boolean;
  VerBtnImprimirPago: boolean;
  ClienteImp: any;
  FormulariosImp: any;
  PagosImp: any;
  sinClientes: boolean;
  textPlaceholderbuscar: string;
  displayedColumns: string[] = ['numero', 'nombre', 'docu'];
  dataSource: MatTableDataSource<UserData>;
  totalIngreso: number;
  verSetRol: boolean;
  permisos: any;
  form: UntypedFormGroup;
  montoCtrl: UntypedFormControl = new UntypedFormControl('');
  paginaSiguiente = 1;
  totalArticulos = 10;
  itemXPag = 10;
  search = '';

  constructor(
    private clienteService: ClientesService,
    private articuloService: ProductoService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DialogoComponent>,
    private modalService: NgbModal,
    private paramService: ParametrosService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      clienteSel: new UntypedFormControl(''),
      razonsocial: new UntypedFormControl(''),
      articuloSel: new UntypedFormControl(''),
      observaciones: new UntypedFormControl(''),
    });
    this.archivoAceptado = '.jpg, .jpeg, .png, .pdf';
    this.verAddFormulario = false;
    this.verAdjDocumentacion = false;
    this.verUnAdjDocumentacion = false;
    this.verConfirmar = false;
    this.verAperturaCaja = false;
    this.verClientes = false;
    this.verCerrarCaja = false;
    this.verArticulos = false;
    this.verEditarArticulo = false;
    this.verFPagos = false;
    if (this.data.tipo === 'setRol') {
      this.verAddFormulario = false;
      this.verAdjDocumentacion = false;
      this.verUnAdjDocumentacion = false;
      this.verConfirmar = false;
      this.verAperturaCaja = false;
      this.verClientes = false;
      this.verCerrarCaja = false;
      this.verArticulos = false;
      this.verEditarArticulo = false;
      this.verFPagos = false;
      this.verSetRol = true;
      this.permisos = this.data.permisos;
    }
    if (this.data.tipo === 'caja') {
      this.verConfirmar = false;
      this.verAperturaCaja = true;
      this.verClientes = false;
      this.verArticulos = false;
      this.verEditarArticulo = false;
      this.verFPagos = false;
    }
    if (this.data.tipo === 'cerrarcaja') {
      this.montoApertura = this.data.montoApertura;
      this.montoCierre = this.data.montoCierre;
      this.totalCompra = this.data.totalCompra;
      this.montoTotal = this.data.montoTotal;
      this.fechaApertura = this.data.fechaApertura;
      this.totalEfectivo = this.data.efectivo;
      this.totalEfectivoPago = this.data.efectivoPago;
      this.totalDebito = this.data.debito;
      this.totalDebitoPago = this.data.debitoPago;
      this.totalCredito = this.data.credito;
      this.totalCreditoPago = this.data.creditoPago;
      this.totalCheque = this.data.cheque;
      this.totalIngreso = this.data.totalIngreso;
      this.totalChequePago = this.data.chequePago;
      this.totalTransferencia = this.data.transferencia;
      this.totalTransferenciaPago = this.data.transferenciaPago;
      this.saldoCajaTotal = this.data.saldoCajaTotal;
      this.verConfirmar = false;
      this.verAperturaCaja = false;
      this.verClientes = false;
      this.verCerrarCaja = true;
      this.verArticulos = false;
      this.verEditarArticulo = false;
      this.verFPagos = false;
    }
    if (this.data.tipo === 'pago') {
      this.obtenerMediosDePagos();
      this.verConfirmar = false;
      this.verAperturaCaja = false;
      this.verClientes = false;
      this.verArticulos = false;
      this.verEditarArticulo = false;
      this.verFPagos = true;
      this.fpago = 'Efectivo';
    }
    if (this.data.tipo === 'cliente') {
      this.textPlaceholderbuscar = 'Buscar socio ...';
      this.tipoCliente = 'Socio';
      this.verTablaSocio = true;
      this.verAperturaCaja = false;
      this.verConfirmar = false;
      this.verClientes = true;
      this.verArticulos = false;
      this.verEditarArticulo = false;
      this.verFPagos = false;
    }
    if (this.data.tipo === 'sinstock') {
      this.articuloNombre = this.data.articuloNombre;
      this.verAperturaCaja = false;
      this.verConfirmar = false;
      this.verClientes = false;
      this.verArticulos = false;
      this.verEditarArticulo = false;
      this.verFPagos = false;
      this.verSinStock = true;
    }
    if (this.data.tipo === 'verAjusteInventario') {
      this.inventarioActual = this.data.inventarioActual;
      this.verAperturaCaja = false;
      this.verConfirmar = false;
      this.verClientes = false;
      this.verArticulos = false;
      this.verEditarArticulo = false;
      this.verFPagos = false;
      this.verSinStock = false;
      this.verAjusteInventario = true;
    }
    if (this.data.tipo === 'confirmar') {
      this.obtenerMediosDePagos();
      this.obtenerBancos();
      this.verConfirmar = true;
      this.verAperturaCaja = false;
      this.verClientes = false;
      this.verArticulos = false;
      this.verEditarArticulo = false;
      this.verPagoEfectivo = true;
      this.verPagoOtraF = false;
      this.verFPagos = false;
      this.total = Number.parseFloat(this.data.total).toFixed(2);
    }
    //
    if (this.data.tipo === 'ok') {
      if (this.data.formulario) {
        this.VerBtnImprimirPago = true;
      } else {
        this.VerBtnImprimirPago = false;
      }
      this.verConfirmar = false;
      this.verConfirmarOK = true;
      this.verAperturaCaja = false;
      this.verClientes = false;
      this.verArticulos = false;
      this.verEditarArticulo = false;
      this.verFPagos = false;
    }
    if (this.data.tipo === 'cambiarprecio') {
      this.verActualizarPrecio = true;
      this.verConfirmar = false;
      this.verConfirmarOK = false;
      this.verAperturaCaja = false;
      this.verClientes = false;
      this.verArticulos = false;
      this.verEditarArticulo = false;
      this.verFPagos = false;
    }

    if (this.data.tipo === 'articulo') {
      this.obtenerArticulos();
      this.verAperturaCaja = false;
      this.tipoSujeto = data.sujeto;
      this.verClientes = false;
      this.verArticulos = true;
      this.verConfirmar = false;
      this.verEditarArticulo = false;
      this.verFPagos = false;
      this.verTablaFormulario = true;
      this.tipoBusqueda = 'formulario';
    }
    if (this.data.tipo === 'imprimirresumen') {
      this.ClienteImp = data.sujeto;
      this.FormulariosImp = data.detalle;
      this.PagosImp = data.pagos;
      this.verImprVenta = true;
    }
    if (this.data.tipo === 'unarticulo') {
      this.activarBtnCancelar = false;
      this.articulo = this.data.articulo;
      if (this.articulo.cantidad !== 0) {
        this.cantidad = this.articulo.cantidad;
      }
      if (!this.data.descuento) {
        this.preciounit = this.articulo.precio;
      } else {
        this.preciounit = this.articulo.preciosocio;
      }
      // tslint:disable-next-line: radix
      this.subtotal = (
        Number.parseInt(this.cantidad) * this.preciounit
      ).toFixed(2);
      this.codigo = this.articulo.codigo;
      this.nombre = this.articulo.nombre;
      this.disponible = this.articulo.disponible;
      this.verAperturaCaja = false;
      this.verClientes = false;
      this.verArticulos = false;
      this.verEditarArticulo = true;
      this.verFPagos = false;
      this.verConfirmar = false;
    }
    if (data.undocumento) {
      this.verAddFormulario = false;
      this.verUnAdjDocumentacion = true;
      this.verAdjDocumentacion = false;
      this.idSocio = data.idsocio;
    }
    if (data.newformulario) {
      this.verAddFormulario = true;
      this.verUnAdjDocumentacion = false;
      this.verAdjDocumentacion = false;
    }
    if (this.data.tipo === 'carnet') {
      this.socio = data.socio;
      this.verCarnet = true;
    }
    this.userid = sessionStorage.getItem('userid');
    this.habilitarGuardar = true;
    if (window.screen.width <= 375) {
      this.mobil = true;
    } else {
      this.mobil = false;
    }
  }

  ngOnInit(): void {}

  setFPago(value) {
    switch (value) {
      case 'Efectivo':
        this.verBanco = false;
        this.verNombreTarjeta = false;
        this.verNumero = false;
        this.verVencimiento = false;
        this.verTitular = false;
        this.verNumeroTransaccion = false;
        break;
      case 'Transferencia':
        this.verNumeroTransaccion = true;
        this.verBanco = true;
        this.verNumero = false;
        this.verVencimiento = false;
        this.verTitular = true;
        break;
      case 'Cheque':
        this.verNumeroTransaccion = false;
        this.verBanco = true;
        this.verNombreTarjeta = false;
        this.verNumero = true;
        this.verVencimiento = true;
        this.verTitular = true;
        break;
      case 'Tarjeta de Débito':
        this.verNumeroTransaccion = true;
        this.verNombreTarjeta = true;
        this.verBanco = false;
        this.verNumero = false;
        this.verTitular = true;
        break;
      case 'Tarjeta de Crédito':
        this.verNumeroTransaccion = true;
        this.verBanco = false;
        this.verNumero = false;
        this.verNombreTarjeta = true;
        this.verTitular = true;
        break;
    }
    this.fpago = value;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 1000,
    });
  }

  setRadioBtn(valor) {
    if (valor === 'caja') {
      this.articuloService
        .getStockByArticulo(this.articulo.idarticulo)
        .subscribe(
          (data) => {
            const stockdb = data.stock as Stock;
            this.preciounit = stockdb.articulo.precioventa;
            this.articulo.vendidoxtableta = false;
            this.calcularCantidadXMonto();
          },
          (err) => console.log(err)
        );
    } else {
      this.preciounit = this.articulo.subtotalxtableta;
      this.articulo.vendidoxtableta = true;
      this.calcularCantidadXMonto();
    }
  }

  verFPago() {
    this.verPagoOtraF = true;
    this.verPagoEfectivo = false;
  }

  cancelar(confirmar?: string): void {
    if (confirmar === 'confirmarok' || confirmar === 'imprimir') {
      const resultado = {
        cambio: this.cambio,
        montoing: this.montorecibido,
        total: this.total,
        imprimir: true,
      };
      this.dialogRef.close(resultado);
    } else {
      this.dialogRef.close();
    }
  }

  imprimirVenta() {
    this.dialogRef.close(true);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  obtenerClientes() {
    this.verSpinner = true;
    this.clienteService.getClientes(this.paginaSiguiente, this.itemXPag, this.search).subscribe(
      (data) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (err) => {
        if (err.error.message === 'No se encuentran registros') {
          this.verSpinner = false;
          this.sinClientes = true;
        } else {
          console.log(err);
        }
      },
      () => (this.verSpinner = false)
    );
  }

  obtenerMediosDePagos() {
    this.verSpinner = true;
    this.articuloService.getMediosDePago().subscribe(
      (data) => (this.mpagos = data),
      (err) => console.log(err),
      () => (this.verSpinner = false)
    );
  }

  obtenerBancos() {
    this.paramService.getBancos().subscribe(
      (data) => {
        this.bancos = data;
      },
      (err) => console.log(err)
    );
  }

  setMedioPago(valor) {
    let medio;
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.mpagos.length; index++) {
      if (this.mpagos[index].nombre === valor) {
        medio = this.mpagos[index];
        break;
      }
    }
    return medio;
  }

  calcularCantidadXMonto() {
    let c = 0;
    if (this.cantidad !== '') {
      // tslint:disable-next-line: radix
      c = Number.parseInt(this.cantidad);
      this.subtotal = (this.preciounit * c).toFixed(2);
    } else {
      this.subtotal = '0.00';
    }
  }

  calcularCambio(e) {
    let diferencia = 0;
    if (e.key === 'Backspace') {
      if (this.montorecibido !== '') {
        diferencia =
          Number.parseFloat(this.montorecibido) - Number.parseFloat(this.total);
        if (diferencia.toString() !== 'NaN') {
          if (diferencia > 0 && this.montorecibido >= this.total) {
            this.cambio = diferencia.toFixed(2);
          } else {
            this.cambio = '0.00';
          }
        } else {
          this.cambio = '0.00';
        }
      } else {
        this.cambio = '0.00';
        diferencia = 0 - Number.parseFloat(this.total);
      }
    } else {
      if (
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      ) {
        if (this.montorecibido !== '') {
          diferencia =
            Number.parseFloat(this.montorecibido) -
            Number.parseFloat(this.total);
          if (diferencia.toString() !== 'NaN') {
            if (diferencia > 0 && this.montorecibido >= this.total) {
              this.cambio = diferencia.toFixed(2);
            }
          } else {
            this.cambio = '0.00';
          }
        }
      }
    }
    if (diferencia >= 0) {
      this.activarBtnGuardar = false;
    }
    if (diferencia < 0 || Number.isNaN(diferencia)) {
      this.activarBtnGuardar = true;
    }
    if (diferencia.toString() === 'NaN') {
      this.activarBtnGuardar = true;
    }
  }

  calcularPreciosPressKey(e) {
    let c = 0;
    if (e.key === 'Backspace') {
      if (this.cantidad !== '') {
        // tslint:disable-next-line: radix
        c = Number.parseInt(this.cantidad);
        this.subtotal = (this.preciounit * c).toFixed(2);
      } else {
        this.subtotal = '0.00';
      }
    } else {
      if (
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      ) {
        if (this.cantidad !== '') {
          // tslint:disable-next-line: radix
          c = Number.parseInt(this.cantidad);
          this.subtotal = (this.preciounit * c).toFixed(2);
        } else {
          this.subtotal = '0.00';
        }
      }
    }
  }

  handleChange(tipo) {
    if (tipo === 'f') {
      this.obtenerArticulos();
      this.verTablaServicio = false;
      this.verTablaFormulario = true;
      this.tipoBusqueda = 'formulario';
    } else {
      this.verTablaServicio = true;
      this.verTablaFormulario = false;
      this.tipoBusqueda = 'servicio';
    }
  }

  handleChangeTipoCliente(tipo) {
    if (tipo === 's') {
      // socio
      this.verTablaCliente = false;
      this.verTablaSocio = true;
      this.tipoCliente = 'Socio';
      this.textPlaceholderbuscar = 'Buscar socio ...';
    } else {
      // cliente
      this.obtenerClientes();
      this.verTablaCliente = true;
      this.verTablaSocio = false;
      this.tipoCliente = 'Cliente';
      this.textPlaceholderbuscar = 'Buscar cliente ...';
    }
  }

  buscarFormularioServicio(key?: any) {}

  obtenerArticulos() {
    this.verSpinner = true;
    this.articulos = [];
    this.articuloService.getArticulos(1,10).subscribe(
      (data) => {
        this.articulos = data;
      },
      (err) => {
        if (err.error.message === 'No se encuentran registros') {
          this.verSpinner = false;
        }
      },
      () => (this.verSpinner = false)
    );
  }

  selCliente(cliente) {
    if (cliente !== undefined) {
      this.dialogRef.close({ cliente, tipo: this.tipoCliente });
    }
  }

  selArticulo(articulo, indice) {}

  // used to build an array of papers relevant at any given time
  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  nextfocus(tipo) {
    if (tipo === 'cantidad' && this.cantidad !== '' && this.cantidad !== '0') {
      this.calcularCantidadXMonto();
    }
    if (tipo === 'bonificacion') {
      this.calcularCantidadXMonto();
      this.activarBtnCancelar = false;
    }
  }

  guardarCaja() {
    if (this.verCerrarCaja) {
      this.dialogRef.close('cerrado');
    }
    // apertura de caja
    if (this.verAperturaCaja) {
      this.dialogRef.close(this.monto);
    }
    // editar articulo
    if (this.verEditarArticulo) {
      // tslint:disable-next-line: radix
      if (Number.parseInt(this.cantidad) === 0 || this.cantidad === '') {
        alert('La cantidad de artículos debe ser mayor que cero.');
      } else {
        if (!this.data.descuento) {
          this.articulo.precio = this.preciounit;
        } else {
          this.articulo.preciosocio = this.preciounit;
        }
        this.articulo.precio = this.preciounit;
        this.articulo.cantidad = this.cantidad;
        this.articulo.subtotal = this.subtotal;
        this.dialogRef.close(this.articulo);
      }
    }

    if (this.verFPagos) {
      this.dialogRef.close(this.setMedioPago(this.fpago));
    }

    if (this.verConfirmar) {
      if (
        Number.parseFloat(this.montorecibido) > 0 &&
        this.lstMedioPagoSelected.length === 0
      ) {
        let cambio = Number.parseFloat(this.cambio);
        if (cambio == undefined) cambio = 0;
        this.lstMedioPagoSelected.push({
          descripcion: this.fpago,
          monto: this.montorecibido,
          cambio,
          tarjeta: '',
          banco: '',
          nrotransaccion: '',
          nrocheque: '',
          vencimiento: '',
          titular: '',
        });
      }
      this.dialogRef.close({
        cambio: this.cambio == undefined ? 0 : this.cambio,
        montoing: this.montorecibido,
        total: this.total,
        pagos: this.lstMedioPagoSelected,
      });
    }

    if (this.verSinStock) {
      this.verConfirmarOK = false;
      this.verConfirmar = true;
    }

    if (this.verAjusteInventario) {
      this.dialogRef.close({
        nuevoInventario: this.nuevoInventario,
        observacion: this.observacion,
      });
    }

    if (this.verActualizarPrecio) {
      this.dialogRef.close({
        actualizar: true,
      });
    }
  }

  onNoClick(value): void {
    this.dialogRef.close();
  }

  guardar() {
    if (this.inicio !== 0 || this.fin !== 0) {
      this.dialogRef.close({ inicio: this.inicio, fin: this.fin });
    } else {
      Swal.fire('Erro!', 'Complete todos los campos!', 'error');
    }
  }

  guardarSetRol(value) {
    this.dialogRef.close({ permisos: value });
  }

  guardarImagen(vistabase64): File {
    const block = vistabase64.split(';');
    const contentType = block[0].split(':')[1];
    const realData = block[1].split(',')[1];
    const blob = this.b64toBlob(realData, contentType);
    return new File([blob], 'imagen', { type: 'jpeg' });
  }

  guardarArchivo(base64): File {
    const block = base64.split(';');
    const contentType = block[0].split(':')[1];
    const realData = block[1].split(',')[1];
    const blob = this.b64toBlob(realData, contentType);
    return new File([blob], 'archivo', { type: 'pdf' });
  }

  // convierte el valor base64 de la imagen recortada a formato blob
  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  comprobarFPagoCargado(fpago): number {
    let existe = -1;
    this.lstMedioPagoSelected.find((fpg, index) => {
      if (fpg.descripcion === fpago) {
        existe = index;
      }
    });
    return existe;
  }

  openVerticallyCentered(content) {
    this.montoFPago = Number.parseFloat(this.total) - this.sumaTotalTablaFPago;
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        const indice = this.comprobarFPagoCargado(this.fpago);
        if (indice === -1) {
          switch (this.fpago) {
            case 'Efectivo':
              this.lstMedioPagoSelected.push({
                descripcion: this.fpago,
                monto: this.montoFPago,
                tarjeta: '',
                banco: '',
                nrotransaccion: '',
                nrocheque: '',
                vencimiento: '',
                titular: '',
              });
              break;
            case 'Transferencia':
              this.lstMedioPagoSelected.push({
                descripcion: this.fpago,
                monto: this.montoFPago,
                tarjeta: '',
                banco: this.banco,
                nrotransaccion: this.nrotransaccion,
                nrocheque: '',
                vencimiento: '',
                titular: this.titular,
              });
              break;
            case 'Cheque':
              this.lstMedioPagoSelected.push({
                descripcion: this.fpago,
                monto: this.montoFPago,
                tarjeta: '',
                banco: this.banco,
                nrotransaccion: '',
                nrocheque: this.nrocheque,
                vencimiento: this.vencimiento,
                titular: this.titular,
              });
              break;
            case 'Tarjeta de Débito':
              this.lstMedioPagoSelected.push({
                descripcion: 'Tarjeta de Débito',
                monto: this.montoFPago,
                tarjeta: this.tarjeta,
                banco: '',
                nrotransaccion: this.nrotransaccion,
                nrocheque: '',
                vencimiento: '',
                titular: this.titular,
              });
              break;
            case 'Tarjeta de Crédito':
              this.lstMedioPagoSelected.push({
                descripcion: 'Tarjeta de Crédito',
                monto: this.montoFPago,
                tarjeta: this.tarjeta,
                banco: '',
                nrotransaccion: this.nrotransaccion,
                nrocheque: '',
                vencimiento: '',
                titular: this.titular,
              });
              break;
          }
        } else {
          this.lstMedioPagoSelected[indice].monto += this.montoFPago;
        }
        this.sumaTotalTablaFPago = 0;
        this.lstMedioPagoSelected.forEach((pago) => {
          this.sumaTotalTablaFPago = this.sumaTotalTablaFPago + pago.monto;
        });
        if (this.sumaTotalTablaFPago >= Number.parseFloat(this.total)) {
          this.activarBtnGuardar = false;
          if (this.sumaTotalTablaFPago > Number.parseFloat(this.total)) {
            this.verCambio = true;
            this.cambio = (
              this.sumaTotalTablaFPago - Number.parseFloat(this.total)
            ).toFixed();
          }
        } else {
          this.verCambio = false;
        }
      },
      (reason) => {
        console.log(`Closed with: ${reason}`);
      }
    );
  }

  imprimir() {
    const opts = {
      printMode: 'iframe',
      pageTitle: 'Resumen Caja',
      styles: [{ p: 'color: red;' }],
    };
    //  PHE.printElement(document.getElementById('caja-cierre'), opts);
  }

  ngOnChanges(changes: SimpleChanges) {
    // tslint:disable-next-line: forin
    for (const propName in changes) {
      const chng = changes[propName];
      console.log(chng);
    }
  }
}
