export class Variante {
    _id: string;
    nombre:string;
    imagen:string;
    alto: number;
    ancho: number;
    largo: number;
    peso: number;
    stock: number;
    precio: number;
    tipovariante: any;
    articulo: any;
    fechaCreate: Date;
    fechaBaja: Date;
  }
  