import { environment } from '../../environments/environment';


import { Injectable } from '@angular/core';
import { Tipodocumento } from '../models/tipodocumento.models';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {

  tipodocumentos: Observable<Tipodocumento[] | any>;

  constructor(private http: HttpClient) { }

  getTipodocumentos(): Observable<Tipodocumento | any> {
    return this.tipodocumentos = this.http.get(environment.apiUrl + 'tipodocumentos');
  }

}
