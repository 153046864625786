import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PuntoVenta } from 'app/models/puntoventa.models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PtoventaService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<PuntoVenta | any> {
    return this.http.get(environment.apiUrl + 'puntoventas');
  }

  getById(id): Observable<PuntoVenta | any> {
    return this.http.get(environment.apiUrl + 'puntoventa/' + id);
  }

  guardar(ptoventa: PuntoVenta): Observable<any> {
    return this.http.post(environment.apiUrl + 'puntoventas', ptoventa);
  }

  actualizar(ptoventa: PuntoVenta): Observable<any> {
    return this.http.put(`${environment.apiUrl}puntoventa/${ptoventa._id}`, ptoventa);
  }

  eliminar(ptoventa: PuntoVenta): Observable<any> {
    return this.http.delete(`${environment.apiUrl}puntoventa/${ptoventa._id}`);
  }
}
