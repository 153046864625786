import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Cliente } from 'app/models/cliente.models';
import { ClientesService } from 'app/services/clientes.service';

@Component({
  selector: 'app-lista-cliente',
  templateUrl: './lista-cliente.component.html',
  styleUrls: ['./lista-cliente.component.css']
})
export class ListaClienteComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  clientes: Cliente[] = [];
  tipo;
  displayedColumns: string[] = ['numero', 'razonsocial','tipo','cuit'];
  dataSource: MatTableDataSource<Cliente> = new MatTableDataSource();
  loading: boolean;
  paginaSiguiente = 1;
  totalArticulos = 10;
  itemXPag = 10;
  search = '';
  lastKeypress: any;

  constructor(
    private dialogRef: MatDialogRef<ListaClienteComponent>,
    private clienteService: ClientesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tipo = data.tipo;
   }

  ngOnInit(): void {
    if (this.tipo === 'Cliente') { 
      this.getClientes();
    } else {
      this.getProveedores();
    }    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.search = filterValue.trim().toLowerCase();
    if (event.timeStamp - this.lastKeypress > 100) {
      this.paginaSiguiente = 1;
      this.getClientes();
    }
    this.lastKeypress = event.timeStamp;
  }


  getClientes() {
    this.clienteService.getClientes(this.paginaSiguiente, this.itemXPag, this.search).subscribe(
      (res) => {
        this.clientes = res.data;
        this.dataSource = new MatTableDataSource(this.clientes);
        this.dataSource.paginator = this.paginator;
        this.totalArticulos = res.total;
        this.loading = false;
      },
      err => {
        console.log(err)
       // this.openSnackBar(`Ocurrió un error al obtener listado clientes`, 'Información');
        this.loading = false;
      }
    );
  }

  
  page(event) {
    if (event.pageIndex == this.paginaSiguiente) {
      this.paginaSiguiente = event.pageIndex + 1;
    } else {
      this.paginaSiguiente = this.paginaSiguiente - 1;
    }   
    this.getClientes();
  }

  getProveedores() {
    this.clienteService.getClientesByTipo('Proveedor').subscribe(
      (data) => {
        this.clientes = data;
      }
    )
  }

  cerrar(cliente?: Cliente) {
    if (cliente) {
      this.dialogRef.close(cliente);
    } else {
      this.dialogRef.close();
    }
  }
}
