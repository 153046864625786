import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { Usuario } from 'app/models/usuario';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  headers: HttpHeaders;
  usuario: Observable<Usuario>;
  tokenParam;
  currentUserFirebase;

  constructor(
    private http: HttpClient,
    private router: Router,
    private dataService: DataService
  ) { 
    
  }

  set currentUser(usuario) {
    this.currentUserFirebase = usuario;
  }

  get currentUser() {
    return this.currentUserFirebase;
  }

  singup(usuario: Usuario, pass: string): Observable<any> {
    return this.http.post(environment.apiUrl + 'signup', {
      email: usuario.email,
      nombre: usuario.nombre,
      rol: usuario.rol,
      password: pass,
      apellido: usuario.apellido
    });
  }

  recuperarContrasena(email): Observable<any> {
    return this.http.post(environment.apiUrl + 'recuperarpass', { email });
  }

  cerrarSesion() {
    this.dataService.deleteVariablesSession();
    this.router.navigate(['/']);
  }

  getUsuarioById(id): Observable<Usuario | any> {
    return this.http.get(environment.apiUrl + 'usuario/' + id);
  }

  getUsuarioByEmail(email, token): Observable<Usuario | any> {
    return this.http.post(environment.apiUrl + 'usuario/email/', {
      email,
      token
    });
  }

  cambiarPassword(usuario, password, token): Observable<Usuario | any> {
    return this.http.post(environment.apiUrl + 'cambiarpass/usuario/', {
      usuario, password, token
    });
  }

  // obtenemos los permisos del usuario
  getPermisosUsuario(): Observable<Usuario | any> {
    return this.http.get(environment.apiUrl + 'usuario/permisos/menu');
  }

  getUsuarios(): Observable<Usuario | any> {
    return this.http.get(environment.apiUrl + 'usuarios');
  }

  getUsuariosGeneralesByEmail(email): Observable<Usuario | any> {
    return this.http.get(environment.apiUrl + 'usuario/email/' + email);
  }

  searchUsuariosByTermino(termino) : Observable<Usuario | any> {
    return this.http.get(environment.apiUrl + 'usuarios/buscar/' + termino);
  }

  setHabilitarUsuario(idusuario: string, estado: boolean) {
    return this.http.put(environment.apiUrl + 'usuario/' + idusuario, { validado: estado }, { headers: this.headers });
  }

  setRolUsuario(idusuario: string, permisos: any) {
    return this.http.put(environment.apiUrl + 'usuario/' + idusuario, { permisos }, { headers: this.headers });
  }


  eliminarUsuario(id: string) {
    return this.http.delete(environment.apiUrl + 'usuario/' + id, { headers: this.headers });
  }

  crearColaborador(usuario: Usuario): Observable<Usuario | any> {
    return this.http.post(environment.apiUrl + 'usuario', usuario);
  }

  crearColaboradorByNotificacion(usuario, idnotificacion): Observable<Usuario | any> {
    return this.http.post(environment.apiUrl + 'usuario/notificacion', {usuario, idnotificacion});
  }

  login(userName, password) {
    return this.http.post(environment.apiUrl + 'login', {
      userName,
      password
    });
  }
  
}
