import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PuntoVenta } from 'app/models/puntoventa.models';
import { PtoventaService } from 'app/services/ptoventa.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-punto-venta',
  templateUrl: './punto-venta.component.html',
  styleUrls: ['./punto-venta.component.css']
})
export class PuntoVentaComponent implements OnInit {

  puntoVentas: PuntoVenta[] = [];
  loading: boolean;

  constructor(
    private ptoVentaService: PtoventaService,
    private _snackBar: MatSnackBar,
    ) { }

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Puntos de venta';
    }
    this.getPuntosVentas();
  }

  getPuntosVentas() {
    this.loading = true;
    this.ptoVentaService.getAll().subscribe(
      (data) => {
        this.puntoVentas = data;
        this.loading = false;
      },
      err => {
        console.log(err);
        this.loading = false;
      }
    )
  }

  eliminar(venta) {
    Swal.fire({
      title: `¿Desea eliminar el punto de venta?`,
      text: `${venta.descripcion}`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminalo!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ptoVentaService.eliminar(venta).subscribe(
          (data) => { 
            this.getPuntosVentas();
            this.openSnackBar(`Punto de venta eliminado correctamente`, 'Información');
          },
          (err) => {
            console.log(err);
            this.openSnackBar(`Error al eliminar el punto de venta`, 'Información');
          }
        );
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
