import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DeviceDetectorService } from 'app/services/deviceDetector.service';
import { Subscription } from 'rxjs';
import { DetalleFilaComponent } from './detalle-fila/detalle-fila.component';
import { MatTableDataSource } from '@angular/material/table';

export interface MatColumn {
  name: string;
  title: string;
  field: string;
  class?: any;
  responsivo?: boolean;
}

export interface Acciones {
  tipo: Accion;
  name: string;
  icon: string;
  dato?: any;
}

export enum Accion {
  edit,
  add,
  delete,
  redirect
}

@Component({
  selector: 'app-tabla-responsiva',
  templateUrl: './tabla-responsiva.component.html',
  styleUrls: ['./tabla-responsiva.component.scss']
})
export class TablaResponsivaComponent {
  @Output() pageEvent = new EventEmitter<any>();
  redirect = Accion.redirect;

  // acciones estandar
  @Output() accionRealizada = new EventEmitter<Acciones>();

  @ViewChild(MatPaginator)
  set paginat(paginator: MatPaginator) {
    this.paginator = paginator;
  }

  @Input() dataSource: MatTableDataSource<any>;
  @Input() columns: MatColumn[] = [];
  @Input() route: string = '';
  @Input() acciones: Acciones[];
  @Input() paginaSiguiente = 1;
  @Input() totalArticulos;
  @Input() itemXPag = 10;
  private paginator: MatPaginator;
  public displayedColumns: String[] = [];
  esPantallaMovil: boolean;
  subscripcion;


  constructor(
    public dialog: MatDialog,
    private deviceDetectorService: DeviceDetectorService) {
    // detectar disposito movil o cambios en la resolución
    this.subscripcion = new Subscription()
    this.subscripcion.add(
      this.deviceDetectorService.cambiosTamañoPantalla$.subscribe(
        pantallaMovil => this.esPantallaMovil = pantallaMovil
      )
    )
  }

  ngOnInit(): void {
    this.displayedColumns = this.columns.map(x => x.name);
  }

  handlePageEvent(event: any) {
    this.pageEvent.emit(event.pageIndex);
  }

  realizarAccion(accion, data) {
    accion.dato = data;
    this.accionRealizada.emit(accion)
  }

  mostrarDetalle(datos) {
    const dialogRef = this.dialog.open(DetalleFilaComponent, {
      data: {
        datos: datos,
        columns: this.columns
      },
      disableClose: true
    });

  }

  ngOnDestroy(): void {
    this.subscripcion.unsubscribe()
  }

}
