import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Producto } from 'app/models/producto.models';
import { CajaService } from 'app/services/caja.service';
import { ProductoService } from 'app/services/producto.service';

@Component({
  selector: 'app-lista-productos-dialogo',
  templateUrl: './lista-productos-dialogo.component.html',
  styleUrls: ['./lista-productos-dialogo.component.css'],
})
export class ListaProductosDialogoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  verSpinner: boolean; 
  displayedColumns: string[] = ['codigo','codigobarras', 'nombre', 'stock','precio', 'accion'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  articulos = [];
  verMensaje: boolean;
  lowValue = 0;
  highValue = 6;
  porcentajeBonificacion = 0;
  paginaSiguiente = 1;
  totalArticulos = 10;
  itemXPag = 5;
  search = '';
  lastKeypress: any;

  constructor(
    private cajaService: CajaService,
    private productoService: ProductoService,
    public dialogRef: MatDialogRef<ListaProductosDialogoComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.porcentajeBonificacion = this.data.bonificacion;
  }

  ngOnInit(): void {
    this.obtenerArticulos();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.search = filterValue.trim().toLowerCase();
    if (event.timeStamp - this.lastKeypress > 100) {
      this.paginaSiguiente = 1;
      this.obtenerArticulos();
    }
    this.lastKeypress = event.timeStamp;
  }

  page(event) {
    if (event.pageIndex == this.paginaSiguiente) {
      this.paginaSiguiente = event.pageIndex + 1;
    } else {
      this.paginaSiguiente = this.paginaSiguiente - 1;
    }   
    this.obtenerArticulos();
  }

  obtenerArticulos() {
    this.verSpinner = true;
    this.articulos = [];
    this.productoService.getArticulos(this.paginaSiguiente, this.itemXPag, this.search).subscribe(
      (res) => {
        this.articulos = res.data;
        this.dataSource = new MatTableDataSource(this.articulos);
        this.verSpinner = false;
        this.totalArticulos = res.total;
      },
      err => {
        this.verSpinner = false;
      }
    );
  }

  cancelar() {
    this.dialogRef.close();
  }

  selArticulo(articulo, indice) {
    if (articulo !== undefined) {
      if (this.comprobarArticuloCargado(articulo._id)){
        const index = this.comprobarIndiceArticuloCargado(articulo._id);
        this.cajaService.articulosList[index].cantidad += 1;
        let subt = 0;
        const cantidadXPrecio =  (articulo.precioventa * this.cajaService.articulosList[index].cantidad);
        const bonif = (this.porcentajeBonificacion * cantidadXPrecio) / 100;
        subt = (cantidadXPrecio - bonif);
        this.cajaService.articulosList[index].subtotalciva = subt;
        this.cajaService.articulosList[index].subtotal = subt;
      } else {
        let subt = 0;
        const bonif = (this.porcentajeBonificacion * articulo.precioventa) / 100;
        subt = (articulo.precioventa - bonif);
        this.cajaService.addArticulo({
          _id: articulo._id,
          codigo: articulo.codigo,
          descripcion: articulo.nombre,
          tipo: articulo.tipo,
          preciounitario: articulo.precioventa,
          cantidad: 1,
          stock: articulo.stock,
          bonificacion: this.porcentajeBonificacion,
          importebonif: 0,
          subtotal: subt,
          subtotalciva: subt,
          articulo
        });
      }
      this.articulos.splice(indice, 1);
      this.dataSource = new MatTableDataSource(this.articulos);
        this.dataSource.paginator = this.paginator;
      this.openSnackBar('Artículo agregado correctamente');
    }
  }

  comprobarIndiceArticuloCargado(id: string): number {
    let resul = -1;
    for (let index = 0; index < this.cajaService.articulosList.length; index++) {
      const articulo = this.cajaService.articulosList[index];
      if (articulo._id === id) {
        resul = index;
        break;
      }
    }
    return resul;
  }

  comprobarArticuloCargado(id: string): boolean {
    let resul = false;
    for (let index = 0; index < this.cajaService.articulosList.length; index++) {
      const articulo = this.cajaService.articulosList[index];
      if (articulo._id === id) {
        resul = true;
        break;
      }
    }
    return resul;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 1000
    });
  }

}
