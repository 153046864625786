import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'app/services/data.service';

@Component({
  selector: 'app-dialog-loading',
  templateUrl: './dialog-loading.component.html',
  styleUrls: ['./dialog-loading.component.css']
})
export class DialogLoadingComponent {
  
  mensaje: string;

  constructor(
    public dataService: DataService,
    public dialogRef: MatDialogRef<DialogLoadingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ){
      this.mensaje = this.data.mensaje;
      this.dataService.isCloseDialog.subscribe(
        status => {
          if (!status) {
            this.dialogRef.close();
          }
        }
      )
  }

}
