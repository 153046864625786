import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Empresa } from "app/models/empresa.models";
import { ArchivosService } from "app/services/archivos.service";
import { EmpresaService } from "app/services/empresa.service";
import { UtilService } from "app/shared/utils.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-empresa",
  templateUrl: "./empresa.component.html",
  styleUrls: ["./empresa.component.css"],
})
export class EmpresaComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private empresaService: EmpresaService,
    private archivosService: ArchivosService,
    private utilService: UtilService
  ) {
    this.myForm = this.fb.group({
      nrodocumento: ["", [Validators.required, Validators.minLength(12)]],
      razonsocial: [
        "",
        [Validators.required, this.utilService.stringMaxLength],
      ],
      domiciliocom: ["", [Validators.required]],
      telefono: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(13),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          Validators.maxLength(255),
        ],
      ],
      logo: ["", [Validators.required]],
    });
    this.myForm.controls["email"].setValidators(this.utilService.validarEmails);

    this.autoDisabledForm();
  }
  public empConfig?: Empresa;
  public myForm: FormGroup;
  public imgReaderUpload?: string | ArrayBuffer;
  public actualizarImg: boolean = false;
  public inputHasChange: boolean = true;
  private imgToUpload: File;

  ngOnInit(): void {
    this.empresaService.getEmpresaConfig().subscribe((data) => {
      this.empConfig = data["empresas"][0];
      this.myForm.patchValue(this.empConfig);
    });
  }

  autoDisabledForm() {
    this.myForm.controls["nrodocumento"].disable();
    this.myForm.controls["razonsocial"].disable();
    this.myForm.controls["domiciliocom"].disable();
    this.myForm.controls["telefono"].disable();
    this.myForm.controls["email"].disable();
    this.actualizarImg = false;
  }

  changeDisabled(field: string): void {
    const inputControl = this.myForm.get(field);
    inputControl.disabled ? inputControl.enable() : inputControl.disable();
  }

  changeTemplate(text: string) {
    if (!this.actualizarImg) {
      this.actualizarImg = true;
    } else {
      this.actualizarImg = false;
    }
    if (text === "close" && this.imgReaderUpload !== undefined) {
      this.empConfig.logo = this.imgReaderUpload as string;
    }
  }

  async preImg(event) {
    if (event.target.files.length === 0) return;
    const imgSelected = event.target.files[0];

    if (imgSelected && imgSelected.type.indexOf("image") === -1) {
      Swal.fire({
        title: "Error!",
        text: "Se admiten archivos con extensión del tipo .jpg/.jpeg/.png",
        icon: "warning",
        confirmButtonText: "Salir",
      });
      return false;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      this.imgReaderUpload = this.empConfig.logo;
      this.empConfig.logo = e.target.result as string;
      this.imgToUpload = imgSelected;
    };
    reader.readAsDataURL(imgSelected);
  }

  onChangeInput(field: string, event?) {
    let inputValue = this.myForm.get(field).value;

    if (event && event.target.files[0]) {
      inputValue = event.target.files[0].name;
    }

    inputValue !== this.empConfig[field]
      ? (this.inputHasChange = false)
      : (this.inputHasChange = true);
  }

  checkWord({ keyCode }: KeyboardEvent) {
    return this.utilService.validarCaracteres(keyCode);
  }

  isValidField(field: string): boolean | null {
    return (
      this.myForm.controls[field].errors && this.myForm.controls[field].touched
    );
  }

  uploadFile() {
    if (!this.imgToUpload) console.log("File not found.");

    return new Promise((resolve, reject) => {
      this.archivosService.importarArchivoExcel(this.imgToUpload).subscribe(
        (res) => {
          this.empConfig.logo = res["data"]["url"];
          resolve(true);
        },
        (err) => {
          Swal.fire({
            title: "Error!",
            text: "Hubo un inconveniente al querer actualizar su logo",
            icon: "error",
            confirmButtonText: "Salir",
          });
          reject(false);
        }
      );
    });
  }
  async guardar() {
    if (this.myForm.invalid) {
      return;
    }
    try {
      await this.uploadFile();
      this.empConfig.nrodocumento = this.myForm.controls["nrodocumento"].value;
      this.empConfig.razonsocial = this.myForm.controls["razonsocial"].value;
      this.empConfig.domiciliocom = this.myForm.controls["domiciliocom"].value;
      this.empConfig.telefono = this.myForm.controls["telefono"].value;
      this.empConfig.email = this.myForm.controls["email"].value;

      if (this.empConfig._id) {
        this.empresaService.actualizarDataEmpresa(this.empConfig).subscribe(
          (data) => {
            this.autoDisabledForm();
            Swal.fire({
              title: "Excelente!",
              text: `Sus datos se han actualizado correctamente!`,
              confirmButtonColor: "#3085d6",
              timer: 5000,
            });
            this.imgReaderUpload = this.empConfig.logo;
            this.inputHasChange = true;
          },
          (err) => {
            Swal.fire({
              title: "Error!",
              text: "Hubo un inconveniente al modificar los datos de la empresa",
              icon: "error",
              confirmButtonText: "Salir",
            });
          }
        );
      }
    } catch (error) {
      console.log({ error });
    }
  }
}
