import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Accion } from '../tabla-responsiva.component';

@Component({
  selector: 'app-detalle-fila',
  templateUrl: './detalle-fila.component.html',
  styleUrls: ['./detalle-fila.component.scss']
})
export class DetalleFilaComponent {

   constructor(
    public dialogRef: MatDialogRef<DetalleFilaComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any){
      console.log(data.datos, data.columns)
     }

     cerrar(){
      this.dialogRef.close()
     }

}
