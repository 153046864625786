import { TipoResponsble } from './tiporesponsable.models';
import { Tipodocumento } from './tipodocumento.models';

export class Empresa {
    _id: string;
    nrodocumento: string;
    nombrefantasia: string;
    razonsocial: string;
    domiciliocom: string;
    domicilioreal: string;
    ingresosbrutos: string;
    inicioactividad: string;
    tiporesponsableiva: TipoResponsble;
    tipodocumento: Tipodocumento;
    email: string;
    telefono: string;
    celular: string;
    logo: string;
    usuario: string;
    sucursales: any;
    emailpropietario: string;
    nombrepropietario: string;
    apellidopropietario: string;
    password: string;
}
