import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Usuario } from 'app/models/usuario';
import { AuthService } from 'app/services/auth.service';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { debounceTime } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-colaborador',
  templateUrl: './create-colaborador.component.html',
  styleUrls: ['./create-colaborador.component.css']
})
export class CreateColaboradorComponent implements OnInit {


  usuario: Usuario;
  form: UntypedFormGroup;
  lstUsuarios = [];
  total = 0;
  cargando: boolean;
  searchCtrl: FormControl = new FormControl('');
  displayedColumns: string[] = ['id', 'nombre', 'perfil', 'email', 'accion'];
  dataSource = new MatTableDataSource<any>();
  filteredColaborarores: any[] = [];
  selected = 'vendedor';
  colaboradorSelected: any;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private notificacionesService: NotificacionesService,
    private router: Router,
    public dialogRef: MatDialogRef<CreateColaboradorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    this.lstUsuarios = data?.lstUsuarios;
    this.dataSource.data = this.lstUsuarios;
    this.form = this.fb.group({
      nombre: new UntypedFormControl(''),
      rol: new UntypedFormControl('vendedor'),
      descripcion: new UntypedFormControl(''),
      apellido: new UntypedFormControl(''),
      email: new UntypedFormControl('')
    });

  this.searchCtrl.valueChanges.pipe(debounceTime(300))
      .subscribe(value => {
        this.authService.searchUsuariosByTermino(value).subscribe(
          data => {
            this.filteredColaborarores = data;
          },
          err => console.log(err)  
        )  
      });
  }


  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Nuevo colaborador';
    } 
  }

  setColaborador(event) {
    // buscamos el usuario en la lista 
    this.colaboradorSelected = this.filteredColaborarores.find(item => item.displayName === event.option.value);
    console.log(this.colaboradorSelected);
  }

  eliminarTemporal() {
    this.colaboradorSelected = undefined;
  }

  // buscamos usuarios por email en la base de datos generales
  buscarUsuario() {
    this.authService.getUsuariosGeneralesByEmail(this.searchCtrl.value).subscribe(
      data => {
        this.usuario = data;
      },
      err => Swal.fire({
        title: 'Error!',
        text: err['message'],
        icon: 'error',
        confirmButtonText: 'Salir'
      }),
      () => this.cargando = false
    )
  }

  guardar(usuario?: any) {
    if (usuario) {
      this.usuario = new Usuario();
      this.usuario.email = usuario.email;
      this.usuario.rol  = this.selected;
      this.authService.crearColaboradorByNotificacion(this.usuario, usuario._id).subscribe(
        (data) => {
          // eliminamos la notificacion de la lista de usuario
          this.lstUsuarios = this.lstUsuarios.filter(item => item._id !== usuario._id);       
          Swal.fire(
            'Excelente!',
            'Ya has creado un nuevo colaborador',
            'success'
          ).then((result) => {
            if (this.colaboradorSelected) {
              this.colaboradorSelected = undefined;
            }
          });
        },
        err => {
          Swal.fire({
            title: 'Error!',
            text: err['message'],
            icon: 'error',
            confirmButtonText: 'Salir'
          })
        }
      )
    } else {
      // setiamos el rol administrador por ahora
      this.usuario.rol = 'administrador';
      this.authService.crearColaborador(this.usuario).subscribe(
        (data) => {
          Swal.fire(
            'Excelente!',
            'Ya has creado un nuevo colaborador',
            'success'
          );
          this.router.navigate(['configurar/colaboradores']);
        },
        err => {
          Swal.fire({
            title: 'Error!',
            text: err['message'],
            icon: 'error',
            confirmButtonText: 'Salir'
          })
        }
      )
    }   
  }

  eliminarNotificacion(notificacion) {
    this.notificacionesService.deleteNotificacion(notificacion._id).subscribe(
      data => {
         this.lstUsuarios = this.lstUsuarios.filter(item => item._id !== notificacion._id);
        this.dataSource.data = this.lstUsuarios;
        Swal.fire(
          'Excelente!',
          'El colaborador ha sido eliminado de la lista de notificaciones',
          'success'
        );
      },
      err => {
        Swal.fire({
          title: 'Error!',
          text: err['message'],
          icon: 'error',
          confirmButtonText: 'Salir'
        })
      }
    )
  }

  salir() {
    this.dialogRef.close();
  }

}
