import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Variante } from 'app/models/variante.models';
import { ParametrosService } from 'app/services/parametros.service';
import { ProductoService } from 'app/services/producto.service';

@Component({
  selector: 'app-variantes-articulo',
  templateUrl: './variantes-articulo.component.html',
  styleUrls: ['./variantes-articulo.component.css']
})
export class VariantesArticuloComponent {

  form: UntypedFormGroup;
  tipoVariantes = [];
  selectedTipo: any;
  idArticulo: string;

  constructor(
    private articuloService: ProductoService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<VariantesArticuloComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.idArticulo = this.data.idArticulo;
    this.form = this.fb.group({
      tipo: ['', Validators.required],
      tipoItem: '',
      peso: '',
      alto: '',
      ancho: '',
      largo: '',
      stock: '',
      precio: ['', Validators.required],
    });
    this.getTipoVariante();

    this.form.controls.tipo.valueChanges.subscribe(
      da => {
        const itemSelect = this.tipoVariantes.find(x => x._id == da);
        this.selectedTipo = itemSelect;
      }
    )
  }

  getTipoVariante() {
    this.articuloService.getTipoVariantes().subscribe(
      (tipoVariantes) => { 
        this.tipoVariantes = tipoVariantes;
        if (this.tipoVariantes.length > 0) {
          this.form.controls.tipo.patchValue(this.tipoVariantes[0])
        } 
      },
      err => {
        console.log(err)
      }
    )
  }

  salir() {
    this.dialogRef.close();
  }

  guardarVariante() {
    // buscamos el tipo
    let variante = new Variante();
    variante.nombre = this.form.controls.tipoItem.value;
    variante.tipovariante =this.form.controls.tipo.value;
    variante.peso =this.form.controls.peso.value;
    variante.alto =this.form.controls.alto.value;
    variante.ancho =this.form.controls.ancho.value;
    variante.largo =this.form.controls.largo.value;
    variante.stock =this.form.controls.stock.value;
    variante.precio =this.form.controls.precio.value;
    variante.articulo = this.idArticulo;
    variante.fechaBaja = null;
    this.articuloService.guardarVariante(variante).subscribe(
      (res) => {
        this.dialogRef.close(res)
      },
      (err) => {
        console.log(err)
      }
    )
   
  }
}
