import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
  
  constructor(private http: HttpClient) { }

  getNotificaciones(): Observable<any> {
    return this.http.get(environment.apiUrl + 'notificaciones');
  }

  deleteNotificacion(_id: any) : Observable<any> {
    return this.http.delete(environment.apiUrl + 'notificacion/' + _id);
  }

  
}
