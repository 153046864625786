import { ListaPrecio } from 'app/models/listaprecio.models';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ListaprecioService } from 'app/services/listaprecio.service';

@Component({
  selector: 'app-lista-precio',
  templateUrl: './lista-precio.component.html',
  styleUrls: ['./lista-precio.component.css']
})
export class ListaPrecioComponent implements OnInit {

  listaPrecios = [];

  constructor(
    private listaPrecioService: ListaprecioService,

    ) { }

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Lista de precios';
    }
    this.getListaPrecios();
  }

  getListaPrecios() {
    this.listaPrecioService.getListaPrecios().subscribe(
      (data) => {
        this.listaPrecios = data;
      },
      err => console.log(err)
    )
  }

}
