import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Empresa } from 'app/models/empresa.models';
import { Tipodocumento } from 'app/models/tipodocumento.models';
import { TipoResponsble } from 'app/models/tiporesponsable.models';
import { DocumentosService } from 'app/services/documentos.service';
import { EmpresaService } from 'app/services/empresa.service';
import { TipoResponsableService } from 'app/services/tiporesponsable.service';
import { UtilService } from 'app/shared/utils.service';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AddSucursalComponent } from '../setup-empresa/add-sucursal/add-sucursal.component';

@Component({
  selector: 'app-setup-empresa-v2',
  templateUrl: './setup-empresa-v2.component.html',
  styleUrls: ['./setup-empresa-v2.component.scss']
})
export class SetupEmpresaV2Component implements OnInit {

  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  tercerFormGroup: UntypedFormGroup;
  sucursales = [];
  responsables: TipoResponsble[] = [];
  documentos: Tipodocumento[] = [];
  empresa: Empresa;
  guardandoEmpresa = false;

  constructor(
    private empresaService: EmpresaService,
    private tipoResponsableService: TipoResponsableService,
    private tipoDocumentoService: DocumentosService,
    public dialog: MatDialog,
    private router: Router,
    private utilService: UtilService,
    private _formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      razonsocial: ['', Validators.required],
      tipoiva: [''],
      tipoduc: [''],
      inicioactividad: [''],
      ingresosbrutos: ['', Validators.required], // simula CP
      domiciliocom: ['', Validators.required],
      nrodocumento: ['', Validators.required],
      email: ['', Validators.required],
      telefono: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      logo: ['']
    });
    this.tercerFormGroup = this._formBuilder.group({
      emailpropietario: ['', Validators.required],
      nombrepropietario: ['', Validators.required],
      apellidopropietario: ['', Validators.required],
      password: ['' , Validators.required]
    });
    this.getTipoResponsables() ;
    this.getTipoDocumentos();
    this.empresa = new Empresa();
  }

  getTipoResponsables() {
    this.tipoResponsableService.getTipoResponsables().subscribe((data) => {
      this.responsables = data;
      this.firstFormGroup.controls.tipoiva.setValue('5bc52e76eb37b117bb3bc5de', {
        onlySelf: true
      })
    })
  }

  getTipoDocumentos() {
    this.tipoDocumentoService.getTipodocumentos().subscribe((data) => {
      this.documentos = data;
      let cuit = this.documentos.find(x => x.codigo == '80')
      this.firstFormGroup.controls.tipoduc.setValue(cuit._id, {
        onlySelf: true
      })
    })
  }

  openDialogSucursal(sucursal?) {
    const dialogRef = this.dialog.open(AddSucursalComponent, {
      minWidth: '40%',
      width: '400px',
      data: {
        numero: this.sucursales.length + 1,
        sucursal
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.sucursales.push(result);
      }
    });
  }

  getError(controlName:string){
    let invalid =  this.firstFormGroup.controls[controlName]?.errors?.required &&
                   this.firstFormGroup.controls[controlName]?.touched;
    return invalid;
  }

  eliminarSucursal(sucursal) {

  }

  guardarSetup() {
    this.guardandoEmpresa  = true;
    if(this.firstFormGroup.invalid){
      this.firstFormGroup.markAllAsTouched()
      this.guardandoEmpresa = false;
      return;
    }

    this.empresa.razonsocial = this.firstFormGroup.controls.razonsocial.value;
    this.empresa.logo = 'https://kocursoft.com/img/logo.jpg';
    this.empresa.inicioactividad = this.firstFormGroup.controls.inicioactividad.value;
    this.empresa.ingresosbrutos = this.firstFormGroup.controls.ingresosbrutos.value;
    this.empresa.domiciliocom = this.firstFormGroup.controls.domiciliocom.value;
    this.empresa.telefono = this.firstFormGroup.controls.telefono.value;
    this.empresa.email = this.firstFormGroup.controls.email.value;
    this.empresa.nrodocumento = this.firstFormGroup.controls.nrodocumento.value;
    this.empresa.tiporesponsableiva = this.firstFormGroup.controls.tipoiva.value;
    this.empresa.usuario = sessionStorage.getItem('userid');
    this.empresa.tipodocumento = this.firstFormGroup.controls.tipoduc.value;
    this.empresa.sucursales = this.sucursales;
    this.empresa.emailpropietario = this.tercerFormGroup.controls.emailpropietario.value;
    this.empresa.nombrepropietario = this.tercerFormGroup.controls.nombrepropietario.value;
    this.empresa.apellidopropietario = this.tercerFormGroup.controls.apellidopropietario.value;
    this.empresa.password = this.tercerFormGroup.controls.password.value;
    this.empresaService.guardarEmpresa(this.empresa)
    .pipe(finalize(()=> this.guardandoEmpresa = false))
    .subscribe(
      (data) => {
        console.log(data);
        Swal.fire(
          'Excelente!',
          'Ya has registrado tu empresa en Pymes Kocursoft',
          'success'
        );
        this.router.navigate(['/auth/login']);
      },
      err => {
        console.log(err)
        Swal.fire(
          'Error!',
          err.message,
          'error'
        );

      }
    )
  }
}

