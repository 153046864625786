import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from 'app/services/data.service';

@Pipe({
  name: 'formatComprobante'
})
export class FormatComprobantePipe implements PipeTransform {

  constructor(private dataService: DataService) {}
  
  transform(value: unknown, ...args: unknown[]): unknown {
     return this.dataService.formatearNumeroComprobante(value);
  }

}
