import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Producto } from "app/models/producto.models";
import { ArchivosService } from "app/services/archivos.service";
import { ProductoService } from "app/services/producto.service";
import Swal from "sweetalert2";
import { UploadFileComponent } from "../../shared/upload-file/upload-file.component";
import { DialogLoadingComponent } from "../dialog-loading/dialog-loading.component";
import { DataService } from "app/services/data.service";

@Component({
  selector: "app-productos",
  templateUrl: "./productos.component.html",
  styleUrls: ["./productos.component.css"],
})
export class ProductosComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    "codigo",
    "nombre",
    "codigobarras",
    "tipo",
    "stock",
    "precioventa",
    "accion",
  ];
  dataSource: MatTableDataSource<Producto> = new MatTableDataSource();

  productos: Producto[] = [];
  loading: boolean;
  paginaSiguiente = 1;
  totalArticulos = 10;
  itemXPag = 10;
  search = "";
  lastKeypress: any;

  constructor(
    private productoService: ProductoService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router,
    private archivoServicio: ArchivosService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector(".navbar-brand");
    if (sidebar !== undefined) {
      sidebar.innerHTML = "Productos y Servicios";
    }
    this.getProductos();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.search = filterValue.trim().toLowerCase();
    if (event.timeStamp - this.lastKeypress > 100) {
      this.paginaSiguiente = 1;
      this.getProductos();
    }
    this.lastKeypress = event.timeStamp;
  }

  getProductos() {
    this.loading = true;
    this.dataSource.data = [];
    this.productoService
      .getArticulos(this.paginaSiguiente, this.itemXPag, this.search)
      .subscribe(
        (res) => {
          this.productos = res.data;
          this.dataSource = new MatTableDataSource(this.productos);
          this.loading = false;
          this.totalArticulos = res.total;
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  page(event) {
    if (event.pageIndex == this.paginaSiguiente) {
      this.paginaSiguiente = event.pageIndex + 1;
    } else {
      this.paginaSiguiente = this.paginaSiguiente - 1;
    }
    this.getProductos();
  }

  openDialog(producto?: Producto) {
    this.router.navigate(["/productos", "create"]);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  actualizarPreciosMasivos() {
    this.router.navigateByUrl("/productos/precios/masivos");
  }

  /**
   * Descargar un excel con los articulos que tiene el sistema
   */
  descargarExcel() {
    this.dataService.isCloseDialog.next(true);
    const fileName = `articulos_${new Date().getDate()}${
      new Date().getUTCMonth() + 1
    }${new Date().getFullYear()}${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}.xlsx`;

    const dialogRef = this.dialog.open(DialogLoadingComponent, {
      minWidth: "400",
      minHeight: "400",
      disableClose: true,
      data: {
        mensaje: "Descargando artículos. Espere ...",
      },
    });

    this.archivoServicio.getArticulosExcel().subscribe(
      (response) => {
        this.dataService.isCloseDialog.next(false);
        this.manageExcelFile(response, fileName);
      },
      (err) => {
        this.dataService.isCloseDialog.next(false);
        console.log(err);
        this.openSnackBar(
          `Ocurrió un error al descargar listado artículos`,
          "Información"
        );
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  manageExcelFile(response: any, fileName: string): void {
    debugger;
    const dataType = response.type;
    const binaryData = [];
    binaryData.push(response);

    const filtePath = window.URL.createObjectURL(
      new Blob(binaryData, { type: dataType })
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = filtePath;
    downloadLink.setAttribute("download", fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  cargarExcel() {
    const dialogRef = this.dialog.open(UploadFileComponent, {
      minWidth: "40%",
      minHeight: "400",
      disableClose: true,
      data: {
        mensaje: "Subir un archivo excel con los productos",
        tipo: "articulos",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.getProductos();
    });
  }

  eliminar(producto: Producto, event) {
    //para evitar que entre en el producto

    event.preventDefault();
    event.stopPropagation();
    Swal.fire({
      title: `¿Desea eliminar el ${producto.tipo}?`,
      text: `${producto.nombre}`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminalo!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.productoService.eliminarArticulo(producto._id).subscribe(
          (data) => {
            this.productos = data;
            this.getProductos();
            this.openSnackBar(
              `${producto.tipo} eliminado correctamente`,
              "Información"
            );
          },
          (err) => {
            console.log(err);
            this.openSnackBar(
              `Error al eliminar el ${producto.tipo}`,
              "Información"
            );
          }
        );
      }
    });
  }
}
