import { Rubro } from "./../../../models/rubro.models";
import { Moneda } from "app/models/moneda.models";
import { Umedida } from "./../../../models/umedida.models";
import { Producto } from "./../../../models/producto.models";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ParametrosService } from "app/services/parametros.service";
import { ProductoService } from "app/services/producto.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Marca } from "app/models/marca.models";
import Swal from "sweetalert2";
import { ArchivosService } from "app/services/archivos.service";
import { MatDialog } from "@angular/material/dialog";
import { VariantesArticuloComponent } from "../variantes-articulo/variantes-articulo.component";
import { Variante } from "app/models/variante.models";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-detalle-producto",
  templateUrl: "./detalle-producto.component.html",
  styleUrls: ["./detalle-producto.component.css"],
})
export class DetalleProductoComponent implements OnInit {
  @ViewChild("fileInput") fileInput!: ElementRef<HTMLInputElement>;

  medidas: Umedida[] = [];
  tipoivas = [];
  listaprecios = [];
  monedas: Moneda[] = [];
  rubros: Rubro[] = [];
  marcas: Marca[] = [];
  form: UntypedFormGroup;
  producto: Producto;
  textoBtn: string;
  idProducto: any;
  verStock = true;
  verVariante = false;
  isImgsToRecoverActive = false;
  isLoading: boolean = false;
  variantes: Variante[] = [];
  list: any[] = [];
  listNoImg: number[];
  listImgCopy: any[] = [];
  dataSource: MatTableDataSource<Variante> = new MatTableDataSource();
  displayedColumns: string[] = [
    "nombre",
    "stock",
    "precio",
    "accion"
  ];
  listSinModificar: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private parametroServicio: ParametrosService,
    private productoService: ProductoService,
    private router: Router,
    private routerActive: ActivatedRoute,
    private archivoService: ArchivosService,
    public dialog: MatDialog
  ) {
    this.idProducto = this.routerActive.snapshot.params.id;
    this.form = this.fb.group({
      codigo: ["", Validators.required],
      nombre: ["", Validators.required],
      tipo: new UntypedFormControl("producto"),
      codigobarra: new UntypedFormControl(0),
      umedida: new UntypedFormControl(""),
      stock: new UntypedFormControl(0),
      stockminimo: new UntypedFormControl(0),
      stockmaximo: new UntypedFormControl(0),
      impuestos: new UntypedFormControl(""),
      preciosinivaventa: new UntypedFormControl(0),
      precioventa: new UntypedFormControl(0),
      preciocompra: new UntypedFormControl(0),
      preciosinivacompra: new UntypedFormControl(0),
      descripcion: new UntypedFormControl(""),
      iva: new UntypedFormControl(""),
      moneda: new UntypedFormControl(""),
      posicioniva: new UntypedFormControl("gravado"),
      listaprecio: new UntypedFormControl(""),
      rubro: new UntypedFormControl(""),
      marca: new UntypedFormControl(""),
      sku: new UntypedFormControl(""),
      variante: new UntypedFormControl(false),
      public: new UntypedFormControl(false),
      imagenes: new UntypedFormControl(""),
    });
    this.form.controls.codigo.disable();
    if (!this.idProducto) {
      this.textoBtn = "Crear";
      this.producto = new Producto();
      this.getNuevoCodigoArticulos();
    } else {
      this.textoBtn = "Actualizar";
      this.productoService.getArticulo(this.idProducto).subscribe((data) => {
        this.producto = data;
        if (!this.producto.imagenes) {
          this.producto.imagenes = [];
        } else {
          this.list = this.producto.imagenes;
          this.listImgCopy = [...this.list];
          this.listSinModificar = [...this.list];
          // para calcular el array de imagenes de productos
          this.getArrayImgLength();
        }
        // variantes
        this.dataSource.data = [];
        this.variantes = this.producto.variantes ? this.producto.variantes : [];
        if (this.variantes.length > 0 ) {
          this.dataSource.data = this.variantes;
          this.verVariante = true;
        }    
        const stock = this.producto.stock;
        this.form = this.fb.group({
          codigo: this.producto.codigo,
          nombre: this.producto.nombre,
          tipo: this.producto.tipo,
          codigobarra: this.producto.codigobarras,
          umedida: this.producto.unidadmedida,
          stock: stock.cantidad,
          stockminimo: stock.stockminimo,
          stockmaximo: stock.stockmaximo,
          impuestos: this.producto.impuestos,
          preciosinivaventa: this.producto.preciosinivaventa,
          precioventa: this.producto.precioventa,
          preciocompra: this.producto.preciocompra,
          preciosinivacompra: this.producto.preciosinivacompra,
          descripcion: this.producto.descripcion,
          iva: this.producto.tipoiva,
          moneda: this.producto.moneda,
          posicioniva: this.producto.posicioniva,
          listaprecio: this.producto.listaprecio,
          rubro: this.producto.rubro,
          marca: this.producto.marca,
          sku: this.producto?.sku,
          imagenes: this.producto?.imagenes,
          variante: this.variantes.length > 0 ? true: false,
          public: this.producto?.public
        });
      });
    }

  }

  ngOnInit(): void {
    // obtenemos los parametros de los articulos
    this.getParametros();
  }

   setVariante(e) {
    if (e.value) {
      this.verVariante = true;
    } else {
      this.verVariante = false;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  imgsRecover() {
    this.list = [...this.listImgCopy];
    this.isImgsToRecoverActive = false;
    this.getArrayImgLength();
  }

  openFileBrowser() {
    this.fileInput.nativeElement.click();
  }

  getParametros() {
    this.parametroServicio.getParametrosArticulos().subscribe(
      (data) => {
        this.monedas = data.monedas;
        this.medidas = data.umedidas;
        this.tipoivas = data.alicuotasiva;
        this.listaprecios = data.listaprecios;
        this.marcas = data.marcas;
        this.rubros = data.rubros;
        this.marcas.unshift({
          _id: "0",
          nombre: "Seleccione una marca",
          codigo: null,
        });
        this.rubros.unshift({
          _id: "0",
          nombre: "Seleccione un rubro",
          codigo: null,
        });
        let idRubro;
        if (this.producto.rubro) {
          idRubro = this.producto.rubro;
        } else {
          idRubro = "0";
        }
        this.form.controls.rubro.setValue(idRubro, {
          onlySelf: true,
        });
        let idMarca;
        if (this.producto.marca) {
          idMarca = this.producto.marca;
        } else {
          idMarca = "0";
        }
        this.form.controls.marca.setValue(idMarca, {
          onlySelf: true,
        });
        const idLista = this.producto.listaprecio
          ? this.producto.listaprecio
          : this.listaprecios[0]._id;
        this.form.controls.listaprecio.setValue(idLista, {
          onlySelf: true,
        });
        let tipoiva = this.tipoivas.find((x) => x.codigo == "5");
        const idTipoIva = this.producto.tipoiva
          ? this.producto.tipoiva
          : tipoiva._id;
        this.form.controls.iva.setValue(idTipoIva, {
          onlySelf: true,
        });
        let medida = this.medidas.find((x) => x.codigo == "07");
        const idMedida = this.producto.unidadmedida
          ? this.producto.unidadmedida
          : medida._id;
        this.form.controls.umedida.setValue(idMedida, {
          onlySelf: true,
        });
        let moneda = this.monedas.find((x) => x.codigo == "PES");
        const idMoneda = this.producto.moneda
          ? this.producto.moneda
          : moneda._id;
        this.form.controls.moneda.setValue(idMoneda, {
          onlySelf: true,
        });
        this.form.controls.moneda.disable();
        this.form.controls.listaprecio.disable();
        this.form.controls.codigo.disable();
      },
      (err) => console.log(err)
    );
  }

  openDialogVariantes() {
    if (this.producto._id) {
      const dialogRef = this.dialog.open(VariantesArticuloComponent, {
        minWidth: "40%",
        minHeight: "400",
        disableClose: true,
        data: {
          idArticulo: this.producto._id
        },
      });
      dialogRef.beforeClosed().subscribe((data) => {
        if(data) {
          this.getVariantesByProducto();
        }
      })
    } else {
      this.openSnackBar(
        "Para cargar las variandes debe guardar el producto",
        "Atención"
      )
    }
  }

  getVariantesByProducto() {
    this.dataSource.data = [];
    this.productoService.getVariantesPorArticulo(this.idProducto).subscribe(
      (res) => {
        this.variantes = res;
        if (this.variantes.length > 0 ) {
          this.form.controls.variante.setValue(true);
          this.verVariante = true;
        }
        this.dataSource.data = res;
      },
      err => {
        console.log(err);
         this.openSnackBar(
          `Ocurrió un error al obtener variantes`,
          "Error"
        )
      }
    )
  }

  deleteVariante(idVariante: string) {
    Swal.fire({
      title: "Advertencia",
      text: "¿Seguro que desea eliminar la variante?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
    }).then((result) => {
      if (result.value) {
        this.productoService.deleteVariante(idVariante).subscribe(
          (res) => {
            this.openSnackBar(
              `Variante eliminada correctamente`,
              "Información"
            );
            this.getVariantesByProducto();
          },
          err => {
            console.log(err);
             this.openSnackBar(
              `Ocurrió un error al eliminar la variante`,
              "Error"
            )
          }
        )
      } 
    });   
  }

  // obtenemos el codigo del nuevo articulo
  getNuevoCodigoArticulos() {
    this.productoService.getNuevoCodigoArticulos().subscribe(
      (data) => {
        this.form.controls.codigo.setValue(data.codigo, {
          onlySelf: true,
        });
        this.form.controls.codigo.disable();
      },
      (err) =>
        this.openSnackBar(
          `Ocurrió un error al obtener código del artículo`,
          "Error"
        )
    );
  }

  // obtenemos un nuevo codigo de barras aleatorio
  nuevoCodigoBarras() {
    this.productoService.getNuevoCodigoBarrasArticulos().subscribe(
      (data) => {
        this.form.controls.codigobarra.setValue(data.codigo, {
          onlySelf: true,
        });
      },
      (err) =>
        this.openSnackBar(
          `Ocurrió un error al obtener código de barras`,
          "Error"
        )
    );
  }

  cambioTipo(tipo) {
    if (tipo == "producto") {
      this.verStock = true;
      this.form.controls.codigobarra.enable();
      let medida = this.medidas.find((x) => x.codigo == "07");
      this.form.controls.umedida.setValue(medida._id, {
        onlySelf: true,
      });
    } else {
      this.verStock = false;
      this.form.controls.codigobarra.disable();
      let medida = this.medidas.find((x) => x.codigo == "98");
      this.form.controls.umedida.setValue(medida._id, {
        onlySelf: true,
      });
    }
  }


  editarCodigo() {
    if (this.form.controls.codigo.disabled) {
      this.form.controls.codigo.enable();
    } else {
      this.form.controls.codigo.disable();
    }
  }

  calcularIvaVenta(precio) {
    const precioSinIva = this.calcularPrecioSinIva(precio);
    this.form.controls.preciosinivaventa.setValue(precioSinIva, {
      onlySelf: true,
    });
  }

  calcularIvaCompra(precio) {
    const precioSinIva = this.calcularPrecioSinIva(precio);
    this.form.controls.preciosinivacompra.setValue(precioSinIva, {
      onlySelf: true,
    });
  }

  calcularPrecioSinIva(precio) {
    let precioSinIva = 0;
    const iva: any = this.tipoivas.find((x) => x._id === this.form.value.iva);
    if (iva) {
      const ivaValor = iva.nombre.includes("%")
        ? iva.nombre.replace("%", "").trim()
        : "0";
      const convertimosIva = Number.parseFloat(ivaValor.toString());
      const precioIva = (precio * convertimosIva) / 100;
      precioSinIva = precio - precioIva;
    }
    return precioSinIva;
  }

  getArrayImgLength() {
    let longitud = this.list.length;

    this.listNoImg = Array.from(
      { length: 5 - longitud },
      (_, index) => index + 1
    );
  }

  onFileChange(file) {
    // file[0].name para usar para cuando se haga el post
    //file[0].url para la vista previa
    const f = file[0];

    if (f && f.file.type.indexOf("image") === -1) {
      Swal.fire({
        title: "Error!",
        text: "Se admiten archivos con extensión del tipo .jpg/.jpeg/.png",
        icon: "warning",
        confirmButtonText: "Salir",
      });

      return;
    }

    this.list.push(file[0]);
    this.listImgCopy = [...this.list];
    this.getArrayImgLength();
  }
  borrarImg({ id }) {
    //TODO implementar quitar img por id, las img deberían tener un id y un string con la url
    this.isImgsToRecoverActive = true;
    let indexToDelete = id.split("_")[1];
    document.getElementById(id).classList.add("slit-out-diagonal-1");
    setTimeout(() => {
      this.list.splice(indexToDelete, 1);
      this.listSinModificar.splice(indexToDelete, 1);
      this.listNoImg.length++;
    }, 800);
  }

  guardarImagenes(): any {
    //TODO: replantear como guardar img cuando ya hay img guardadas
    return new Promise(async (resolve, reject) => {
      let listado = [];
      if (this.list.length === 0) {
        resolve([]);
      }

      const listaFiltrada = this.list.filter((img) => img["file"]);

      for (let index = 0; index < listaFiltrada.length; index++) {
        try {
          /* let [...props] = Object.getOwnPropertyNames(this.list[index]);
          if (!props.includes("file")) {
          } */

          const res = await this.archivoService.subirImagen(
            listaFiltrada[index]["file"]
          );
          // _id, nombre, url y tamaño
          let imgObj = {
            nombre: res["data"].nombre,
            tamaño: listaFiltrada[index]["file"].size,
            url: res["data"].url,
            id: res["data"].id,
          };
          listado.push(imgObj);
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: "Hubo un inconveniente al subir la imagen del producto",
            icon: "error",
            confirmButtonText: "Salir",
          });
          reject(error);
        }
      }

      //resolve(listado);
      resolve(this.listSinModificar.concat(listado));
    });
  }

  async checkImagenesCargadas() {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "Advertencia",
        text: "¿Seguro que desea guardar un producto sin imágenes?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
      }).then((result) => {
        console.log({ result });
        if (result.value) {
          this.list = [];
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  }

  async guardarProducto() {
    try {
      this.isLoading = true;
      //if (this.list.length === 0) await this.checkImagenesCargadas();
      this.producto.codigo = this.form.controls.codigo.value;
      this.producto.codigobarras = this.form.controls.codigobarra.value;
      this.producto.descripcion = this.form.controls.descripcion.value;
      this.producto.nombre = this.form.controls.nombre.value;
      this.producto.tipo = this.form.controls.tipo.value;
      this.producto.unidadmedida = this.form.controls.umedida.value;
      this.producto.tipoiva = this.form.controls.iva.value;
      this.producto.posicioniva = this.form.controls.posicioniva.value;
      this.producto.moneda = this.form.controls.moneda.value;
      this.producto.listaprecio = this.form.controls.listaprecio.value;
      this.producto.preciocompra = this.form.controls.preciocompra.value;
      this.producto.precioventa = this.form.controls.precioventa.value;
      this.producto.preciosinivaventa =
        this.form.controls.preciosinivaventa.value;
      this.producto.preciosinivacompra =
        this.form.controls.preciosinivacompra.value;
      this.producto.stock = this.form.controls.stock.value;
      this.producto.stockminimo = this.form.controls.stockminimo.value;
      this.producto.stockmaximo = this.form.controls.stockmaximo.value;
      this.producto.rubro = this.form.controls.rubro.value;
      this.producto.marca = this.form.controls.marca.value;
      this.producto.sku = this.form.controls.sku.value;
      this.producto.public = this.form.controls.public.value;
      this.producto.variante = this.form.controls.variante.value;
      if (this.producto.rubro === "0") {
        delete this.producto.rubro;
      }
      if (this.producto.marca === "0") {
        delete this.producto.marca;
      }
      if (this.list.length === this.listImgCopy.length)
        this.producto.imagenes = await this.guardarImagenes();

      if (this.producto._id) {
        this.productoService.modificarArticulo(this.producto).subscribe(
          (data) => {
            this.isLoading = false;
            this.router.navigate(["productos"]);
            this.openSnackBar(
              `${this.producto.tipo} actualizado correctamente`,
              "Información"
            );
          },
          (err) => {
            this.openSnackBar(
              `Ocurrió un error al actualizar un producto`,
              "Error"
            );
          }
        );
      } else {
        this.productoService.guardarArticulo(this.producto).subscribe(
          (data) => {
            this.isLoading = false;
            this.router.navigate(["productos"]);
            this.openSnackBar(
              `${this.producto.tipo} guardado correctamente`,
              "Información"
            );
          },
          (err) => {
            this.openSnackBar(
              `Ocurrió un error al guardar un producto`,
              "Error"
            );
          }
        );
      }
    } catch (error) { }
  }
}
