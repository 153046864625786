import { Marca } from './../../../../models/marca.models';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ParametrosService } from 'app/services/parametros.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-marca',
  templateUrl: './create-marca.component.html',
  styleUrls: ['./create-marca.component.css']
})
export class CreateMarcaComponent implements OnInit {

  marca: Marca;
  form: UntypedFormGroup;
  lstPuntoVentas = [];
  total = 0;

  constructor(
    private fb: UntypedFormBuilder,
    private parametrosService: ParametrosService,
    private router: Router
    ) {
    this.form = this.fb.group({
      codigo: new UntypedFormControl(''),
      nombre: new UntypedFormControl('')
    });
  }

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Nuevo marca';
    }
    this.marca = new Marca();
  }


  guardar() {
    this.marca.codigo = this.form.value.codigo;
    this.marca.nombre = this.form.value.nombre;
    this.parametrosService.guardarMarca(this.marca).subscribe(
      (data) => {
        console.log(data);
        Swal.fire(
          'Excelente!',
          'Ya has creado una nueva marca',
          'success'
        );
        this.router.navigate(['configurar/marcas']);
      },
      err => console.log(err)
    )
  }
}
