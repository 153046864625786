import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ParametrosService } from 'app/services/parametros.service';
import { ProductoService } from 'app/services/producto.service';

@Component({
  selector: 'app-confirmar-caja',
  templateUrl: './confirmar-caja.component.html',
  styleUrls: ['./confirmar-caja.component.css']
})
export class ConfirmarCajaComponent implements OnInit {

  verMensaje: boolean;
  verPagoEfectivo: boolean;
  verSpinner: boolean;
  verPagoOtraF: boolean;
  activarBtnGuardar: boolean;
  total;
  mpagos = [];
  bancos = [];
  lstMedioPagoSelected = [];
  form: UntypedFormGroup;
  montorecibido: string;
  cambio = '0.00';
  fpago = 'Efectivo';


  constructor(
    public dialogRef: MatDialogRef<ConfirmarCajaComponent>,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private parametroService: ParametrosService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.form = this.fb.group({
        montorecibido: new UntypedFormControl('')
      });
      this.total =  Number.parseFloat(this.data.total).toFixed(2);
     }

  ngOnInit(): void {
    this.obtenerMediosDePagos();
    this.obtenerBancos();
    this.activarBtnGuardar = true;
    this.verPagoEfectivo = true;
    this.verPagoOtraF = false;
  }


  obtenerMediosDePagos() {
    this.verSpinner = true;
    this.parametroService.getMediosPagos().subscribe(
      (data) => this.mpagos = data,
      (err) => console.log(err),
      () => (this.verSpinner = false)
    );
  }

  obtenerBancos() {
    this.parametroService.getBancos().subscribe(
      (data) => {
        this.bancos = data;
      },
      (err) => console.log(err)
    );
  }

  verFPago() {}

  guardarCaja() {
    if (Number.parseFloat(this.montorecibido) > 0 && this.lstMedioPagoSelected.length === 0) {
      let cambio = Number.parseFloat(this.cambio);
      if (cambio === undefined) { cambio = 0; }
      this.lstMedioPagoSelected.push({
        descripcion: this.fpago,
        monto: this.montorecibido,
        cambio,
        tarjeta: '',
        banco: '',
        nrotransaccion: '',
        nrocheque: '',
        vencimiento: '',
        titular: ''
      });
    }
    this.dialogRef.close(
      {
        cambio: (this.cambio === undefined) ? 0 : this.cambio,
        montoing: this.montorecibido,
        total: this.total,
        pagos: this.lstMedioPagoSelected
      }
    );

  }

  openVerticallyCentered(ModalFPago) {}

  calcularCambio(e) {
    this.montorecibido = this.form.controls.montorecibido.value;
    let diferencia = 0;
    if (e.key === 'Backspace') {
      if (this.montorecibido !== '') {
        diferencia = Number.parseFloat(this.montorecibido) - Number.parseFloat(this.total);
        if (diferencia.toString() !== 'NaN') {
          if (diferencia > 0 && (this.montorecibido >= this.total)) {
            this.cambio = (diferencia).toFixed(2);
          } else {
            this.cambio = '0.00';
          }
        } else {
          this.cambio = '0.00';
        }
      } else {
        this.cambio = '0.00';
        diferencia = 0 - Number.parseFloat(this.total);
      }
    } else {
      if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        if (this.montorecibido !== '') {
          diferencia = Number.parseFloat(this.montorecibido) - Number.parseFloat(this.total);
          if (diferencia.toString() !== 'NaN') {
            if (diferencia > 0 && (this.montorecibido >= this.total)) {
              this.cambio = (diferencia).toFixed(2);
            }
          } else {
            this.cambio = '0.00';
          }
        }
      }
    }
    if (diferencia >= 0 ) {
      this.activarBtnGuardar = false;
    }
    if (diferencia < 0 || Number.isNaN(diferencia)) {
      this.activarBtnGuardar = true;
    }
    if (diferencia.toString() === 'NaN') {
      this.activarBtnGuardar = true;
    }

  }

cancelar() {
  this.dialogRef.close();
}
}
