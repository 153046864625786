import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, isDevMode } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";

import { FormsModule } from "@angular/forms";

import { StepsModule } from "primeng/steps";
import { CardModule } from "primeng/card";
import { CalendarModule } from "primeng/calendar";

import { SidebarModule } from "./sidebar/sidebar.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { MaterialModule } from "./material/material.module";

import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routing";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { ClientesComponent } from "./pages/clientes/clientes.component";
import { DetalleClienteComponent } from "./pages/clientes/detalle-cliente/detalle-cliente.component";

import { InterceptorHeadersInterceptor } from "./services/interceptor-headers.interceptor";

import { ProductosComponent } from "./pages/productos/productos.component";
import { DetalleProductoComponent } from "./pages/productos/detalle-producto/detalle-producto.component";
import { ResumenComponent } from "./pages/clientes/resumen/resumen.component";
import { VentasComponent } from "./pages/ventas/ventas.component";
import { CajaComponent } from "./pages/caja/caja.component";
import { AperturaComponent } from "./pages/caja/apertura/apertura.component";
import { CierreComponent } from "./pages/caja/cierre/cierre.component";
import { SetupEmpresaComponent } from "./pages/setup-empresa/setup-empresa.component";
import { AddSucursalComponent } from "./pages/setup-empresa/add-sucursal/add-sucursal.component";

import { SettingsComponent } from "./pages/settings/settings.component";
import { PuntoVentaComponent } from "./pages/settings/punto-venta/punto-venta.component";
import { CreatePtoVentaComponent } from "./pages/settings/punto-venta/create-pto-venta/create-pto-venta.component";
import { ListaPrecioComponent } from "./pages/settings/lista-precio/lista-precio.component";
import { CreateListaPrecioComponent } from "./pages/settings/lista-precio/create-lista-precio/create-lista-precio.component";
import { ListaClienteCajaComponent } from "./pages/caja/lista-cliente-caja/lista-cliente-caja.component";

import { ConfirmarCajaComponent } from "./pages/caja/confirmar-caja/confirmar-caja.component";
import { UsuariosComponent } from "./pages/settings/usuarios/usuarios.component";
import { CreateColaboradorComponent } from "./pages/settings/usuarios/create-colaborador/create-colaborador.component";
import { MarcasComponent } from "./pages/settings/marcas/marcas.component";
import { CreateMarcaComponent } from "./pages/settings/marcas/create-marca/create-marca.component";
import { RubrosComponent } from "./pages/settings/rubros/rubros.component";
import { CreateRubroComponent } from "./pages/settings/rubros/create-rubro/create-rubro.component";
import { UploadFileComponent } from "./shared/upload-file/upload-file.component";
import { ComprasComponent } from "./pages/compras/compras.component";
import { ReportesComponent } from "./pages/reportes/reportes.component";
import { FormatCodigoPipe } from "./pipes/format-codigo.pipe";
import { ListaProductosDialogoComponent } from "./pages/lista-productos-dialogo/lista-productos-dialogo.component";
import { FormatComprobantePipe } from "./pipes/format-comprobante.pipe";
import { FormatPtoventaPipe } from "./pipes/format-ptoventa.pipe";
import { ComprobanteComponent } from "./pages/comprobante/comprobante.component";
import { ListaClienteComponent } from "./pages/comprobante/lista-cliente/lista-cliente.component";
import { ReporteStockComponent } from "./pages/reportes/reporte-stock/reporte-stock.component";
import { EditarCantidadesComponent } from "./pages/caja/editar-cantidades/editar-cantidades.component";
import { SetupEmpresaV2Component } from "./pages/setup-empresa-v2/setup-empresa-v2.component";
import { EmpresaComponent } from "./pages/settings/empresa/empresa.component";
import { CheckauthComponent } from "./pages/checkauth/checkauth.component";
import { TablaResponsivaComponent } from "./shared/tabla-responsiva/tabla-responsiva.component";
import { DetalleFilaComponent } from "./shared/tabla-responsiva/detalle-fila/detalle-fila.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RecibosComponent } from "./pages/clientes/recibos/recibos.component";
import { DialogComprobantesComponent } from "./pages/clientes/recibos/dialog-comprobantes/dialog-comprobantes.component";
import { DialogMediosPagoComponent } from "./pages/clientes/recibos/dialog-medios-pago/dialog-medios-pago.component";
import { ProductosProveedorComponent } from "./pages/clientes/productos-proveedor/productos-proveedor.component";
import { PreciosMasivosComponent } from "./pages/productos/precios-masivos/precios-masivos.component";
import { FileDragAndDropDirective } from "./shared/file-drag-and-drop.directive";
import { VariantesArticuloComponent } from "./pages/productos/variantes-articulo/variantes-articulo.component";
import { DialogGenericoComponent } from "./shared/dialog/dialog.component";
import { GoBackBtnComponent } from "./shared/go-back-btn/go-back-btn.component";
import { TalonariosComponent } from './pages/settings/talonarios/talonarios.component';
import { CreateTalonarioComponent } from './pages/settings/talonarios/create-talonario/create-talonario.component';
import { TiendaComponent } from './pages/settings/tienda/tienda.component';
import {MatCardModule} from '@angular/material/card';

@NgModule({
  declarations: [
    AppComponent,
    DialogGenericoComponent,
    AdminLayoutComponent,
    ClientesComponent,
    DetalleClienteComponent,
    ProductosComponent,
    DetalleProductoComponent,
    ResumenComponent,
    VentasComponent,
    ComprobanteComponent,
    ListaClienteComponent,
    CajaComponent,
    AperturaComponent,
    CierreComponent,
    SetupEmpresaComponent,
    AddSucursalComponent,
    SettingsComponent,
    PuntoVentaComponent,
    CreatePtoVentaComponent,
    ListaPrecioComponent,
    CreateListaPrecioComponent,
    ListaClienteCajaComponent,
    ListaProductosDialogoComponent,
    ConfirmarCajaComponent,
    UsuariosComponent,
    CreateColaboradorComponent,
    MarcasComponent,
    CreateMarcaComponent,
    RubrosComponent,
    CreateRubroComponent,
    UploadFileComponent,
    ComprasComponent,
    ReportesComponent,
    FormatCodigoPipe,
    FormatComprobantePipe,
    FormatPtoventaPipe,
    ReporteStockComponent,
    EditarCantidadesComponent,
    SetupEmpresaV2Component,
    EmpresaComponent,
    CheckauthComponent,
    TablaResponsivaComponent,
    DetalleFilaComponent,
    RecibosComponent,
    DialogComprobantesComponent,
    DialogMediosPagoComponent,
    ProductosProveedorComponent,
    PreciosMasivosComponent,
    FileDragAndDropDirective,
    VariantesArticuloComponent,
    GoBackBtnComponent,
    TalonariosComponent,
    CreateTalonarioComponent,
    TiendaComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, { useHash: false }),
    SidebarModule,
    NavbarModule,
    FooterModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    CalendarModule,
    StepsModule,
    CardModule,
    MatCardModule,
    FormsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorHeadersInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [FormatCodigoPipe, FormatComprobantePipe, FormatPtoventaPipe],
})
export class AppModule {}
