import { Injectable } from "@angular/core";
import { BehaviorSubject, fromEvent, Observable, Subscription } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class DeviceDetectorService{

  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  cambiosTamañoPantalla = new BehaviorSubject<Boolean>(this.esVistaMovil())
  cambiosTamañoPantalla$;

  constructor(){
    this.cambiosTamañoPantalla$ = this.cambiosTamañoPantalla.asObservable()
  }

  detectarCambiosPantalla(){
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      this.cambiosTamañoPantalla.next(this.esVistaMovil())
    })
  }

  public esVistaMovil():boolean{
    return this.pantallaTamañoPequeño() || this.esNavegadorMovil() ;
  }

  private esNavegadorMovil(): boolean{
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|OperaMini|Mobile|mobile|CrisOs/i.test(navigator.userAgent);
  }

  private pantallaTamañoPequeño(): boolean{
    let pantalla = window.screen.width <= 800
    let navegador = window.innerWidth <= 800
     //let pantalla = window.screen.width <= 800 || window.screen.height <= 500
    //let navegador = window.innerHeight <= 500 || window.innerWidth <= 800
     return pantalla || navegador
  }
}
