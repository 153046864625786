import { Component, OnInit } from "@angular/core";
import { DataService } from "app/services/data.service";

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  activa: boolean;
}

@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  logo = "assets/img/logo.jpg";

  public menuItems: any[];

  constructor(public dataService: DataService) {}

  ngOnInit() {
    this.dataService.getDatos();
    console.log({ nombre: this.dataService.empresarazonsocial });
    // this.dataService.empresalogo.subscribe((data)=> {
    //   this.logo = data;
    // })
    this.menuItems = this.dataService.menu.value;
  }

  abrirTabAyuda() {
    window.open(
      "https://drive.google.com/drive/folders/1-fC0QInHZjTh28ejbrS166epuiSMqgNn?usp=sharing",
      "_blank"
    );
  }
}
